import React from "react";
import ProfilePic from "../profile_pic.png";

function GroupLeaders({ data }) {
  console.log(data)
  return (
    
      <div className="overflow-y-auto scrollbar-hide">
        {data.map((member) => (
          <div className="flex border  rounded-lg shadow-sm px-3 py-3 my-2">
            <img
              alt="profile pic"
              src={ProfilePic}
              className="w-[40px] h-[40px] rounded-full mr-3"
            />
            <div>
              <h2 className="font-semibold mb-1">{member[0]}</h2>
              <h2 className="text-[#6B7280]">{member[1] || ""}</h2>
            </div>
          </div>
        ))}
      </div>
  
  );
}

export default GroupLeaders;

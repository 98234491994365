import React, { useRef, useState, useEffect } from "react";
import * as Icons from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { NavLink } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_CATEGORIES } from "../../GraphQL/Tickets/Queries";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ToastAlert, { showToast } from "../ToastAlert";
import MyTickets from "./MyTickets";

const iconMap = {
  Operations: Icons.ImportContacts,
  Sales: Icons.HorizontalSplit,
  Admin: Icons.SettingsApplications,
  Maintainance: Icons.BuildCircle,
  Customercare: Icons.Security,
  IT: Icons.Storefront,
  Marketing: Icons.ColorLens,
  Analytics: Icons.BarChart,
};

const IconList = () => {
  const tenantId = localStorage.getItem("tenantId");
  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: categoriesData,
  } = useQuery(GET_ALL_CATEGORIES, {
    variables: { tenantId },
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (categoriesLoading) {
    return <div>Loading...</div>;
  }

  if (categoriesError) {
    return <div>Error loading categories</div>;
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <main>
      <ToastAlert />
      <div className="text-ruby-red flex items-center justify-between px-4">
        <div className="w-full flex flex-row justify-between">
          <h1 className="text-4xl font-black">Open New Ticket</h1>
          <div className="relative" ref={dropdownRef}>
            <button onClick={toggleDropdown} className="flex items-center gap-x-2">
              <MoreVertIcon fontSize="large" />
            </button>
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                <NavLink
                  to={`/tickets/createcategory`}
                  state={{ catdata: categoriesData.getAllCategories, iscreate: true }}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  onClick={() => setDropdownOpen(false)}
                >
                  Create Category
                </NavLink>
                <NavLink
                  to="/tickets/alltickets"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  onClick={() => setDropdownOpen(false)}
                >
                  Manage Tickets
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card w-[70rem] h-[40rem] rounded-[25px] shadow border border-white">
        <div className="grid grid-cols-4 grid-rows-2">
          {categoriesData.getAllCategories.map((category, index) => {
            const IconComponent = iconMap[category.icon] || Icons.HelpOutline;
            return (
              <div
                key={index}
                className="w-[245px] h-[245px] ml-4 mt-5 rounded-[25px] bg-gradient-to-b from-pink-600 to-rose-700 flex flex-col space-y-10"
              >
                <NavLink to={`/tickets/${category.id}`} state={{ catdata: category }}>
                  <span className="p-3 text-xl text-white">{category.name}</span>
                  <div className="w-[100px] h-[100px] rounded-full ml-[73px] mt-12 bg-white flex items-center justify-center">
                    <IconComponent style={{ width: "2em", height: "2em", color: "#be123c" }} />
                  </div>
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
      <MyTickets />
    </main>
  );
};

export default IconList;

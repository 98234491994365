import React from 'react';
import { useLocation } from 'react-router-dom';

const TicketDetails = () => {
  const location = useLocation();
  const { ticketData } = location.state || {};

  if (!ticketData) {
    return <div>No ticket data available</div>;
  }

  return (
    <div className="card w-[60rem] mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Ticket Details</h1>
  
        <div className="mb-4">
          <strong>Ticket ID:</strong> {ticketData.id}
        </div>
        <div className="mb-4">
          <strong>Template Name:</strong> {ticketData.templateName}
        </div>
        <div className="mb-4">
          <strong>Category ID:</strong> {ticketData.categoryId}
        </div>
        <div className="mb-4">
          <strong>Created By:</strong> {ticketData.createdBy.name}
        </div>
        <div className="mb-4">
          <strong>Status:</strong> {ticketData.status}
        </div>
        {ticketData.status === "Denied" && (
       <div className="mb-4">
       <div className="flex flex-row">
         <strong className="mr-2">Denial Reasons:</strong>
         {ticketData.denialReasons.map((reason) => reason.value).join(", ")}
       </div>
     </div>
        )}
    
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Answers:</h2>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 border">Question</th>
                <th className="px-4 py-2 border">Value</th>
              </tr>
            </thead>
            <tbody>
              {ticketData.answers.map((answer, index) => (
                <tr key={index} className="border-t">
                  <td className="px-4 py-2 border">{answer.questionId}</td>
                  <td className="px-4 py-2 border">{answer.value.join(", ")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {ticketData.additionalComment && (
          <div className="mb-4">
            <strong>Additional Comment:</strong> {ticketData.additionalComment}
          </div>
        )}
      </div>
  
  );
};

export default TicketDetails;

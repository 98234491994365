import React, { useState } from "react";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";
import backward from "../assets/backward.svg";
import pdfImg from "../assets/pdflogo.svg";
import imageLogo from "../assets/imaglog.svg";
import fileImg from "../assets/filelogo.svg";
import addFile from "../assets/addfileimage.svg";
import fileIcon from "../assets/fileIcon.svg";

function FileUpload() {
  const [uploadedItems, setUploadedItems] = useState([]);
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const accessData = "Only You";
    const lastUpdate = new Date().toISOString().split("T")[0]; // Get the current date

    const newItem = {
      file: file,
      recentlyUploaded: file.name,
      whoCanAccess: `${accessData}`,
      lastUpdated: ` ${lastUpdate}`,
    };

    setUploadedItems([...uploadedItems, newItem]);
  };

  return (
    <main className="flex flex-col mx-auto">
      <div className="flex justify-between items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="flex flex-col">
        <div className="container mx-auto px-3">
          <div className="flex flex-row items-center gap-[10px] pb-2 ">
            <img src={backward} alt="backward" />
            <h2 className="text-2xl font-semibold text-ruby-red">
              Upload Notes or Files
            </h2>
          </div>
          <div className="bg-white rounded-xl p-4 shadow-md">
            <div className="flex flex-row justify-between items-center mb-4 text-ruby-red">
              <p>Drag and drop your files below to continue</p>
              <label for="file-upload" className="cursor-pointer">
                <img className="w-[18px]" src={addFile} alt="addFile" />
              </label>
            </div>

            <div className=" p-8 mb-8">
              <div className="flex flex-col items-center justify-center mt-4">
                <label
                  for="file-upload"
                  className="flex flex-row gap-[20px] cursor-pointer"
                >
                  <img className="w-[50px]" src={pdfImg} alt="pdflog" />
                  <img className="w-[50px]" src={fileImg} alt="filelog" />
                  <img className="w-[50px]" src={imageLogo} alt="imagelog" />
                </label>
                <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  onChange={handleFileUpload}
                />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl p-4 shadow-md my-3 text-ruby-red">
            <div className="flex justify-between  font-semibold mb-4">
              <p className="flex flex-grow-1 flex-shrink-0 w-1/2 ">
                Recently Uploaded
              </p>
              <p className="flex-grow-1 flex-shrink-0 w-1/3 ">Who can access</p>
              <p className="flex-grow-1 flex-shrink-0 w-1/3 ">Last updated</p>
            </div>
            <div>
              {uploadedItems.map((item, index) => (
                <div
                  key={index}
                  className="mb-4 flex flex-row  border-t  pt-2 border-ruby-red"
                >
                  <div className="flex flex-grow-1 gap-[10px] flex-shrink-0 w-1/2 ">
                    <img src={fileIcon} alt="filIcon" />
                    <p>{item.recentlyUploaded}</p>
                  </div>

                  <p className="flex-grow-1 flex-shrink-0 w-1/3 ">
                    {item.whoCanAccess}
                  </p>
                  <p className="flex-grow-1 flex-shrink-0 w-1/3 ">
                    {item.lastUpdated}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default FileUpload;

import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import logo from "../assets/ruby_logo.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailEntered, setEmailEntered] = useState(false);

  const onContinueEmail = () => {
    if (email.trim() === "") {
      alert("Please enter your email.");
      navigate("/login");
      return;
    }
    setEmailEntered(true);
  };

  const onLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log(errorMessage);
        alert(errorMessage);
      });
  };

  return (
    <div className="h-screen flex bg-white text-black dark:text-white">
      <div className="hidden lg:flex w-full lg:w-1/2 bg-ruby-red dark:bg-[#F98CA0]  items-center"></div>

      <div className="flex flex-col w-full lg:w-1/2 justify-center items-center dark:bg-darkcard space-y-8">
        <div className="h-screen flex flex-col mt-[140px] items-center dark:bg-darkcard ">
          <div className="flex flex-row gap-2 justify-center items-center mb-3">
            <img
              src={logo}
              className="w-[100px] h-[100px]  "
              alt="rubylog-logo"
            />
          </div>

          {!emailEntered && (
            <>
              <div className="text-black text-2xl font-bold font-['Montserrat'] mt-[50px] mb-[30px]">
                Enter Your Email
              </div>
              <input
                id="email-address"
                className="px-3 py-[0.4rem] bg-white rounded-[9px] border border-black w-[350px] h-[50px]"
                name="email"
                type="email"
                required
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                onClick={onContinueEmail}
                className="w-[350px] h-[49px]  rounded-[9px] bg-ruby-red dark:bg-[#F98CA0]  px-3 py-2 text-white font-bold transition duration-200 hover:ruby-red-hover mt-3 flex justify-center items-center"
              >
                <div className="flex flex-row space-x-2 ">
                  <h2> Continue </h2>
                  <ArrowForwardIcon
                    style={{ width: "15px", height: "15px", marginTop: "3px" }}
                  />
                </div>
              </button>
            </>
          )}

          {emailEntered && (
            <>
              <div className="text-black text-2xl font-bold font-['Montserrat'] mt-[50px] mb-[30px]">
                Enter Your Password
              </div>
              <input
                className="px-3 py-[0.4rem] bg-white rounded-[9px] border border-black w-[350px] h-[50px]"
                id="password"
                name="password"
                type="password"
                required
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                onClick={onLogin}
                className="w-[350px] h-[49px]  rounded-[9px] bg-ruby-red dark:bg-[#F98CA0]  px-3 py-2 text-white font-bold transition duration-200 hover:ruby-red-hover mt-3 "
              >
                Log in
              </button>
            </>
          )}

          <div className="w-full flex flex-row justify-between mt-4">
            <a
              href="/signup"
              className="text-black text-base font-medium font-['Montserrat'] underline"
            >
              Sign up
            </a>
            <a
              href="/forgotpassword"
              className="text-black text-base font-medium font-['Montserrat'] underline"
            >
              Reset Password
            </a>
          </div>
        </div>
      </div>
      <div className="absolute right-5 bottom-5 text-black text-[40px] font-medium font-['Nunito'] leading-[43.20px]">
        rubylog
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";
import ForwardIcon from "@mui/icons-material/Forward";
import AuditTemplateList from "../components/audits/AuditTemplateList";

function AuditTemplates() {
  const navigate = useNavigate()
  useEffect(()=>{
if(localStorage.getItem("isAdmin") !== "true"){
  alert("You Are Not An Admin")
  navigate("/audits")
}
  },[])
  return (
    <main className="relative flex flex-col mx-auto">
      <div className="flex justify-between items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="flex flex-col mt-2">
        <div className="text-ruby-red flex items-center justify-between px-4">
          <div className="flex gap-x-4">
         
            <h1 className="text-4xl font-black">Audit Templates</h1>
          </div>
          <div className="flex items-center gap-x-5 mb-3">
            <NavLink
              className="box navlink-button navlink-button-hover"
              to="/audits/templates/create"
            state={{ currentaudit : null}}
            >
              Create Template
            </NavLink>
          </div>
        </div>
        <div className="flex justify-center">
          <AuditTemplateList />
        </div>
      </div>
    </main>
  );
}

export default AuditTemplates;

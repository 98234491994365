import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_TENANTS } from "../GraphQL/Users/Queries.js";
import { UPDATE_TENANT_STATUS } from "../GraphQL/Users/Mutation.js";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";

function Admin() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("user") !== "hephium@gmail.com") {
      alert("Cant Access You are not an admin");

      navigate("/");
    }
  }, []);

  const { loading, error, data } = useQuery(GET_ALL_TENANTS);
  const [tenantStatus, setTenantStatus] = useState({});
  const [updateTenantStatus] = useMutation(UPDATE_TENANT_STATUS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const handleStatusChange = (tenantId, status) => {
    setTenantStatus((prevStatus) => ({
      ...prevStatus,
      [tenantId]: status,
    }));
  };

  const handleSaveClick = async (tenantId) => {
    try {
      await updateTenantStatus({
        variables: { id: tenantId, status: tenantStatus[tenantId] },
      });
      console.log(`Status for Tenant ID ${tenantId} updated successfully.`);
    } catch (error) {
      console.error("Error updating tenant status:", error.message);
    }
  };
  const renderStatusDropdown = (tenant) => {
    const statusOptions = ["Pending", "Hold", "Active"];

    return (
      <div className="flex items-center">
        <select
          className="border p-2 rounded-md text-black"
          onChange={(e) => handleStatusChange(tenant.id, e.target.value)}
          value={tenantStatus[tenant.id] || tenant.status}
        >
          {statusOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <main className="relative flex flex-col mx-auto">
      <div className="flex justify-between items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="flex flex-col mt-2">
        <div className="text-[#b00041] px-4">
          <div className="flex items-center gap-x-4">
            <h1 className="text-4xl font-black mb-4">Admin Settings</h1>
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Tenant ID
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Save Change
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.getAllTenants.map((tenant) => (
                <tr key={tenant.id}>
                  <td className="px-6 py-4 text-gray-500">{tenant.id}</td>
                  <td className="px-6 py-4 text-black ">{tenant.name}</td>
                  <td className="px-6 py-4 ">{renderStatusDropdown(tenant)}</td>
                  <td className="px-6 py-4 ">
                    <button
                      className="bg-rose-700 text-white p-2 rounded-md"
                      onClick={() => handleSaveClick(tenant.id)}
                    >
                      Save
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}

export default Admin;

import React, { useState } from "react";

import avatar from "../../assets/avatar.svg";
import assignment from "../../assets/assignment.svg";

function AllNotifications() {

  // Sample notification data from server
  const AllNotifications = [
    {
      id: 1,
      title: "You have 4 new actions items",
      image: assignment,
      date: "Monday 10/02 ", 
      time: "15:30",
      isRead: true,
    },
    {
      id: 2,
      title: "Mr Nelson responded to your comment ,Thank you for reminding me",
      image: avatar,
      date: "Tuesday 10/02",
      time: "15:30",
      isRead: false,
    },
    {
      id: 3,
      title: "You have 4 new actions items",
      image: assignment,
      date: "Monday 10/02 ",
      time: "15:30",
      isRead: true,
    },
  ];

  const [showUnread, setShowUnread] = useState(false);

  const clearAllAllNotifications = () => {};

  return (
    <div className=" card w-[700px] max-h-[2000px]">
      <div className="w-full flex justify-between mb-4">
        <div className="flex gap-[20px] mb-2">
          <button
            onClick={() => setShowUnread(false)}
            className={` rounded-xl px-[10px] max-h-[25px] py-[3px] text-xm semi-bold ${
              !showUnread
                ? "bg-ruby-red text-white "
                : "bg-white text-ruby-red "
            }`}
          >
            All
          </button>
          <button
            onClick={() => setShowUnread(true)}
            className={` rounded-xl px-[10px] max-h-[25px] py-[3px] text-xm semi-bold  ${
              showUnread
                ? "bg-ruby-red text-white rounded-xl  text-xm semi-bold"
                : "bg-white text-ruby-red "
            }`}
          >
            Unread
          </button>
        </div>

        <button
          onClick={clearAllAllNotifications}
          className=" rounded-xl px-[10px] max-h-[25px] py-[3px] text-xm semi-bold "
        >
          Clear All
        </button>
      </div>
      {AllNotifications.filter(
        (notification) => !showUnread || !notification.isRead
      ).map((notification) => (
        <div
          key={notification.id}
          className="w-full p-2 border-b ml-2 mr-2 px-3"
        >
          <div className="flex justify-between items-center">
            <div className="flex gap-[25px] items-center">
              <img
                src={notification.image}
                alt="avatar"
                className="rounded-lg max-w-[40px] h-auto"
              />
              <div>
                <h3 className="text-sm font-semibold py-2 ">
                  {notification.title}
                </h3>
                <p className="text-xs">
                  {notification.date} at {notification.time}
                </p>
              </div>
            </div>
            <div>
              {!notification.isRead && (
                <div className="text-ruby-red  h-[9px] w-[9px] bg-ruby-red  rounded-full " />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AllNotifications;

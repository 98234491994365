import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_TICKET } from "../../GraphQL/Tickets/Mutations";
import ToastAlert, { showToast } from "../ToastAlert";
// import SearchBar from "../SearchBar";
// import Navbar from "../Navbar";
import Info from "../Info";
import { Forward as ForwardIcon } from "@mui/icons-material";

const IndividualTicket = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { template } = location.state || {};

  const [responses, setResponses] = useState(
    template.questions.map((question) => ({
      questionId: question.questionName,
      value:
        question.questionType === "checkbox" ||
        question.questionType === "checkbox grid"
          ? []
          : "",
    }))
  );

  const [createTicket, { loading, error }] = useMutation(CREATE_TICKET);

  const handleInputChange = (index, value) => {
    const newResponses = [...responses];
    newResponses[index].value = value;
    setResponses(newResponses);
  };

  const handleCheckboxChange = (index, value) => {
    const newResponses = [...responses];
    const currentValues = newResponses[index].value;

    if (currentValues.includes(value)) {
      newResponses[index].value = currentValues.filter((val) => val !== value);
    } else {
      newResponses[index].value = [...currentValues, value];
    }

    setResponses(newResponses);
  };

  const handleGridCheckboxChange = (questionIndex, rowIndex, value) => {
    const newResponses = [...responses];
    const currentValues = newResponses[questionIndex].value[rowIndex] || [];

    if (currentValues.includes(value)) {
      newResponses[questionIndex].value[rowIndex] = currentValues.filter(
        (val) => val !== value
      );
    } else {
      newResponses[questionIndex].value[rowIndex] = [...currentValues, value];
    }

    setResponses(newResponses);
  };

  const handleGridChange = (questionIndex, rowIndex, value) => {
    const newResponses = [...responses];
    newResponses[questionIndex].value[rowIndex] = value;
    setResponses(newResponses);
  };

  const handleMultipleChoiceChange = (index, value) => {
    const newResponses = [...responses];
    newResponses[index].value = value;
    setResponses(newResponses);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const denialReasons =
        template.denialReasons?.map((reason) => ({
          value: reason,
          status: false,
        })) || [];
      const input = {
        tenantId: localStorage.getItem("tenantId"),
        templateId: template.id,
        templateName: template.title,
        assignees: template.assigneeIds,
        categoryId: template.categoryId,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        status: "Pending",
        denialReasons,
        additionalComment: "",
        answers: responses.map((response) => ({
          ...response,
          value: Array.isArray(response.value)
            ? response.value.map(String)
            : String(response.value),
        })),
        createdBy: {
          id: localStorage.getItem("userid"),
          name: localStorage.getItem("name"),
          email: localStorage.getItem("user"),
        },
      };
      await createTicket({ variables: { input } });
      showToast("Ticket created successfully!", "success");
      setTimeout(() => {
        navigate("/tickets");
        window.location.reload();
      }, 2500);
    } catch (err) {
      console.error("Error creating ticket:", err);
      showToast("Error creating ticket: " + err.message, "error");
    }
  };

  return (
    <main className="flex flex-col">
      <ToastAlert />
      <div className="flex justify-between items-center space-x-2">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="text-[#b00041] flex items-center justify-between px-4 space-x-2">
        <div className="flex flex-row space-x-2 mb-2">
          <NavLink
            className="flex items-center gap-x-4 hover:text-red-800"
            to="/tickets"
          >
            <ForwardIcon fontSize="large" className="rotate-180" />
          </NavLink>
          <div className="text-[#b00041] flex px-4 space-x-2">
            <h1 className="text-4xl font-black">Open New Ticket</h1>
          </div>
        </div>
      </div>
      <div className="card w-[70rem] max-h-[10000rem] justify-between">
        <div className="mb-5 space-y-3">
          <h1 className="text-2xl font-black uppercase">{template.title}</h1>
          <h2 className="text-lg font-black text-gray-600">* Required</h2>
          <div className="space-y-2">
            <h2 className="font-semibold text-black">
              Description
              <Info title="Description of the ticket" />
            </h2>
            <h2 className="font-black text-gray-600">{template.description}</h2>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold text-black">
              Estimated Resolution Days:
            </h2>
            <h2 className="font-black text-gray-600">
              {template.estimatedResolutionDays}
            </h2>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold text-black">Availability Time:</h2>
            <h2 className="font-black text-gray-600">
              {template.availableFrom} to {template.availableTo}
            </h2>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold text-black">Availability Days:</h2>
            <h2 className="font-black text-gray-600">
              {template.availabilityDays.join(", ")}
            </h2>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          {template.questions.map((question, index) => (
            <div
              key={question.questionId}
              className="w-[798px] h-max-[252px] relative bg-stone-50 rounded-md mt-2 border border-gray-300 p-4"
            >
              <div className="flex items-center space-x-4 mb-2">
                <h3 className="text-lg font-semibold">
                  {question.questionName}
                </h3>
                {question.isRequired && <span className="text-red-500">*</span>}
              </div>
              {question.questionType === "short answer" && (
                <input
                  type="text"
                  className="border p-2 h-9 text-sm rounded w-full"
                  value={responses[index].value}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              )}
              {question.questionType === "paragraph" && (
                <textarea
                  className="border p-2 text-sm rounded w-full"
                  value={responses[index].value}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              )}
              {question.questionType === "multiple choice" && (
                <div>
                  {question.values.map((value, optionIndex) => (
                    <div key={optionIndex} className="flex items-center mb-2">
                      <input
                        type="radio"
                        name={`question_${index}`}
                        value={value}
                        checked={responses[index].value === value}
                        onChange={() =>
                          handleMultipleChoiceChange(index, value)
                        }
                      />
                      <label className="ml-2">{value}</label>
                    </div>
                  ))}
                </div>
              )}
              {question.questionType === "checkbox" && (
                <div>
                  {question.values.map((value, optionIndex) => (
                    <div key={optionIndex} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        name={`question_${index}`}
                        value={value}
                        checked={responses[index].value.includes(value)}
                        onChange={() => handleCheckboxChange(index, value)}
                      />
                      <label className="ml-2">{value}</label>
                    </div>
                  ))}
                </div>
              )}
              {question.questionType === "dropdown" && (
                <select
                  className="border p-2 h-9 text-sm rounded w-full"
                  value={responses[index].value}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                >
                  {question.values.map((value, optionIndex) => (
                    <option key={optionIndex} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              )}
              {question.questionType === "file upload" && (
                <input
                  type="file"
                  className="border p-2 text-sm rounded w-full"
                  onChange={(e) => handleInputChange(index, e.target.files[0])}
                />
              )}
              {question.questionType === "multiple choice grid" && (
                <div>
                  {question.values.map((row, rowIndex) => (
                    <div key={rowIndex}>
                      <h4 className="font-semibold">{row.rowLabel}</h4>
                      {row.columns.map((column, columnIndex) => (
                        <div
                          key={columnIndex}
                          className="flex items-center mb-2"
                        >
                          <input
                            type="radio"
                            name={`question_${index}_row_${rowIndex}`}
                            value={column}
                            checked={
                              responses[index].value[rowIndex] === column
                            }
                            onChange={() =>
                              handleGridChange(index, rowIndex, column)
                            }
                          />
                          <label className="ml-2">{column}</label>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
              {question.questionType === "checkbox grid" && (
                <div>
                  {question.values.map((row, rowIndex) => (
                    <div key={rowIndex}>
                      <h4 className="font-semibold">{row.rowLabel}</h4>
                      {row.columns.map((column, columnIndex) => (
                        <div
                          key={columnIndex}
                          className="flex items-center mb-2"
                        >
                          <input
                            type="checkbox"
                            name={`question_${index}_row_${rowIndex}`}
                            value={column}
                            checked={responses[index].value[rowIndex]?.includes(
                              column
                            )}
                            onChange={() =>
                              handleGridCheckboxChange(index, rowIndex, column)
                            }
                          />
                          <label className="ml-2">{column}</label>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
              {question.questionType === "date" && (
                <input
                  type="date"
                  className="border p-2 h-9 text-sm rounded w-full"
                  value={responses[index].value}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              )}
              {question.questionType === "time" && (
                <input
                  type="time"
                  className="border p-2 h-9 text-sm rounded w-full"
                  value={responses[index].value}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              )}
              {question.questionType === "number" && (
                <input
                  type="number"
                  className="border p-2 h-9 text-sm rounded w-full"
                  value={responses[index].value}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              )}
              {question.questionType === "linear scale" && (
                <div>
                    <h2 className="font-semibold text-gray-400"> The Scale is from ( {question.values[0]} to {question.values[1]} ) </h2>
                <div className="flex flex-row justify-between mt-3">
                  
                  <label className="mt-4 font-semibold">
                    {question.values[2]}
                  </label>
                
                  {Array.from(
                    { length: question.values[1] - question.values[0] + 1 },
                    (_, i) => i + Number(question.values[0])
                  ).map((value) => (
                    <div key={value} className="flex flex-col items-center">
                         <label className="mb-4">{value}</label>
                      <input
                        type="radio"
                        name={`question_${index}`}
                        value={value}
                        checked={responses[index].value === String(value)}
                        onChange={() => handleInputChange(index, String(value))}
                      />
                   
                    </div>
                  ))}

                  <label className="mt-4 font-semibold">
                    {question.values[3]}
                  </label>
                </div>
                </div>
              )}
            </div>
            
          ))}
          <div className="mt-5 flex justify-end">
            <button
              type="submit"
              className="bg-rose-700 text-white px-4 py-2 rounded"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
          {error && (
            <p className="text-red-500 mt-3">
              Error creating ticket: {error.message}
            </p>
          )}
        </form>
      </div>
    </main>
  );
};

export default IndividualTicket;

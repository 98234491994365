import React from "react";
import { CircularProgress, createTheme, ThemeProvider } from "@mui/material";
const CircularPorgress = ({ title, outer, inner }) => {
  var ETA = outer[1];
  var actual = inner[1];
  var outerProgress = 0;
  var innerProgress = 0;
  var values = [
    {
      "Group's Action Progress": [],
      "Ticket Completion": [outer[1] + " days", inner[1] + " days"],
      "Today's Completion": [outer[1] + " items", inner[1] + " items"],
    },
  ];
  if (title === "Group's Action Progress") {
    outerProgress = ((ETA / (ETA + actual)) * 100);
    values[0][title] = [outerProgress.toFixed(1) + " %", 100 - outerProgress.toFixed(1) + " %"];
    actual = 0;
  } else if (ETA > actual) {
    outerProgress = 100;
    innerProgress = ((actual / ETA) * 100);
  } else {
    innerProgress = 100;
    outerProgress = ((ETA / actual) * 100);
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#b00041",
      },
      secondary: {
        main: "#757575",
      },
      unprogress:{
        main:"#fecaca",
      }
    },
  });
  const Circle = ({ color, value, size }) => {
    return (
      <CircularProgress
        thickness="2.5"
        color={color}
        size={size}
        variant="determinate"
        value={value}
      />
    );
  };
  return (
    <div className="card w-[23rem] max-h-[320px]">
      <h1 className="mt-1">{title}</h1>{" "}
      <div className="flex flex-col w-[20rem]  relative">
        <div className="flex flex-row justify-center space-x-2 mt-2  mb-3">
          <div>{outer[0]}</div>
          
          <div className="w-4 h-4 mt-1 bg-rose-700 rounded-full"></div>
          <div>{inner[0]}</div>
          {title !== "Group's Action Progress" ? (
  <div className="w-4 h-4 mt-1 bg-[#757575] rounded-full"></div>
) : (
  <div className="w-4 h-4 mt-1 bg-[#fecaca] rounded-full"></div>
)}
        </div>

        <ThemeProvider theme={theme}>
          <div className="relative w-[40rem] ml-20 h-[20rem]">
          <div style={{ position: "absolute", opacity: 0.5 }}>
              <Circle color="unprogress" value={100} size="170px" />
            </div>
            <div style={{ position: "absolute" }}>
              <Circle color="primary" value={outerProgress} size="170px" />

              <div className="absolute top-[162px] right-[-20px] mb-10 flex items-center justify-center text-rose-700 text-l font-bold">
                {values[0][title][0]}
              </div>
            </div>
           
            {title !== "Group's Action Progress" && (
              <div
                style={{
                  position: "absolute",
                  top: "28%",
                  left: "13%",
                  transform: "translate(-49%, -51%)",
                  opacity: "0.2",
                }}
              >
                <Circle color="secondary" value={100} size="140px" />
              </div>
            )}
            <div
              style={{
                position: "absolute",
                top: "28%",
                left: "13%",
                transform: "translate(-49%, -51%)",
              }}
            >
              <Circle color="secondary" value={innerProgress} size="140px" />
              <div className="absolute inset-0 flex items-center justify-center text-gray-600 text-l font-bold">
                {values[0][title][1]}
              </div>
            </div>
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default CircularPorgress;

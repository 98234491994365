import React from "react";
import { NavLink } from "react-router-dom";
// import Navbar from "../Navbar";
// import SearchBar from "../SearchBar";
import CircularPorgress from "./CircularPorgress";
import {
  Favorite as FavoriteIcon,
  Forward as ForwardIcon,
  ModeComment as ModeCommentIcon,
  ThumbUp as ThumbUpIcon,
} from "@mui/icons-material";
import profilePic from "../profile_pic.jpg";
import MembersDisplay from "../MembersDisplay";

const people = [
  {
    id: 1,
    name: "Vrushab",
    avatar:
      "https://thumb.ac-illust.com/80/8024dd53daa1d06853715cb2bdf1db40_t.jpeg",
    message:
      "I have a dentist appointment at 1:30 tomorrow, but I’ll be able to take any calls afterwards!",
  },
  {
    id: 2,
    name: "Antonio",
    avatar:
      "https://thumb.ac-illust.com/80/8024dd53daa1d06853715cb2bdf1db40_t.jpeg",
    message: "UPDATE: We need to have this finished by EOD!",
  },
  {
    id: 3,
    name: "Hephium",
    avatar:
      "https://thumb.ac-illust.com/80/8024dd53daa1d06853715cb2bdf1db40_t.jpeg",
    message: "On it!",
  },
];

const members = [
  {
    id: "1",
    name: "Leslie Alexander",
    title: "Co-Founder / CEO",
  },
  {
    id: "2",
    name: "Michael Foster",
    title: "Co-Founder / CTO",
  },
  {
    id: "3",
    name: "Dries Vincent",
    title: "Manager, Business Relations",
  },
  {
    id: "4",
    name: "Dries Vincent",
    title: "Manager, Business Relations",
  },
];

const opened = [
  {
    id: "1",
    name: "Leslie Alexander",
    title: "Co-Founder / CEO",
  },
];

function Groups() {
  return (
    <main className="flex flex-col mx-auto">
      <div className="flex justify-between items-center space-x-2">
        {/* <SearchBar />
        <Navbar /> */}
      </div>

      <div className="flex flex-col">
        <div className="text-[#b00041] flex items-center justify-between px-4 space-x-2">
          <div className="flex flex-row justify-between w-[56rem]">
            <div className="flex flex-row gap-x-4">
              <NavLink
                className="flex items-center gap-x-4 hover:text-red-800"
                to="/tickets"
              >
                <ForwardIcon fontSize="large" className="rotate-180" />
              </NavLink>
              <h1 className="text-4xl font-black">Ticket Name</h1>
            </div>
            <div>
              <button
                type="submit"
                className="w-[182px] h-[44px]  bg-rose-900 rounded-lg text-white text-m font-semibold font-montserrat"
              >
                Assign Ticket
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-around">
          <div className="pl-5 w-[60rem] h-max-[1000px] justify-between space-y-3">
            <div className="card flex flex-col text-sm space-y-3">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row text-xl space-x-5 font-extrabold">
                  <h1>Ticket Title</h1>
                  <div className="test-black h-7 w-[100px] bg-[#7dd3fc] bg-opacity-50 rounded-full">
                    <h6 className="text-sm text-[#2563eb] ml-2 mt-1">
                      Not Started
                    </h6>
                  </div>
                  <FavoriteIcon className="text-rose-700 text-xs" />
                </div>
                <div className="text-m text-gray-500">
                  Opened: 00/00/0000 0:00PM
                </div>
              </div>
              <div className="flex flex-col space-y-3 text-xl text-neutral-500 font-light">
                <p>Details</p>
                <p className="text-sm">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
              <div className="flex justify-center item-center">
                <div className="w-[300px] h-[300px] bg-gray-700"></div>
              </div>
            </div>
            <h1 className="text-4xl text-[#b00041] font-black m-4">
              Discussion
            </h1>
            <div className="card flex flex-col text-sm space-y-3">
              <div className="flex p-2 flex-row pb-4 border-b-2 justify-between">
                <img className="w-10 h-10" src={profilePic} alt="Profile" />
                <input
                  type="search"
                  name="search"
                  placeholder="Write Something"
                  className="bg-zinc-100 h-10 w-[605px] pl-4 rounded-full text-sm focus:outline-none"
                />
                <button
                  type="submit"
                  className="w-[102px] h-[38px] bg-rose-900 rounded-full text-white text-sm font-semibold font-montserrat"
                >
                  Post
                </button>
              </div>
              <div className="flex flex-col space-y-7 p-2 text-xl font-light">
                {people.map((member) => (
                  <div key={member.id}>
                    <div className="flex flex-row justify-between space-x-5">
                      <img
                        className="w-12 h-12 mt-2 rounded-full"
                        src={profilePic}
                        alt="Profile"
                      />
                      <div className="flex flex-col p-2 rounded-sm w-full bg-gray-100 max-h-20 text-sm">
                        <h1 className="text-black font-black">{member.name}</h1>
                        <p>{member.message}</p>
                      </div>
                    </div>
                    <div className="flex flex-row ml-[70px] mt-2 text-sm space-x-3">
                      <p>
                        Respond <ModeCommentIcon className="text-[#b00041]" />
                      </p>
                      <p>
                        Thumb's Up <ThumbUpIcon className="text-[#b00041]" />
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <CircularPorgress
              title="Ticket Completion"
              outer={["ETA", 5]}
              inner={["Actual", 7]}
            />

            <div className="card max-w-[25rem] max-h-[25rem]">
              <h1>Opened By</h1>
              <MembersDisplay data={opened} />
            </div>
            <div className="card max-w-[25rem] max-h-[25rem]">
              <h1>Leader</h1>
              <MembersDisplay data={opened} />
            </div>
            <div className="card max-w-[25rem] max-h-[25rem]">
              <h1>Asignees</h1>
              <MembersDisplay data={members} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Groups;

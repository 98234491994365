import React from "react";
import { TextField, Autocomplete, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export default function MultiSelect({ selectedValues, setSelectedValues, names }) {

  const handleSelectChange = (event, selectedOptions) => {
    setSelectedValues(selectedOptions);
  };

  // Filter out users from the names array if they are already selected
  const filteredNames = names.filter((name) => !selectedValues.some((selected) => selected.name === name.name));

  return (
    <Autocomplete
      sx={{ m: 1, width: 400 }}
      multiple
      options={filteredNames} // Use filteredNames instead of names
      value={selectedValues}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect
      onChange={handleSelectChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select Users"
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={option.name}
          value={option}
          sx={{ justifyContent: "space-between" }}
        >
          {option.name}
       
        </MenuItem>
      )}
    />
  );
}

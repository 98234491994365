import React from "react";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";
import ALlNotifications from "../components/Notifications/AllNotifications";

function Notification() {
  return (
    <main className="flex flex-col mx-10">
      <div className="flex justify-between  items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="flex flex-col mt-2  ">
        <div className="text-[#b00041] flex items-center justify-between px-4 mb-3">
          <div className="flex items-center gap-x-4">
            <h1 className="text-4xl font-black">Notifications</h1>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <ALlNotifications />
        </div>
      </div>
    </main>
  );
}

export default Notification;

import { gql } from '@apollo/client';

export const GET_AUDIT_BY_ID = gql`
  query GetAuditById($workspaceId: ID!, $auditId: ID!) {
    getAuditById(workspaceId: $workspaceId, auditId: $auditId) {
      auditId
      assignees {
        id
        name
        status
        responses {
          questionId
          answer
        }
      }
      auditDesc
      auditTitle
      endDate
      repeatCount
      repeatType
      repeatDays
      repeatDates
      resolution
      startDate
      comment
      questions {
        questionId
        questionName
        questionType
        isRequired
        values
        failvalue
      }
      createdBy {
        id
        name
        email
      }
      admins {
        id
        name
        email
      }
    }
  }
`;

export const GET_ALL_AUDITS = gql`
  query GetAllAudits($workspaceId: ID!) {
    getAllAudits(workspaceId: $workspaceId) {
      auditId
      auditTitle
      auditDesc
    }
  }
`;

export const GET_TICKET_BY_ID = gql`
  query GetTicketById($workspaceId: ID!, $id: ID!) {
    getTicketById(workspaceId: $workspaceId, id: $id) {
      id
      templateId
      templateName
      answers {
        questionId
        value
      }
      createdBy {
        id
        name
        email
      }
      admins {
        id
        name
        email
      }
      assignees
      createdAt
      updatedAt
      status
      denialReasons {
        value
        status
      }
      additionalComment
    }
  }
`;

export const GET_ALL_TICKETS = gql`
  query GetAllTickets($workspaceId: ID!) {
    getAllTickets(workspaceId: $workspaceId) {
      id
      templateName
      status
    }
  }
`;

export const GET_TICKET_TEMPLATES = gql`
  query GetTicketTemplates($workspaceId: ID!) {
    getTicketTemplates(workspaceId: $workspaceId) {
      id
      title
      description
    }
  }
`;

export const GET_TICKET_BY_USER = gql`
  query GetTicketByUser($workspaceId: ID!, $userId: ID!) {
    getTicketByUser(workspaceId: $workspaceId, userId: $userId) {
      id
      templateName
      status
    }
  }
`;

export const GET_USER_WORKSPACES = gql`
  query GetUserWorkspaces($userId: ID!) {
    getUserWorkspaces(userId: $userId) {
      id
      name
      leader {
        id
        name
        email
      }
      members {
        id
        name
        email
      }
    }
  }
`;

export const GET_ALL_WORKSPACES = gql`
  query GetAllWorkspaces {
    getAllWorkspaces {
      id
      name
      leader {
        id
        name
        email
      }
      members {
        id
        name
        email
      }
    }
  }
`;

export const GET_WORKSPACE_BY_ID = gql`
  query GetWorkspaceById($id: ID!) {
    getWorkspaceById(id: $id) {
      id
      name
      leader {
        id
        name
        email
      }
      members {
        id
        name
        email
      }
    }
  }
`;

import React from "react";
import logo from "../../assets/ruby_logo.svg";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import AddReactionIcon from "@mui/icons-material/AddReaction";
const user = "Vrushab";
const chatData = [
  {
    id: 1,
    message:
      "What are the dimensions of our webpage banners for the brand’s online website?",
    sender: "user",
  },
  {
    id: 2,
    message:
      "Hey Vincent! The dimensions are 1080 x 300 px for web and 500 x 500 px for mobile! Would you like to see the doc?",
    sender: "receiver",
  },
  {
    id: 2,
    message:
      "Hey Vincent! The dimensions are 1080 x 300 px for web and 500 x 500 px for mobile! Would you like to see the doc?",
    sender: "receiver",
  },
  {
    id: 1,
    message:
      "What are the dimensions of our webpage banners for the brand’s online website?",
    sender: "user",
  },
  {
    id: 1,
    message:
      "What are the dimensions of our webpage banners for the brand’s online website?",
    sender: "user",
  },
  {
    id: 2,
    message:
      "Hey Vincent! The dimensions are 1080 x 300 px for web and 500 x 500 px for mobile! Would you like to see the doc?",
    sender: "receiver",
  },
  {
    id: 2,
    message:
      "Hey Vincent! The dimensions are 1080 x 300 px for web and 500 x 500 px for mobile! Would you like to see the doc?",
    sender: "receiver",
  },
  {
    id: 2,
    message:
      "Hey Vincent! The dimensions are 1080 x 300 px for web and 500 x 500 px for mobile! Would you like to see the doc?",
    sender: "receiver",
  },
];

const MiniChat = () => {
  return (
    <div className="card w-[320px] h-[460px] p-2 ">
      <div className=" flex w-full ml-2 mt-2 space-x-4 h-7">
        <img src={logo} className="object-cover h-6 w-6  rounded-full" alt="" />
        <h1>{user}</h1>
      </div>
      <div className="w-full px-2 pt-2 border-t-2 overflow-y-auto scrollbar-hide max-h-[360px] mt-2">
        {chatData.map((message, index) => (
          <div
            key={message.id}
            className={`flex ${
              message.sender === "user" ? "justify-end" : "justify-start"
            } space-x-2 mb-2`}
          >
            {message.sender !== "user" && (
              <img
                src={logo}
                className="object-cover h-6 w-6 rounded-full"
                alt=""
              />
            )}
            <div
              className={`w-[270px] max-h-[500px] ${
                message.sender === "user"
                  ? "bg-gray-200"
                  : "bg-rose-700 bg-opacity-25"
              } rounded-[10px] p-2 text-m text-black text-base font-normal font-['Montserrat'] leading-tight`}
            >
              {message.message}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-2 space-x-1 pb-2  text-[#b00041]">
        <AddReactionIcon fontSize="small" />
        <AttachFileIcon fontSize="small" />
        <input
          type="search"
          name="search"
          placeholder="search"
          class="bg-zinc-100 h-9 w-[230px]  pl-2 rounded-full text-sm focus:outline-none"
        />
        <SendIcon fontSize="small" />
      </div>
    </div>
  );
};

export default MiniChat;

import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { NavLink } from "react-router-dom";
import { GET_ALL_AUDITS } from "../../GraphQL/Audits/Queries";
import { DELETE_AUDIT } from "../../GraphQL/Audits/Mutations";
import { DeleteForever, Edit } from "@mui/icons-material";
import ToastAlert, { showToast } from "../../components/ToastAlert";

function AuditTemplateList() {
  const { loading, error, data } = useQuery(GET_ALL_AUDITS, {
    variables: {
      tenantId: localStorage.getItem('tenantId'),
    },
  });
  const [deleteAudit] = useMutation(DELETE_AUDIT);

  const handleDelete = async (auditId, name) => {
    try {
      await deleteAudit({
        variables: { tenantId: localStorage.getItem('tenantId'), auditId },
      });

      const toastContent = (
        <div>
          <p className="text-ruby-red text-bold">Audit Deleted Successfully! </p>
          <p className="text-green-500">{name} </p>
        </div>
      );
      showToast(toastContent, "success");
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } catch (error) {
      showToast("Please Verify Ticket Details");
      console.error("Error deleting Ticket:", error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const currentUserId = localStorage.getItem("userid");
  
  // Filter audits where current user's ID is in the visibility list of assignedBy
  const visibleAudits =  data.getAllAudits.filter(audit =>
    audit.assignedBy.visibility.includes(currentUserId)
  );

  return (
    <div className="card w-[70rem] max-h-[10000rem] gap-2">
      <ToastAlert />
      {visibleAudits.map((audit, index) => (
        <div key={index} className="flex flex-col border border-gray-400 px-3 py-3 gap-2 rounded-md">
          <div className="flex flex-row justify-between">
            <NavLink
              to={{
                pathname: `/audits/${audit.auditId}`,
              }}
              state={{ audit: audit }}
            >
              <h2 className="font-semibold text-ruby-red">
                {audit.auditTitle}
              </h2>
            </NavLink>
            <div className="text-ruby-red space-x-3">
              <NavLink
                to="/audits/templates/create"
                state={{ currentaudit: audit }}
              >
                <Edit className="text-ruby-red" />
              </NavLink>

              <button
                onClick={() => handleDelete(audit.auditId, audit.auditTitle)}
              >
                <DeleteForever className="text-ruby-red" />
              </button>
            </div>
          </div>
          <p className="text-gray-400">{audit.auditDesc}</p>
        </div>
      ))}
    </div>
  );
}

export default AuditTemplateList;


import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { Button, TextField, Box, Typography, Modal } from '@mui/material';


// Define the mutation
const SUBMIT_FEEDBACK = gql`
  mutation SubmitFeedback($input: FeedbackInput!) {
    submitFeedback(input: $input) {
      success
      message
    }
  }
`;

const FeedbackForm = ({open,onClose}) => {
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [submitFeedback] = useMutation(SUBMIT_FEEDBACK);

  const token = localStorage.getItem('token'); // Get token from localStorage
  const tenantId = localStorage.getItem('tenantId'); // Get tenantId from localStorage

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await submitFeedback({
        variables: {
          input: { feedback, email, tenantId } // Include tenantId here
        },
        
      });

      if (data.submitFeedback.success) {
        setIsSubmitted(true);
        setFeedback('');
        setEmail('');
      } else {
        alert('Failed to submit feedback: ' + data.submitFeedback.message);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('An error occurred while submitting feedback.');
    }
  };

 

  return (
    <Modal
    
      open={open}
      onClose={onClose}
      aria-labelledby="submit-feedback"
      aria-describedby="submit-feedback-form"
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        {isSubmitted ? (
          <Box textAlign="center">
            <Typography variant="h6" component="h2">
              Thanks for submitting the feedback!
            </Typography>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              sx={{ mt: 2, bgcolor: '#b00041',
              '&:hover': { bgcolor: '#A70023' },}}
            >
              Close
            </Button>
          </Box>
        ) : (
          <>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ color: '#b00041' }}
            >
              Submit Feedback or Suggestion
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              paragraph
              sx={{ mb: 2 }}
            >
              Your feedback helps us make improvements to RubyLog. Our team values any and all feedback or ideas for future features!
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" component="label" htmlFor="feedback">
                Description<span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                id="feedback"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                required
                margin="normal"
                sx={{
                  borderRadius: 2,
                  '& fieldset': {
                    borderRadius: 2,
                  },
                }}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
                bgcolor: '#b00041',
                '&:hover': { bgcolor: '#A70023' },
              }}
            >
              Submit
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default FeedbackForm;
import React, { useState, useEffect } from "react";
import { Forward as ForwardIcon } from "@mui/icons-material";
// import SearchBar from "../SearchBar";
// import Navbar from "../Navbar";
import {
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
} from "../../GraphQL/Tickets/Mutations";
import { useMutation } from "@apollo/client";
import ToastAlert, { showToast } from "../../components/ToastAlert";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import * as Icons from "@mui/icons-material";
import { FormControl, Autocomplete, TextField } from "@mui/material";

const CreateCategory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { catdata, iscreate } = location.state;
  const existingCategories = Array.isArray(catdata) ? catdata : [];

  const initialFormData = {
    name: iscreate ? "" : catdata.name,
    parentId: iscreate ? "" : catdata.parentId || "",
    childrenIds: iscreate ? [] : catdata.childrenIds || [],
    icon: iscreate ? "" : catdata.icon || "",
    tenantId: localStorage.getItem("tenantId"),
    createdBy: {
      id: localStorage.getItem("userid"),
      name: localStorage.getItem("name"),
      email: localStorage.getItem("user"),
    },
  };

  const [formData, setFormData] = useState(initialFormData);

  const [
    createCategory,
    { loading: createLoading, error: createError, data: createData },
  ] = useMutation(CREATE_CATEGORY);
  const [
    updateCategory,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(UPDATE_CATEGORY);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (iscreate) {
        await createCategory({ variables: { input: formData } });
        showToast("Category created successfully!", "success");
      } else {
        await updateCategory({
          variables: { id: catdata.id, input: formData },
        });
        showToast("Category updated successfully!", "success");
      }
      navigate("/tickets");
      window.location.reload();
    } catch (err) {
      console.error("Error creating/updating category:", err);
      showToast("Error creating/updating category: " + err.message, "error");
    }
  };

  const iconOptions = [
    { name: "Operations", component: Icons.ImportContacts },
    { name: "Sales", component: Icons.HorizontalSplit },
    { name: "Admin", component: Icons.SettingsApplications },
    { name: "Maintainance", component: Icons.BuildCircle },
    { name: "Customercare", component: Icons.Security },
    { name: "IT", component: Icons.Storefront },
    { name: "Marketing", component: Icons.ColorLens },
    { name: "Analytics", component: Icons.BarChart },
  ];

  useEffect(() => {
    if (!iscreate) {
      setFormData({
        ...formData,
        name: catdata.name,
        parentId: catdata.parentId || "",
        childrenIds: catdata.childrenIds || [],
        icon: catdata.icon || "",
      });
    }
  }, [iscreate, catdata]);

  return (
    <main className="flex flex-col">
      <div className="flex justify-between items-center space-x-2">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <ToastAlert />
      <div className="flex flex-col">
        <div className="flex flex-row space-x-2 mb-10">
          <NavLink
            className="flex items-center gap-x-4 hover:text-red-800"
            to="/tickets"
          >
            <ForwardIcon fontSize="large" className="rotate-180" />
          </NavLink>
          <div className="text-[#b00041] flex px-4 space-x-2">
            <h1 className="text-4xl font-black">
              {iscreate ? "Create Category" : "Update Category"}
            </h1>
          </div>
        </div>
        <div className="flex justify-around">
          <form onSubmit={handleSubmit}>
            <div className="card w-[768px] max-h-[1000px] p-5 rounded-[25px] shadow">
              <div className="text-black mb-3">
                <label>Category Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Enter the category name"
                  className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 mb-4"
                />
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-black mb-3 flex flex-col space-y-2">
                  <label>Parent Category</label>
                  <Autocomplete
                    className="w-[300px]"
                    options={existingCategories}
                    getOptionLabel={(option) => option.name}
                    value={
                      existingCategories.find(
                        (cat) => cat.id === formData.parentId
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        parentId: newValue ? newValue.id : "",
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Parent Category"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <div className="text-black mb-3 flex flex-col space-y-2">
                  <label>Icon</label>
                  <FormControl className="w-[300px]">
                    <Autocomplete
                      options={iconOptions}
                      getOptionLabel={(option) => option.name}
                      value={
                        iconOptions.find(
                          (icon) => icon.name === formData.icon
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFormData((prevData) => ({
                          ...prevData,
                          icon: newValue ? newValue.name : "",
                        }));
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <option.component style={{ marginRight: 8 }} />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Icon"
                          variant="outlined"
                          className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 mb-4"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="mt-5 flex flex-row justify-end space-x-3">
                <button
                  type="submit"
                  className="w-full h-[38px] px-[30px] py-[9px] bg-rose-900 rounded-md text-white font-semibold"
                  disabled={createLoading || updateLoading}
                >
                  {createLoading || updateLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
              {(createError || updateError) && (
                <p className="text-red-500 mt-3">
                  Error: {(createError || updateError).message}
                </p>
              )}
              {(createData || updateData) && (
                <p className="text-green-500 mt-3">
                  {iscreate
                    ? "Category created successfully!"
                    : "Category updated successfully!"}
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default CreateCategory;

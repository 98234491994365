import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setResetEmailSent(true);
      })
      .catch((error) => {
        console.error("Error sending reset email: ", error);
      });
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-ruby-red">
      {resetEmailSent ? (
        <div className="card space-y-3 w-[500px] h-[150px] items-center">
          <h2 className="text-black font-black text-xl">
            Check your email for password reset instructions.
          </h2>
          <a
            className="w-[168px] h-[38px] px-[30px] py-[9px] bg-rose-900 rounded-md text-white text-sm font-semibold font-montserrat"
            href="/login"
          >
            Back To Login
          </a>
        </div>
      ) : (
        <div className="card space-y-3 w-[500px] h-[150px] items-center">
          <h2 className="text-black font-black ">Forgot Password</h2>
          <input
            type="email"
            class="bg-zinc-100 h-10 w-[20rem]  pl-4 rounded-full text-sm  focus:outline-none"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className="w-[178px] h-[38px] px-[30px] py-[9px] bg-rose-900 rounded-md text-white text-sm font-semibold font-montserrat"
            onClick={handleResetPassword}
          >
            Reset Password
          </button>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;

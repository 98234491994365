import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Landing from "./pages/Landing";
import { CircularProgress } from "@mui/material";
import { GET_USER_BY_EMAIL } from "./GraphQL/NewUsers/Queries";
import { useQuery } from "@apollo/client";

const Loggedin = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSidebar, setIsSidebar] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if(authUser ){
        setUser(authUser);
        localStorage.setItem('user', authUser.email)
      }
 
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);
  const { loading: queryLoading, error, data } = useQuery(GET_USER_BY_EMAIL, {
    variables: {
      userEmail: user ? user.email : null,
    },
  });

  useEffect(() => {
    if(!queryLoading && data){
      if(data.getUserByEmail === null){
        window.location.href = "/login";
        alert("User not registered with this tenant/ registered with other tenant! Please recheck tenant ID and email address");
        localStorage.clear();
      }
     
      
    }
    if (!queryLoading && data) {

      localStorage.setItem("name", data.getUserByEmail.name);
      localStorage.setItem("userid", data.getUserByEmail.id);
      localStorage.setItem("email", data.getUserByEmail.email);
   
    }
   
  }, [queryLoading, data]);
console.log(localStorage.getItem("userid"));
  if (loading || queryLoading) {
    return (
      <div className="w-screen h-screen bg-white flex justify-center items-center text-2xl text-black">
        Loading... <CircularProgress />
      </div>
    );
  }


  return (
    <div>
      {user ? (
        <div className="flex flex-row w-screen min-h-screen max-h-[10000px] justify-between dark:bg-neutral-900">
        <Sidebar isSidebar={isSidebar} />
          <Outlet />
        </div>
      ) : (
        <Landing />
      )}
    </div>
  );
};

export default Loggedin;

import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  CREATE_AUDIT,
  UPDATE_AUDIT,
  CREATE_TICKET_TEMPLATE,
  UPDATE_TICKET_TEMPLATE,
} from "../GraphQL/NewMethods/Mutation";
import { useNavigate, useLocation } from "react-router-dom";
import ToastAlert, { showToast } from "../components/ToastAlert";
import QuestionsForm from "./QuestionsForm";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Autocomplete,
} from "@mui/material";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";

const CreateTemplateForm = () => {
  const [templateType, setTemplateType] = useState("audit"); // Default to audit template
  const [questions, setQuestions] = useState([
    { questionType: "checkbox", questionName: "", values: [""], isRequired: false, failvalue: "" },
  ]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [endDate, setEndDate] = useState("");
  const [repeatCount, setRepeatCount] = useState(0);
  const [repeatType, setRepeatType] = useState("");
  const [repeatDays, setRepeatDays] = useState([]);
  const [repeatDates, setRepeatDates] = useState([]);
  const [resolution, setResolution] = useState("Unresolved");
  const [startDate, setStartDate] = useState("");
  const [comment, setComment] = useState("");
  const [assignees, setAssignees] = useState([]);
  const [createdBy, setCreatedBy] = useState({
    id: localStorage.getItem("userid"),
    name: localStorage.getItem("name"),
    email: localStorage.getItem("user"),
  });
  const [admins, setAdmins] = useState([]);
  const [estimatedResolutionDays, setEstimatedResolutionDays] = useState(0);
  const [availableFrom, setAvailableFrom] = useState("");
  const [availableTo, setAvailableTo] = useState("");
  const [availabilityDays, setAvailabilityDays] = useState([]);
  const [members, setMembers] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const location = useLocation();
  const workspace = location.state.workspace;

  const dataedit = location.state?.currentaudit || location.state?.currentticket;


  const [createAudit] = useMutation(CREATE_AUDIT);
  const [updateAudit] = useMutation(UPDATE_AUDIT);
  const [createTemplate] = useMutation(CREATE_TICKET_TEMPLATE);
  const [updateTemplate] = useMutation(UPDATE_TICKET_TEMPLATE);

  useEffect(() => {
    if (dataedit) {
      if (dataedit.auditTitle) {
        setTemplateType("audit");
        setTitle(dataedit.auditTitle);
        setDescription(dataedit.auditDesc);
        setQuestions(dataedit.questions);
        setEndDate(dataedit.endDate);
        setRepeatCount(dataedit.repeatCount);
        setRepeatType(dataedit.repeatType);
        setRepeatDays(dataedit.repeatDays);
        setRepeatDates(dataedit.repeatDates);
        setResolution(dataedit.resolution);
        setStartDate(dataedit.startDate);
        setComment(dataedit.comment);
        setAssignees(dataedit.assignees);
        setCreatedBy(dataedit.createdBy);
        setAdmins(dataedit.admins);
      } else if (dataedit.title) {
        setTemplateType("ticket");
        setTitle(dataedit.title);
        setDescription(dataedit.description);
        setQuestions(dataedit.questions);
        setEstimatedResolutionDays(dataedit.estimatedResolutionDays);
        setAvailableFrom(dataedit.availableFrom);
        setAvailableTo(dataedit.availableTo);
        setAvailabilityDays(dataedit.availabilityDays);
        setMembers(dataedit.members);
        setAdmins(dataedit.admins);
      }
    } 
  }, [dataedit, workspace]);

  const handleCreateTemplateClick = async () => {
    try {
      if (templateType === "audit") {
        if (!dataedit) {
          await createAudit({
            variables: {
              workspaceId: workspace.id,
              input: {
                assignees,
                auditDesc: description,
                auditTitle: title,
                endDate: endDate || new Date().toISOString(),
                repeatType,
                repeatDays,
                repeatCount,
                repeatDates,
                resolution,
                startDate: startDate || new Date().toISOString(),
                comment,
                questions,
                createdBy,
                admins,
              },
            },
          });
        } else {
          await updateAudit({
            variables: {
              workspaceId: workspace.id,
              auditId: dataedit.auditId,
              input: {
                auditDesc: description,
                auditTitle: title,
                endDate,
                repeatCount,
                repeatType,
                repeatDays,
                repeatDates,
                resolution,
                startDate,
                comment,
                questions,
                createdBy,
                admins,
              },
            },
          });
        }
      } else {
        if (!dataedit) {
          await createTemplate({
            variables: {
              workspaceId: workspace.id,
              input: {
                title,
                description,
                estimatedResolutionDays,
                availableFrom,
                availableTo,
                availabilityDays,
                members,
                admins,
                questions,
                createdBy,
              },
            },
          });
        } else {
          await updateTemplate({
            variables: {
              workspaceId: workspace.id,
              id: dataedit.id,
              input: {
                title,
                description,
                estimatedResolutionDays,
                availableFrom,
                availableTo,
                availabilityDays,
                members,
                admins,
                questions,
              },
            },
          });
        }
      }
      showToast(`Template ${dataedit ? "updated" : "created"} successfully!`, "success");
      setIsPopupOpen(true);
    } catch (error) {
      console.error("Error creating or updating template:", error);
      showToast("Failed to create or update template.", "error");
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col items-center">
      <div className="flex justify-between items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <form className="flex flex-col items-center mt-10">
        <ToastAlert />
        <div className="card w-[768px] p-5 rounded-[25px] shadow">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Create Template</h2>
            <FormControl>
              <InputLabel>Template Type</InputLabel>
              <Select
                value={templateType}
                onChange={(e) => setTemplateType(e.target.value)}
              >
                <MenuItem value="audit">Audit</MenuItem>
                <MenuItem value="ticket">Ticket</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            className="mb-4"
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            className="mb-4"
          />
          {templateType === "audit" && (
            <>
              <TextField
                label="End Date"
                type="datetime-local"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                fullWidth
                className="mb-4"
              />
              <TextField
                label="Repeat Count"
                type="number"
                value={repeatCount}
                onChange={(e) => setRepeatCount(Number(e.target.value))}
                fullWidth
                className="mb-4"
              />
              <TextField
                label="Repeat Type"
                value={repeatType}
                onChange={(e) => setRepeatType(e.target.value)}
                fullWidth
                className="mb-4"
              />
              <TextField
                label="Repeat Days"
                value={repeatDays.join(", ")}
                onChange={(e) => setRepeatDays(e.target.value.split(", "))}
                fullWidth
                className="mb-4"
              />
              <TextField
                label="Repeat Dates"
                value={repeatDates.join(", ")}
                onChange={(e) => setRepeatDates(e.target.value.split(", ").map(Number))}
                fullWidth
                className="mb-4"
              />
              <TextField
                label="Resolution"
                value={resolution}
                onChange={(e) => setResolution(e.target.value)}
                fullWidth
                className="mb-4"
              />
              <TextField
                label="Start Date"
                type="datetime-local"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                fullWidth
                className="mb-4"
              />
              <TextField
                label="Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                fullWidth
                className="mb-4"
              />
              <FormControl fullWidth className="mb-4">
                <InputLabel>Assignees</InputLabel>
                <Autocomplete
                  multiple
                  options={workspace.members}
                  getOptionLabel={(option) => option.name}
                  value={assignees}
                  onChange={(event, newValue) => setAssignees(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select assignees" />
                  )}
                />
              </FormControl>
              <FormControl fullWidth className="mb-4">
                <InputLabel>Admins</InputLabel>
                <Autocomplete
                  multiple
                  options={workspace.members}
                  getOptionLabel={(option) => option.name}
                  value={admins}
                  onChange={(event, newValue) => setAdmins(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select admins" />
                  )}
                />
              </FormControl>
            </>
          )}
          {templateType === "ticket" && (
            <>
              <TextField
                label="Estimated Resolution Days"
                type="number"
                value={estimatedResolutionDays}
                onChange={(e) => setEstimatedResolutionDays(Number(e.target.value))}
                fullWidth
                className="mb-4"
              />
              <TextField
                label="Available From"
                type="time"
                value={availableFrom}
                onChange={(e) => setAvailableFrom(e.target.value)}
                fullWidth
                className="mb-4"
              />
              <TextField
                label="Available To"
                type="time"
                value={availableTo}
                onChange={(e) => setAvailableTo(e.target.value)}
                fullWidth
                className="mb-4"
              />
              <TextField
                label="Availability Days"
                value={availabilityDays.join(", ")}
                onChange={(e) => setAvailabilityDays(e.target.value.split(", "))}
                fullWidth
                className="mb-4"
              />
              <FormControl fullWidth className="mb-4">
                <InputLabel>Members</InputLabel>
                <Autocomplete
                  multiple
                  options={workspace.members}
                  getOptionLabel={(option) => option.name}
                  value={members}
                  onChange={(event, newValue) => setMembers(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select members" />
                  )}
                />
              </FormControl>
              <FormControl fullWidth className="mb-4">
                <InputLabel>Admins</InputLabel>
                <Autocomplete
                  multiple
                  options={workspace.members}
                  getOptionLabel={(option) => option.name}
                  value={admins}
                  onChange={(event, newValue) => setAdmins(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select admins" />
                  )}
                />
              </FormControl>
            </>
          )}
          <QuestionsForm
            questions={questions}
            setQuestions={setQuestions}
            templateType={templateType}
          />
          <div className="flex justify-end mt-4">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateTemplateClick}
            >
              {dataedit ? "Update Template" : "Create Template"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateTemplateForm;

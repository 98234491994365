import { gql } from "@apollo/client";

const GET_ALL_TENANTS = gql`
query GetAllTenants {
  getAllTenants {
    id
    status
    companyName
    companySize
    additionalInfo
  }
}
`;
const GET_TENANT = gql`
query GetTenantById($getTenantByIdId: ID) {
  getTenantById(id: $getTenantByIdId) {
    id
    status
    companyName
    companySize
    additionalInfo
  }
}
`;
const GET_ALL_USERS = gql`
query GetAllUsers($tenantId: ID!) {
  getAllUsers(tenantId: $tenantId) {
    id
    name
    email
    role
    title
    isAdmin
    groups
  }
}
`;
const GET_USER = gql`
query GetUserByEmail($tenantId: ID!, $userEmail: String!) {
  getUserByEmail(tenantId: $tenantId, userEmail: $userEmail) {
    id
    name
    email
    role
    title
    isAdmin
    groups
  }
}
`;
export {GET_ALL_TENANTS,GET_TENANT,GET_ALL_USERS,GET_USER}
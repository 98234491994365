import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_USERS } from "../GraphQL/Users/Queries.js";
import {
  CREATE_USER,
  DELETE_USER,
  UPDATE_USER,
} from "../GraphQL/Users/Mutation.js";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase";
import { Delete } from "@mui/icons-material";
function AdminTenant() {
  const navigate = useNavigate();
  const [createUser, { loadinguser, erroruser }] = useMutation(CREATE_USER);

  const [email, setEmail] = useState("");
  const password = "rubylog1234";
  const [modalOpen, setModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    tenantId: localStorage.getItem("tenantId"),
    name: "",
    email: "",
    isAdmin: false,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  console.log(formData);
  useEffect(() => {
    if (localStorage.getItem("isAdmin") !== "true") {
      alert("Cant Access You are not an adminTenant");

      navigate("/");
    }
  }, []);

  const { loading, error, data } = useQuery(GET_ALL_USERS, {
    variables: {
      tenantId: localStorage.getItem("tenantId"),
    },
  });
  const [UserStatus, setUserStatus] = useState({});
  const [updateUser] = useMutation(UPDATE_USER);
  const [deleteUser] = useMutation(DELETE_USER);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const handleStatusChange = (tenantId, status) => {
    setUserStatus((prevStatus) => ({
      ...prevStatus,
      [tenantId]: status,
    }));
  };
  const handleDelete = async (userId) => {
    try {
      await deleteUser({
        variables: {
          tenantId: localStorage.getItem("tenantId"),
          userId,
        },
      });
      alert(`user ${userId} deleted successfully.`);
      window.location.reload();
    } catch (error) {
      console.error("Error updating tenant status:", error.message);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUser({
        variables: {
          input: formData,
        },
      });

      setFormData({
        tenantId: localStorage.getItem("tenantId"),
        name: "",
        email: "",
        isAdmin: false,
      });

      alert("User created successfully!");
    } catch (error) {
      alert(`Error creating user: ${error.message}`);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendPasswordResetEmail(auth, email);

      const user = userCredential.user;
      console.log("User created successfully:", user);

  
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error("Error creating user:", errorCode, errorMessage);

      alert(`Error: ${errorMessage}`);
    }
  };

  const handleSaveClick = async (userId) => {
    try {
      await updateUser({
        variables: {
          tenantId: localStorage.getItem("tenantId"),
          userId,
          updatedUserData: {
            isAdmin: UserStatus[userId] === "True" ? true : false,
          },
        },
      });
      console.log(`Status for User ID ${userId} updated successfully.`);
    } catch (error) {
      console.error("Error updating tenant status:", error.message);
    }
  };
  const renderStatusDropdown = (user) => {
    const statusOptions = ["True", "False"];

    return (
      <div className="flex items-center">
        <select
          className="border p-2 rounded-md "
          onChange={(e) => handleStatusChange(user.id, e.target.value)}
          value={UserStatus[user.id] || user.status}
        >
          {statusOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <main className="relative flex flex-col mx-auto">
      <div className="flex justify-between items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="flex flex-col mt-2">
        <div className="text-[#b00041] px-4">
          <div className="flex flex-row justify-between items-center gap-x-4">
            <h1 className="text-4xl font-black mb-4">AdminTenant Settings</h1>
            <button
              className="box navlink-button navlink-button-hover"
              onClick={() => setModalOpen(true)}
            >
              Create User
            </button>
          </div>
          {modalOpen && (
            <div className=" fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div className="bg-white p-8 w-[700px] rounded-md">
                <h2 className="text-xl font-semibold mb-4">Create User</h2>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleFormSubmit(e);
                    onSubmit(e);
                  }}
                >
                  <div className="mb-4 mt-3">
                    <label
                      htmlFor="name"
                      className="block text-black font-black"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Vrushab Hanumesh"
                      onChange={(e) => handleInputChange(e)}
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded text-black"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-black font-black"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      label="Email address"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        handleInputChange(e);
                      }}
                      required
                      placeholder="Email address"
                      className="w-full px-3 py-2 border border-gray-300 rounded text-black"
                    />
                  </div>
                  <div className="flex flex-row space-x-3 justify-end">
                    <button
                      type="submit"
                      className="bg-rose-700 text-white p-2 rounded-md "
                    >
                      Create User
                    </button>
                    <button
                      className="bg-white   p-2 rounded-md text-black shadow-black shadow-xs border-2"
                      onClick={() => setModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  User ID
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Email Id
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Is Admin
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Save Change
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.getAllUsers.map((user) => (
                <tr key={user.id}>
                  <td className="px-6 py-4 text-gray-500">{user.id}</td>
                  <td className="px-6 py-4 text-black ">{user.name}</td>
                  <td className="px-6 py-4 text-black ">{user.email}</td>
                  <td className="px-6 py-4 ">{renderStatusDropdown(user)}</td>
                  <td className="px-6 py-4 ">
                    <button
                      className="bg-rose-700 text-white p-2 rounded-md"
                      onClick={() => handleSaveClick(user.id)}
                    >
                      Save
                    </button>
                  </td>
                  <td className="px-6 py-4 ">
                    <button
                      className="bg-rose-700 text-white p-2 rounded-md"
                      onClick={() => handleDelete(user.id)}
                    >
                      <Delete />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}

export default AdminTenant;

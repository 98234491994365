import React, { useState, useRef, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TEMPLATES_BY_CATEGORY } from "../../GraphQL/Tickets/Queries";
// import SearchBar from "../SearchBar";
// import Navbar from "../Navbar";
import { Forward as ForwardIcon } from "@mui/icons-material";
import { DeleteForever, Edit } from "@mui/icons-material";
import ToastAlert, { showToast } from "../ToastAlert";
import { DELETE_TEMPLATE, DELETE_CATEGORY } from "../../GraphQL/Tickets/Mutations";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem, IconButton } from "@mui/material";

const TemplateList = () => {
  const location = useLocation();
  const catdata = location.state.catdata || {};
  const navigate = useNavigate();
  const tenantId = localStorage.getItem("tenantId");
  const userId = localStorage.getItem("userid");
  const [deleteTemplate] = useMutation(DELETE_TEMPLATE);
  const { loading, error, data } = useQuery(GET_TEMPLATES_BY_CATEGORY, {
    variables: { tenantId, categoryId: catdata.id },
  });

  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteCategory] = useMutation(DELETE_CATEGORY);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading templates</div>;
  }

  const handleDelete = async (id, categoryId, name) => {
    try {
      await deleteTemplate({
        variables: {
          tenantId,
          categoryId,
          id,
        },
      });

      const toastContent = (
        <div>
          <p className="text-ruby-red text-bold">Template Deleted Successfully!</p>
          <p className="text-green-500">{name}</p>
        </div>
      );
      showToast(toastContent, "success");
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } catch (error) {
      showToast("Please Verify Ticket Details");
      console.error("Error deleting Ticket:", error);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDeleteCategory = async () => {
    try {
      await deleteCategory({
        variables: {
          tenantId,
          id: catdata.id,
        },
      });

      const toastContent = (
        <div>
          <p className="text-ruby-red text-bold">Category Deleted Successfully!</p>
          <p className="text-green-500">{catdata.name}</p>
        </div>
      );
      showToast(toastContent, "success");
      setTimeout(() => {
        navigate("/tickets");
        window.location.reload();
      }, 2500);
    } catch (error) {
      showToast("Please Verify Category Details");
      console.error("Error deleting Category:", error);
    }
  };

  const isTemplateAvailable = (template) => {
    const currentDay = new Date().toLocaleString('en-US', { weekday: 'long' });
    const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });

    const [currentHour, currentMinute] = currentTime.split(":").map(Number);
    const [availableFromHour, availableFromMinute] = template.availableFrom.split(":").map(Number);
    const [availableToHour, availableToMinute] = template.availableTo.split(":").map(Number);

    const isAvailableDay = template.availabilityDays.includes(currentDay);
    const isAvailableTime =
      (currentHour > availableFromHour || (currentHour === availableFromHour && currentMinute >= availableFromMinute)) &&
      (currentHour < availableToHour || (currentHour === availableToHour && currentMinute <= availableToMinute));

    return isAvailableDay && isAvailableTime;
  };

  return (
    <div className="w-full overflow-scroll scrollbar-hide">
      <ToastAlert />
      <div className="flex justify-between items-center space-x-2">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="text-[#b00041] flex items-center justify-between px-4 space-x-2">
        <div className="flex flex-row space-x-5">
          <NavLink className="flex items-center gap-x-4 hover:text-red-800" to="/tickets">
            <ForwardIcon fontSize="large" className="rotate-180" />
          </NavLink>
          <h1 className="text-4xl font-black">{catdata.name}</h1>
        </div>
        <div className="relative" ref={dropdownRef}>
          <IconButton onClick={toggleDropdown}>
            <MoreVertIcon fontSize="large" className="text-ruby-red" />
          </IconButton>
          <Menu anchorEl={dropdownRef.current} open={dropdownOpen} onClose={() => setDropdownOpen(false)}>
            <MenuItem onClick={() => setDropdownOpen(false)}>
              <NavLink to="/tickets/createcategory" state={{ catdata: catdata, iscreate: false }} className="block px-4 py-2 text-gray-800 ">
                Edit Category
              </NavLink>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDropdownOpen(false);
                handleDeleteCategory();
              }}
            >
              <h2 className="block px-4 py-2 text-gray-800 hover:text-ruby-red ">Delete Category</h2>
            </MenuItem>
            <MenuItem onClick={() => setDropdownOpen(false)}>
              <NavLink to={`/tickets/${catdata.id}/createtemplate`} state={{ catdata: catdata, iscreate: true }} className="block px-4 py-2 text-gray-800 ">
                Create Template
              </NavLink>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="card w-[70rem] max-h-[10000rem] justify-between">
        <div>
          {data.getTemplatesByCategory.map((template, index) => {
            const canEditOrDelete = template.assigneeIds.includes(userId);
            const canAnswer = template.visibility.includes(userId);

            if (!canEditOrDelete && !canAnswer) {
              return null;
            }


            return (
              <div key={index} className="flex flex-col border border-gray-400 px-3 py-3 gap-2 rounded-md mt-2 shadow shadow-sm">
                <div className="flex flex-row justify-between">
                  {canAnswer && isTemplateAvailable(template) ? (
                    <NavLink to={{ pathname: `/tickets/response/${template.id}` }} state={{ template: template }}>
                      <h2 className="font-semibold text-ruby-red">{template.title}</h2>
                    </NavLink>
                  ) : (
                    <h2 className="font-semibold text-black">{template.title}</h2>
                  )}
                  <div className="text-ruby-red space-x-3">
                    {canEditOrDelete && (
                      <>
                        <NavLink
                          to={{
                            pathname: `/tickets/${template.categoryId}/createtemplate`,
                          }}
                          state={{ template: template, iscreate: false }}
                        >
                          <Edit className="text-ruby-red" />
                        </NavLink>
                        <button
                          onClick={() =>
                            handleDelete(template.id, template.categoryId, template.title)
                          }
                        >
                          <DeleteForever className="text-ruby-red" />
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <p className="text-gray-400">{template.createdBy.name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TemplateList;

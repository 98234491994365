import { gql } from "@apollo/client";

const UPDATE_TENANT_STATUS = gql`
  mutation UpdateTenantStatus($id: ID!, $status: String!) {
    updateTenantStatus(input: { id: $id, status: $status }) {
      id
      status
      companyName
      companySize
      additionalInfo
    }
  }
`;
const CREATE_TENANT = gql`
  mutation CreateTenant($input: CreateTenantInput!) {
    createTenant(input: $input) {
      id
      status
      companyName
      companySize
      additionalInfo
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      name
      email
      isAdmin
      groups
    }
  }
`;
const UPDATE_USER = gql`
  mutation UpdateUserById(
    $tenantId: ID!
    $userId: ID!
    $updatedUserData: UpdateUserInput
  ) {
    updateUserById(
      tenantId: $tenantId
      userId: $userId
      updatedUserData: $updatedUserData
    ) {
      success
    }
  }
`;
const DELETE_USER = gql`
  mutation DeleteUserById($tenantId: ID!, $userId: ID!) {
    deleteUserById(tenantId: $tenantId, userId: $userId) {
      success
    }
  }
`;
export {
  UPDATE_TENANT_STATUS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  CREATE_TENANT,
};

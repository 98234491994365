import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from "@mui/icons-material/Groups";
import ChecklistIcon from '@mui/icons-material/Checklist';
import StarIcon from '@mui/icons-material/Star';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import SettingsIcon from '@mui/icons-material/Settings';
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FaSearch } from "react-icons/fa";
import { MdViewModule } from 'react-icons/md';
import logo from '../../assets/ruby_logo.svg';
import { Sidebar, SubMenu, Menu, MenuItem } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';
import { auth } from '../../firebase';
import { useQuery } from "@apollo/client";
import WorkspaceSwitcher from '../../pages/WorkspaceSwitcher';
import WorkspaceModal from "../../pages/WorkspaceSwitcher";
import { GET_ALL_WORKSPACES } from "../../GraphQL/NewMethods/Queries"
import FeedbackForm from '../FeedbackForm';
import { set } from 'firebase/database';

function Sidebars({ onCollapse }) {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [personalWorkspaces, setPersonalWorkspaces] = useState([]);
  const [allWorkspaces, setAllWorkspaces] = useState([]);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility
  const [openFeedback, setOpenFeedback] = useState(false); //openFeedback: FeedbackForm is closed initially , setOpenFeedback: to update the state of openFeedback
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [currentUser, setCurrentUser] = useState(null);
  const [open, setOpen, setShowText] = useState(false);
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid'); // Replace with the actual current user ID

 

  const { loading, error, data } = useQuery(GET_ALL_WORKSPACES);
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCollapsed(true);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if (data) {
      const personal = data.getAllWorkspaces.filter(
        (workspace) => workspace.leader.id === userId
      );
      const all = data.getAllWorkspaces.filter(
        (workspace) =>
          (workspace.members !== null && workspace.members.some((member) => member.id === userId)) || workspace.leader.id === userId
      );

      setPersonalWorkspaces(personal);
      setAllWorkspaces(all);
    }
  }, [data, userId]);

  useEffect(() => {
    document.querySelector("html").classList.toggle("dark", theme === "dark");
  }, [theme]);

  const handleChangeTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  }; 



  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSettingsToggle = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const handleNavigateToSettings = () => {
    navigate("/settings");
  };

  const handleOpenFeedback = () => {
    setOpenFeedback(true);
  }
  const handleCloseFeedback = () => {
    setOpenFeedback(false);
  }
  if (!userId) {
    return null;
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="sidebar-wrapper">
      <div className={`sidebar-container ${collapsed ? 'collapsed' : ''}`}>
        <Sidebar
          className={`max-h-screen shadow-md bg-white dark:bg-darkcard dark:text-white ${toggled ? 'toggled' : ''}`}
          style={{ height: '100vh' }} 
        >
        <main className="dark:bg-darkcard flex flex-col h-full">
          <Menu className="dark:bg-darkcard p-2">
              <NavLink exact to="/" activeClassName="active-link" className="flex flex-row items-center styled-navlink">
                <div className="styled-menu-item">
                  <img src={logo} alt="logo" className="w-10 h-10 pl-2 ml-2" />
                  {!collapsed && <h1 className="title">rubylog</h1>}
                  <ChevronLeftIcon
                    className={`toggle-icon ${collapsed ? 'collapsed' : ''}`}
                  />
                </div>
              </NavLink>
              {!collapsed && (
                <>
                  <div className="search-bar-container">
                    <div className="search-wrapper">
                      <FaSearch className="search-icon" />
                      <input type="text" placeholder="search" className="search-bar"/>
                    </div>
                  </div>
                  <hr className="custom-hr" />
                  <div className="button-container">
                  <NavLink to="/favorites" className="styled-button" activeClassName = "active" >
                      <StarIcon className="styled-icon"/>
                      Favorites
                    </NavLink>
                    <NavLink to="/audits" className="styled-button" activeClassName = "active">
                      <ChecklistIcon className="styled-icon" />
                      Audits
                    </NavLink>
                    <NavLink to="/tickets" className="styled-button" activeClassName = "active" >
                      <ConfirmationNumberIcon className="styled-icon" />
                      Tickets
                    </NavLink>
                    <NavLink to="/data-entry" className="styled-button" activeClassName = "active" >
                      <MdViewModule className="styled-icon" />
                      Data Entry
                    </NavLink>
                  </div>
                  <hr className="custom-hr" />
                </>
              )}

            
                {!collapsed && <h2 className="subtitle">workspace</h2>}
                <SubMenu defaultOpen label={"Personal Workspaces"} icon={<PersonIcon />}>
                  {personalWorkspaces.map((workspace) => (
                    <SubMenu key={workspace.id} label={workspace.name} icon={<GroupsIcon />}>
                      <MenuItem
                        onClick={() => {
                          navigate(`/workspace/${workspace.id}`);
                        }}
                        icon={<GroupsIcon />}
                        className="dark:bg-darkcard dark:text-white"
                      >
                        Home
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/workspace/${workspace.id}/audits`);
                        }}
                        icon={<GroupsIcon />}
                        className="dark:bg-darkcard dark:text-white"
                      >
                        Audits
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/workspace/${workspace.id}/tickets`);
                        }}
                        icon={<GroupsIcon />}
                        className="dark:bg-darkcard dark:text-white"
                      >
                        Tickets
                      </MenuItem>
                      <MenuItem
                        icon={<ChecklistIcon />}
                        className="dark:bg-darkcard dark:text-white"
                      >
                        <NavLink 
                          to={`/workspace/${workspace.id}/create-template`} 
                          state={{ workspace: workspace }}
                          className="text-black"
                        >
                          Create Template
                        </NavLink>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/workspace/${workspace.id}/settings`);
                        }}
                        icon={<SettingsIcon />}
                        className="dark:bg-darkcard dark:text-white"
                      >
                        Settings
                      </MenuItem>
                    </SubMenu>
                  ))}
                </SubMenu>
                <SubMenu defaultOpen label={"All Workspaces"} icon={<GroupsIcon />}>
                  {allWorkspaces.map((workspace) => (
                    <SubMenu key={workspace.id} label={workspace.name} icon={<GroupsIcon />}>
                      <MenuItem
                      onClick={() => {
                        navigate(`/workspace/${workspace.id}`);
                      }}
                      icon={<GroupsIcon />}
                      className="dark:bg-darkcard dark:text-white"
                   >
                    Home
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate(`/workspace/${workspace.id}/audits`);
                      }}
                      icon={<GroupsIcon />}
                      className="dark:bg-darkcard dark:text-white"
                    >
                    Audits
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate(`/workspace/${workspace.id}/tickets`);
                      }}
                      icon={<GroupsIcon />}
                      className="dark:bg-darkcard dark:text-white"
                    >
                    Tickets
                    </MenuItem>
                    <MenuItem
                 
                      icon={<ChecklistIcon />}
                      className="dark:bg-darkcard dark:text-white"
                    >
                    <NavLink 
                       to={`/workspace/${workspace.id}/create-template`} 
                        state={{ workspace: workspace }}
                        className="text-black"
                      >
                        Create Template
                      </NavLink>

                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate(`/workspace/${workspace.id}/settings`);
                      }}
                      icon={<SettingsIcon />}
                      className="dark:bg-darkcard dark:text-white"
                    >
                      Settings
                    </MenuItem>
                  
                  </SubMenu>
                ))}
            </SubMenu>
            <MenuItem
              onClick={handleModalOpen}
              icon={<GroupsIcon />}
              className="dark:bg-darkcard dark:text-white"
            >
              Create Workspace
            </MenuItem>
            </Menu>
            <div className="profile-section dark:bg-darkcard dark:text-white">
              <PersonIcon className="styled-person-icon" />
              <WorkspaceSwitcher />
              {/* {!collapsed && (
                <div className="profile-details">
                  <p className="profile-name">Antonio</p>
                  <p className="profile-title">Hephium</p>
                </div>
              )} */}
              <SettingsIcon onClick={handleSettingsToggle} className="styled-settings-icon" />
            </div>
          </main>
        </Sidebar>
        <WorkspaceModal open={modalOpen} handleClose={handleModalClose} />
      </div>
      {isSettingsOpen && (
        <div className="settings-popup">
        <div className="popup-button" onClick={handleLogout}>
          log out
        </div>
        <hr className="custom-hr" />
        <div className="popup-button" onClick={handleChangeTheme}>
          toggle dark mode
          <div className="styled-icon">
            {theme === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
          </div>
        </div>
        <hr className="custom-hr" />
      
         <div className='popup-button' onClick={handleOpenFeedback}>{/* When clicked, openFeedback is set to true with handleOpenFeedback which invokes setOpenFeedback */}
        submit feedback  
          </div>
          <hr className="custom-hr" />
          <div className='popup-button' onClick={handleNavigateToSettings}>
          settings
        </div>
      
      </div>
      )}
      <FeedbackForm open={openFeedback} onClose={handleCloseFeedback} />
    </div>
  );
}

export default Sidebars;
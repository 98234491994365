import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Switch from "@mui/material/Switch";
import CancelIcon from "@mui/icons-material/Cancel";

const defaultQuestion = {
  questionType: "checkbox",
  questionName: "",
  values: [""],
  isRequired: false,
  response: [],
  failvalue: "notneeded",
};

const AuditFrom = ({ allquestions, onUpdateQuestions }) => {
  const [questions, setQuestions] = useState(allquestions);

  useEffect(() => {
    setQuestions(allquestions);
  }, [allquestions]);

  const updateQuestions = (newQuestions) => {
    setQuestions(newQuestions);
    onUpdateQuestions(newQuestions);
  };

  const handleQuestionTypeChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index] = {
      ...newQuestions[index],
      questionType: value,
      values: [""],
      failvalue: "",
    };
    updateQuestions(newQuestions);
  };

  const handlequestionNameChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index] = { ...newQuestions[index], questionName: value };
    updateQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].values[optionIndex] = value;
    updateQuestions(newQuestions);
  };

  const handleAddOption = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].values.push("");
    updateQuestions(newQuestions);
  };

  const handleRemoveOption = (questionIndex, optionIndex,e) => {
    e.preventDefault();
    const newQuestions = [...questions];
    if (newQuestions[questionIndex].values.length > 2) {
      newQuestions[questionIndex].values.splice(optionIndex, 1);
      updateQuestions(newQuestions);
    } else {
      alert("A question must have at least two options.");
    }
  };

  const handleToggleIsRequired = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].isRequired =
      !newQuestions[questionIndex].isRequired;
    updateQuestions(newQuestions);
  };

  const handleDeleteQuestion = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions.splice(questionIndex, 1);
    updateQuestions(newQuestions);
  };

  const handleAddQuestion = (e) => {
    e.preventDefault();
    updateQuestions([...questions, { ...defaultQuestion }]);
  };

  const handleCopyQuestion = (questionIndex) => {
    const questionToCopy = JSON.parse(JSON.stringify(questions[questionIndex]));
    const newQuestions = [...questions];
    newQuestions.splice(questionIndex + 1, 0, questionToCopy);
    updateQuestions(newQuestions);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedQuestions = Array.from(questions);
    const [removed] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, removed);
    updateQuestions(reorderedQuestions);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="questions">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="flex flex-col space-y-4 mt-1 font-nunito"
          >
            {questions.map((question, index) => (
              <Draggable
                key={index}
                draggableId={`question-${index}`}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className=" bg-gray-50 dark:bg-darkcard border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1"
                  >
                    <div className="flex items-center space-x-4 mb-2">
                      <div className="w-[48%]">
                        <h2 className="ml-4 font-semibold mt-2 ">Question</h2>
                        <input
                          type="text"
                          className="w-[296px] h-8 mt-2 ml-4 pl-2  text-gray-500 text-sm font-normal  border-b-2 bg-gray-50"
                          placeholder="Untitled Question"
                          value={question.questionName}
                          onChange={(e) =>
                            handlequestionNameChange(index, e.target.value)
                          }
                        />
                      </div>
                      <div>
                        <h2 className=" font-semibold mt-2 ">Question Type</h2>
                        <select
                          className="border p-2 mt-2 h-9 text-sm rounded"
                          value={question.questionType}
                          onChange={(e) =>
                            handleQuestionTypeChange(index, e.target.value)
                          }
                        >
                          <option value="checkbox">Checkbox</option>
                          <option value="multipleChoice">
                            Multiple Choice
                          </option>
                        </select>
                      </div>
                  
                    </div>
                    <div className="flex flex-row p-2 m-2">
                    <h2 className=" font-semibold w-[50%]">Options</h2>
                    {question.questionType === "multipleChoice" && (
                        <div>
                          <span className=" font-semibold ">
                            Fail Value:{" "}
                          </span>
                          <select
                            className="border pl-1 h-5 w-40 text-sm text-black rounded"
                            value={question.failvalue}
                            onChange={(e) => {
                              const newQuestions = [...questions];
                              newQuestions[index].failvalue = e.target.value;
                              updateQuestions(newQuestions);
                            }}
                          >
                            <option value="">Select Fail Value</option>
                            {question.values.map((option, optionIndex) => (
                              <option key={optionIndex} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                    
                    )}
                        </div>
                    <div className="ml-4 text-sm">
                      {(question.questionType === "checkbox" ||
                        question.questionType === "multipleChoice") && (
                        <div className="space-y-2">
                          {question.values.map((option, optionIndex) => (
                            <div
                              key={optionIndex}
                              className="flex items-center space-x-2"
                            >
                          
                              <input
                                type="text"
                                className="border p-2 h-5 w-40 text-sm rounded"
                                placeholder={`Option ${optionIndex + 1}`}
                                value={option}
                                onChange={(e) => {
                                  handleOptionChange(
                                    index,
                                    optionIndex,
                                    e.target.value
                                  );
                                }}
                              />
                              {question.values.length > 2 && (
                                <button
                                  onClick={(e) =>
                                    handleRemoveOption(index, optionIndex,e)
                                  }
                                >
                                  <CancelIcon />
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            onClick={() => handleAddOption(index)}
                            type="button"
                            className="bg-rose-700 text-sm text-white w-40 h-6 mt-3 mb-3 rounded"
                          >
                            Add Option
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="pl-[570px]">
                      <DeleteIcon
                        style={{ color: "#b00041", strokeWidth: "2" }}
                        fontSize="medium"
                        onClick={() => handleDeleteQuestion(index)}
                        className="cursor-pointer"
                      />
                      <ContentCopyIcon
                        style={{ color: "#b00041", strokeWidth: "2" }}
                        fontSize="medium"
                        onClick={() => handleCopyQuestion(index)}
                        className="cursor-pointer ml-3"
                      />
                      <span className="text-sm ml-3">Required</span>
                      <Switch
                        checked={question.isRequired}
                        onChange={() => handleToggleIsRequired(index)}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <button
        onClick={(e) => handleAddQuestion(e)}
        className="bg-rose-700 text-white mt-2 px-2 py-1 rounded"
      >
        Add Question
      </button>
    </DragDropContext>
  );
};

export default AuditFrom;

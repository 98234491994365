import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Switch from "@mui/material/Switch";
import CancelIcon from "@mui/icons-material/Cancel";
import ShortTextIcon from "@mui/icons-material/ShortText";
import SubjectIcon from "@mui/icons-material/SubjectOutlined";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBoxOutlined";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUploadOutlined";
import GridOnIcon from "@mui/icons-material/GridOnOutlined";
import ViewCompactIcon from "@mui/icons-material/ViewCompactOutlined";
import DateRangeIcon from "@mui/icons-material/DateRangeOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTimeOutlined";
import { MenuItem, Select, Divider } from "@mui/material";
import { NumbersOutlined } from "@mui/icons-material";

const defaultQuestion = {
  questionType: "checkbox",
  questionName: "",
  values: [""],
  isRequired: false,
  failvalue: "",
};

const QuestionsForm = ({ questions, setQuestions, templateType }) => {
  useEffect(() => {
    setQuestions(questions);
  }, [questions]);

  const updateQuestions = (newQuestions) => {
    setQuestions(newQuestions);
  };

  const handleQuestionTypeChange = (index, value) => {
    const newQuestions = JSON.parse(JSON.stringify(questions));
    newQuestions[index] = {
      ...newQuestions[index],
      questionType: value,
      values: value === "linear scale" ? ["1", "5", "", ""] : [""],
    };
    updateQuestions(newQuestions);
  };

  const handleQuestionNameChange = (index, value) => {
    const newQuestions = JSON.parse(JSON.stringify(questions));
    newQuestions[index] = { ...newQuestions[index], questionName: value };
    updateQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = JSON.parse(JSON.stringify(questions));
    newQuestions[questionIndex].values[optionIndex] = value;
    updateQuestions(newQuestions);
  };

  const handleAddOption = (questionIndex) => {
    const newQuestions = JSON.parse(JSON.stringify(questions));
    newQuestions[questionIndex].values.push("");
    updateQuestions(newQuestions);
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    const newQuestions = JSON.parse(JSON.stringify(questions));
    newQuestions[questionIndex].values.splice(optionIndex, 1);
    updateQuestions(newQuestions);
  };

  const handleToggleIsRequired = (questionIndex) => {
    const newQuestions = JSON.parse(JSON.stringify(questions));
    newQuestions[questionIndex].isRequired =
      !newQuestions[questionIndex].isRequired;
    updateQuestions(newQuestions);
  };

  const handleDeleteQuestion = (questionIndex) => {
    const newQuestions = JSON.parse(JSON.stringify(questions));
    newQuestions.splice(questionIndex, 1);
    updateQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    updateQuestions([...questions, { ...defaultQuestion }]);
  };

  const handleCopyQuestion = (questionIndex) => {
    const questionToCopy = JSON.parse(JSON.stringify(questions[questionIndex]));
    const newQuestions = JSON.parse(JSON.stringify(questions));
    newQuestions.splice(questionIndex + 1, 0, questionToCopy);
    updateQuestions(newQuestions);
  };

  return (
    <div className="mb-3">
      <label className="mr-3">Questions</label>
      {questions.map((question, index) => (
        <div
          key={index}
          className="w-full h-max-[252px] relative bg-stone-50 rounded-md mt-2 shadow border border-gray-300"
        >
          <div className="flex items-center space-x-4 mb-2">
            <input
              type="text"
              className="w-[296px] h-8 mt-2 ml-5 mr-20 text-gray-500 text-sm font-normal font-['Inter'] leading-tight"
              placeholder="Untitled Question"
              value={question.questionName}
              onChange={(e) => handleQuestionNameChange(index, e.target.value)}
            />
            <Select
              className="border p-2 mt-2 h-9 text-sm rounded"
              value={question.questionType}
              onChange={(e) => handleQuestionTypeChange(index, e.target.value)}
            >
              <MenuItem value="short answer">
                <ShortTextIcon className="mr-2 text-gray-500" />
                Short Answer
              </MenuItem>
              <MenuItem value="paragraph">
                <SubjectIcon className="mr-2 text-gray-500" />
                Paragraph
              </MenuItem>
              <MenuItem value="number">
                <NumbersOutlined className="mr-2 text-gray-500" />
                Number
              </MenuItem>
              <Divider
                style={{
                  margin: "10px 0",
                  backgroundColor: "grey",
                  height: "0.8px",
                  boxShadow: "2px",
                }}
              />
              <MenuItem value="multiple choice">
                <RadioButtonCheckedIcon className="mr-2 text-gray-500" />
                Multiple Choice
              </MenuItem>
              <MenuItem value="checkbox">
                <CheckBoxIcon className="mr-2 text-gray-500" />
                Checkbox
              </MenuItem>
              <MenuItem value="dropdown">
                <ArrowDropDownCircleIcon className="mr-2 text-gray-500" />
                Dropdown
              </MenuItem>
              <Divider
                style={{
                  margin: "10px 0",
                  backgroundColor: "grey",
                  height: "0.8px",
                  boxShadow: "2px",
                }}
              />
              <MenuItem value="fileupload">
                <CloudUploadIcon className="mr-2 text-gray-500" />
                File Upload
              </MenuItem>
              <Divider
                style={{
                  margin: "10px 0",
                  backgroundColor: "grey",
                  height: "0.8px",
                  boxShadow: "2px",
                }}
              />
              <MenuItem value="linear scale">
                <RadioButtonCheckedIcon className="mr-2 text-gray-500" />
                Linear Scale
              </MenuItem>
              <MenuItem value="multiple choice grid">
                <ViewCompactIcon className="mr-2 text-gray-500" />
                Multiple Choice Grid
              </MenuItem>
              <MenuItem value="checkbox grid">
                <GridOnIcon className="mr-2 text-gray-500" />
                Checkbox Grid
              </MenuItem>
              <Divider
                style={{
                  margin: "10px 0",
                  backgroundColor: "grey",
                  height: "0.8px",
                  boxShadow: "2px",
                }}
              />
              <MenuItem value="date">
                <DateRangeIcon className="mr-2 text-gray-500" />
                Date
              </MenuItem>
              <MenuItem value="time">
                <AccessTimeIcon className="mr-2 text-gray-500" />
                Time
              </MenuItem>
            </Select>
          </div>
          <div className="ml-5 text-sm">
            {(question.questionType === "checkbox" ||
              question.questionType === "multiple choice" ||
              question.questionType === "dropdown" ||
              question.questionType === "multiple choice grid" ||
              question.questionType === "checkbox grid") && (
                
              <div className="space-y-2">
                    {templateType === "audit" &&
            question.questionType === "multiple choice" && (
              <div className="flex items-center space-x-4 mb-2">
                <label>Fail Value</label>
                <select
                  className="border p-2 h-9 text-sm rounded"
                  value={question.failvalue}
                  onChange={(e) => {
                    const newQuestions = [...questions];
                    newQuestions[index].failvalue = e.target.value;
                    updateQuestions(newQuestions);
                  }}
                >
                  <option value="">Select Fail Value</option>
                  {question.values.map((option, optionIndex) => (
                    <option key={optionIndex} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
                {question.values.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    className="flex items-center space-x-2"
                  >
                    <input
                      type="text"
                      className="border p-2 h-5 w-40 text-sm rounded"
                      placeholder={`Option ${optionIndex + 1}`}
                      value={option}
                      onChange={(e) => {
                        handleOptionChange(index, optionIndex, e.target.value);
                      }}
                    />
                    {optionIndex > 0 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveOption(index, optionIndex)}
                      >
                        <CancelIcon />
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => handleAddOption(index)}
                  className="bg-rose-700 text-sm text-white w-40 h-6 ml-6 mb-3 rounded"
                >
                  Add Option
                </button>
              </div>
            )}
            {question.questionType === "linear scale" && (
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <label>Min Value</label>
                  <input
                    type="number"
                    className="border p-2 h-5 w-20 text-sm rounded"
                    value={question.values[0]}
                    onChange={(e) =>
                      handleOptionChange(index, 0, e.target.value)
                    }
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <label>Max Value</label>
                  <input
                    type="number"
                    className="border p-2 h-5 w-20 text-sm rounded"
                    value={question.values[1]}
                    onChange={(e) =>
                      handleOptionChange(index, 1, e.target.value)
                    }
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <label>Min Value Label</label>
                  <input
                    type="text"
                    className="border p-2 h-5 w-40 text-sm rounded"
                    value={question.values[2]}
                    onChange={(e) =>
                      handleOptionChange(index, 2, e.target.value)
                    }
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <label>Max Value Label</label>
                  <input
                    type="text"
                    className="border p-2 h-5 w-40 text-sm rounded"
                    value={question.values[3]}
                    onChange={(e) =>
                      handleOptionChange(index, 3, e.target.value)
                    }
                  />
                </div>
              </div>
            )}
          </div>
      
          <div className="pl-[460px]">
            <DeleteIcon
              style={{ color: "#b00041", strokeWidth: "2" }}
              fontSize="medium"
              onClick={() => handleDeleteQuestion(index)}
              className="cursor-pointer"
            />
            <ContentCopyIcon
              style={{ color: "#b00041", strokeWidth: "2" }}
              fontSize="medium"
              onClick={() => handleCopyQuestion(index)}
              className="cursor-pointer ml-3"
            />
            <span className="text-sm ml-3">Required</span>
            <Switch
              checked={question.isRequired}
              onChange={() => handleToggleIsRequired(index)}
              className="cursor-pointer"
            />
          </div>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddQuestion}
        className="bg-rose-700 text-white mt-2 px-2 py-1 rounded"
      >
        Add Question
      </button>
    </div>
  );
};

export default QuestionsForm;

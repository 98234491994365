import React from "react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useQuery } from "@apollo/client";
import { GET_ALL_AUDITS } from "../../GraphQL/Audits/Queries";
function MyAudits() {
  const { loading, error, data } = useQuery(GET_ALL_AUDITS, {
    variables: {
      tenantId: localStorage.getItem('tenantId'),
    },
  });
  console.log(data);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  const statusStyle = (status) => {
    if (status === "In Progress") {
      return (
        <h2 className="my-audits-item text-green-700 bg-green-200  ">
          {status}
        </h2>
      );
    } else if (status === "Late") {
      return (
        <h3 className="text-red-700 bg-red-200 my-audits-item">{status}</h3>
      );
    } else if (status === "Due") {
      return (
        <h3 className="text-yellow-700 bg-yellow-200 my-audits-item">
          {status}
        </h3>
      );
    }
  };

  return (
    <div className="card w-[25rem] h-[35rem] overflow-scroll scrollbar-hide">
      <h1>My Audits</h1>
      {data.getAllAudits.map((audit, index) => (
        <div className="flex flex-col border border-gray-400 px-3 py-2 gap-2.5">
          <div className="flex flex-row justify-between">
            <h2 className="font-semibold text-[#621029]">{audit.auditTitle}</h2>
            <div className="flex ">{statusStyle(audit.resolution)}</div>
          </div>

          <div className="flex gap-x-2 text-gray-400 items-center">
            <CalendarTodayIcon />
            <h2>{audit.endDate}</h2>
          </div>
          <div className="flex flex-row space-x-2">
            {audit.assignee.map((assignee, index) => (
              <h3>{assignee.name}</h3>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default MyAudits;
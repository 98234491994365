import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_TICKETS } from "../../GraphQL/Tickets/Queries";
import { APPROVE_TICKET, DENY_TICKET } from "../../GraphQL/Tickets/Mutations";
import { NavLink, useNavigate } from "react-router-dom";
// import SearchBar from "../SearchBar";
// import Navbar from "../Navbar";
import Pagination from "@mui/material/Pagination";
import { Forward as ForwardIcon } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";

function TicketList() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [additionalComment, setAdditionalComment] = useState("");
  useEffect(() => {
    if (localStorage.getItem("isAdmin") === "false") {
      alert("You are not an admin, you can't access this page");
      navigate("/tickets");
    }
  }, [navigate]);

  const { loading, error, data } = useQuery(GET_ALL_TICKETS, {
    variables: {
      tenantId: localStorage.getItem("tenantId"),
    },
  });

  const [activeTab, setActiveTab] = useState("Approved");
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [denialReasons, setDenialReasons] = useState([]);
  const [updateStatus, setUpdateStatus] = useState("");
  const [approveTicket] = useMutation(APPROVE_TICKET);
  const [denyTicket] = useMutation(DENY_TICKET);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const filterTicketsByStatus = (status) => {
    return data.getAllTickets.filter(
      (ticket) =>
        ticket.status === status &&
        ticket.assignees.includes(localStorage.getItem("userid"))
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const statusStyle = (status) => {
    if (status === "Approved") {
      return (
        <h2 className="my-audits-item text-green-700 bg-green-200 ">
          {"Approved"}
        </h2>
      );
    } else if (status === "Denied") {
      return (
        <h3 className="text-red-700 bg-red-200 my-audits-item">{"Denied"}</h3>
      );
    } else if (status === "Pending") {
      return (
        <h3 className="text-black bg-stone-200 my-audits-item">{"Pending"}</h3>
      );
    }
  };

  const filteredTickets = filterTicketsByStatus(activeTab);
  const indexOfLastTicket = page * rowsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - rowsPerPage;
  const paginatedTickets = filteredTickets.slice(
    indexOfFirstTicket,
    indexOfLastTicket
  );

  const openTicketDetails = (ticket) => {
    setSelectedTicket(ticket);
    setDenialReasons(ticket.denialReasons || []);
    setUpdateStatus(ticket.status);
    setAdditionalComment(ticket.additionalComment || []);
  };

  const closeTicketDetails = () => {
    setSelectedTicket(null);
    setDenialReasons([]);
    setUpdateStatus("");
    navigate(0);
  };

  const approveSelectedTicket = async () => {
    try {
      await approveTicket({
        variables: {
          tenantId: localStorage.getItem("tenantId"),
          ticketId: selectedTicket.id,
          additionalComment,
        },
      });
      closeTicketDetails();
    } catch (error) {
      console.error("Error approving ticket:", error);
    }
  };

  const denySelectedTicket = async () => {
    try {
      await denyTicket({
        variables: {
          tenantId: localStorage.getItem("tenantId"),
          ticketId: selectedTicket.id,
          reasons: denialReasons,
          additionalComment,
        },
      });
      closeTicketDetails();
    } catch (error) {
      console.error("Error denying ticket:", error);
    }
  };

  function formatCreatedAt(createdAt) {
    const timestamp = createdAt || { _seconds: 0, _nanoseconds: 0 };
    const date = new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6
    );
    return date.toLocaleString();
  }

  return (
    <div className="w-full overflow-scroll scrollbar-hide">
      <div className="flex justify-between items-center space-x-2">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="text-[#b00041] flex items-center justify-between px-4 space-x-2">
        <div className="flex flex-row space-x-10">
          <NavLink
            className="flex items-center gap-x-4 hover:text-red-800"
            to="/tickets"
          >
            <ForwardIcon fontSize="large" className="rotate-180" />
          </NavLink>
          <h1 className="text-4xl font-black">All Tickets</h1>
        </div>
      </div>
      <div className="card w-[70rem] max-h-[10000rem] justify-between">
        <div>
          <h1>All Tickets</h1>
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs dark:bg-[#71717a] dark:text-white text-gray-700 bg-gray-100 border uppercase">
                <tr>
                  <th scope="col" className="p-3">
                    Ticket ID
                  </th>
                  <th scope="col" className="p-3">
                    Template ID
                  </th>
                  <th scope="col" className="p-3">
                    Created At
                  </th>
                  <th scope="col" className="p-3">
                    Created By
                  </th>
                  <th scope="col" className="py-3">
                    Status
                  </th>
                  <th scope="col" className="p-3">
                    Denial Reason
                  </th>
                  <th scope="col" className="p-3">
                   Additional Info
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedTickets.map((ticket, index) => (
                  <tr
                    key={index}
                    className="border-b"
                    onClick={() => openTicketDetails(ticket)}
                  >
                    <th
                      scope="row"
                      className="p-3 font-medium text-ruby-red dark:text-white whitespace-nowrap"
                    >
                      <button>{ticket.id}</button>
                    </th>
                    <td className="p-3">{ticket.templateName}</td>
                    <td className="p-3">{formatCreatedAt(ticket.createdAt)}</td>
                    <td className="p-3">{ticket.createdBy.name}</td>
                    <td className="p-3">
                      <div className="rounded-full text-center py-1">
                        {statusStyle(ticket.status)}
                      </div>
                    </td>
                    <td className="p-3">
                      {ticket.status === "Denied"
                        ? ticket.denialReasons
                            .filter((reason) => reason.status)
                            .map((reason) => reason.value)
                            .join(", ")
                        : ""}
                    </td>
                    <td className="p-3">{ticket.additionalComment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center mt-4">
            <Pagination
              count={Math.ceil(filteredTickets.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
            />
          </div>
        </div>
        <div className="flex gap-4 text-md text-gray-600 self-center mt-3">
          {["Approved", "Denied", "Pending"].map((tab, index) => (
            <div
              key={index}
              className={`flex p-1 gap-x-2 cursor-pointer ${
                activeTab === tab
                  ? "text-rose-700 font-semibold border-b-2 border-rose-700"
                  : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              <p>{tab}</p>
              <p
                className={`bg-gray-200 px-2 rounded-xl w-7 text-center ${
                  activeTab === tab
                    ? " font-semibold bg-rose-700 bg-opacity-30"
                    : ""
                }`}
              >
                {filterTicketsByStatus(tab).length}
              </p>
            </div>
          ))}
        </div>
      </div>
      {selectedTicket && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ">
          <div className="bg-white pl-10 pt-5 rounded-lg w-[55rem] h-[40rem] pb-10 overflow-scroll scrollbar-hide">
            <div className="text-black">
              <h1 className="text-2xl font-bold mb-4">
                Submission Information
              </h1>
              <div className="grid grid-cols-1 gap-4">
                <div className="flex flex-row">
                  <p className="w-[50%] font-semibold">Ticket ID:</p>
                  <p>{selectedTicket.id}</p>
                </div>
                <div className="flex flex-row">
                  <p className="w-[50%] font-semibold">Category ID:</p>
                  <p>{selectedTicket.categoryId}</p>
                </div>
                <div className="flex flex-row">
                  <p className="w-[50%] font-semibold">Template ID:</p>
                  <p>{selectedTicket.templateId}</p>
                </div>
                <div className="flex flex-row">
                  <p className="w-[50%] font-semibold">Created By:</p>
                  <p>{selectedTicket.createdBy.name}</p>
                </div>
                <div className="flex flex-row">
                  <label className="w-[50%] font-semibold">Status:</label>
                  <select
                    value={updateStatus}
                    onChange={(e) => setUpdateStatus(e.target.value)}
                  >
                    <option value="Select Status">Select Status</option>
                    <option value="Approved">Approved</option>
                    <option value="Denied">Denied</option>
                    <option value="Pending">Pending</option>
                  </select>
                </div>
                {updateStatus === "Denied" && (
                  <div className="flex flex-row">
                    <label className="w-[50%] font-semibold">
                      Denial Reason:
                    </label>
                    <Autocomplete
                      multiple
                      options={denialReasons.map((reason) => reason.value)}
                      getOptionLabel={(option) => option}
                      value={denialReasons
                        .filter((reason) => reason.status)
                        .map((reason) => reason.value)}
                      onChange={(event, newValue) => {
                        const updatedDenialReasons = denialReasons.map(
                          (reason) => ({
                            ...reason,
                            status: newValue.includes(reason.value),
                          })
                        );
                        setDenialReasons(updatedDenialReasons);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select denial reasons"
                        />
                      )}
                    />
                  </div>
                )}
                <div>
                  <label className="w-[50%] font-semibold">
                    Additional Comment:
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={additionalComment}
                    onChange={(e) => setAdditionalComment(e.target.value)}
                    required
                    placeholder="Enter Additional Info"
                    className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-[90%] p-2.5 mb-4"
                  />
                </div>
                <div className="flex flex-col w-[80%]">
                  <h2 className="font-semibold mb-2">Answers:</h2>
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                      <thead>
                        <tr>
                          <th className="px-4 py-2 border">Question</th>
                          <th className="px-4 py-2 border">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedTicket.answers.map((answer, index) => (
                          <tr key={index} className="border-t">
                            <td className="px-4 py-2 border">
                              {answer.questionId}
                            </td>
                            <td className="px-4 py-2 border">
                              {answer.value.join(",")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center pr-20 space-x-10">
              {updateStatus === "Approved" ? (
                <button
                  className="mt-4 bg-rose-700 text-white px-4 py-2 rounded"
                  onClick={approveSelectedTicket}
                >
                  Approve Ticket
                </button>
              ) : updateStatus === "Denied" ? (
                <button
                  className="mt-4 bg-rose-700 text-white px-4 py-2 rounded"
                  onClick={denySelectedTicket}
                >
                  Deny Ticket
                </button>
              ) : (
                ""
              )}
              <button
                className="mt-4 ml-4 text-black bg-white border-2 px-4 py-2 rounded"
                onClick={closeTicketDetails}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TicketList;

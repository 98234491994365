// mutations.ts
import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      name
      email
    }
  }
`;

export const CHECK_USERNAME_AVAILABILITY = gql`
  query CheckUsernameAvailability($username: String!) {
    checkUsernameAvailability(username: $username) {
      isAvailable
    }
  }
`;

export const DELETE_USER_BY_ID = gql`
  mutation DeleteUserById($userId: ID!) {
    deleteUserById(userId: $userId) {
      success
    }
  }
`;

export const UPDATE_USER_BY_ID = gql`
  mutation UpdateUserById($userId: ID!, $updatedUserData: UpdateUserInput!) {
    updateUserById(userId: $userId, updatedUserData: $updatedUserData) {
      success
    }
  }
`;



import React from 'react';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

const Info = ({ title }) => {
  return (

      <Tooltip title={title} >
        <IconButton>
          <InfoIcon style={{width: "17px", height: '17px', color:'#9ca3af', marginBottom:'1px'}}/>
        </IconButton>
      </Tooltip>
 
  );
}

export default Info;

import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
} from "../../GraphQL/Tickets/Mutations";
import {
  Autocomplete,
  TextField,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";
import ToastAlert, { showToast } from "../ToastAlert";
import TemplateForm from "./TemplateForm"; // Assuming you have this component
// import SearchBar from "../SearchBar";
// import Navbar from "../Navbar";
import {
  Forward as ForwardIcon,
  Add as AddIcon,
  DeleteForeverOutlined,
} from "@mui/icons-material";
import { GET_ALL_USERS } from "../../GraphQL/Users/Queries";
import { GET_ALL_GROUPS, GET_SUB_GROUPS } from "../../GraphQL/Groups/Queries";

const CreateTemplate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { template, iscreate } = location.state || {};

  const { data: usersData, loading: userLoading } = useQuery(GET_ALL_USERS, {
    variables: { tenantId: localStorage.getItem("tenantId") },
  });
  const { data: groupsData, loading: groupLoading } = useQuery(GET_ALL_GROUPS, {
    variables: { tenantId: localStorage.getItem("tenantId") },
  });

  const [createTemplate] = useMutation(CREATE_TEMPLATE);
  const [updateTemplate] = useMutation(UPDATE_TEMPLATE);
  const client = useApolloClient();

  const initialFormData = {
    title: iscreate ? "" : template?.title || "",
    description: iscreate ? "" : template?.description || "",
    estimatedResolutionDays: iscreate
      ? 0
      : template?.estimatedResolutionDays || 0,
    availableFrom: iscreate ? "" : template?.availableFrom || "",
    availableTo: iscreate ? "" : template?.availableTo || "",
    availabilityDays: iscreate ? [] : template?.availabilityDays || [],
    visibilityType: iscreate ? "" : template?.visibilityType || "",
    visibility: iscreate ? [] : template?.visibility || [],
    assigneeIds: iscreate ? [] : template?.assigneeIds || [],
    dynamicFields: iscreate ? [] : template?.dynamicFields || [],
    connectedFields: iscreate ? [] : template?.connectedFields || [],
    questions: iscreate ? [] : template?.questions || [],
    categoryId: iscreate
      ? location.state.catdata.id
      : template?.categoryId || "",
    denialReasons: iscreate ? [] : template?.denialReasons || [], // New field added
    tenantId: localStorage.getItem("tenantId"),
    createdBy: {
      id: localStorage.getItem("userid"),
      name: localStorage.getItem("name"),
      email: localStorage.getItem("user"),
    },
  };

  const [formData, setFormData] = useState(initialFormData);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [validationError, setValidationError] = useState("");
  useEffect(() => {
    if (template) {
      setFormData(initialFormData);
    }
  }, [template]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "availableFrom" || name === "availableTo") {
      setFormData((prevData) => {
        const newFormData = { ...prevData, [name]: value };

        if (newFormData.availableFrom && newFormData.availableTo) {
          if (newFormData.availableFrom >= newFormData.availableTo) {
            setValidationError(
              "Available To time must be after Available From time"
            );
          } else {
            setValidationError("");
          }
        }

        return newFormData;
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: name === "estimatedResolutionDays" ? Number(value) : value,
      }));
    }
  };

  const handleGroupChange = (event, newValue) => {
    setSelectedGroups(newValue);
  };

  const handleVisibilityTypeChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      visibilityType: e.target.value,
    }));
  };

  const processVisibility = async () => {
    let visibility = [];

    if (formData.visibilityType === "includeAllUsers") {
      visibility = usersData.getAllUsers.map((user) => user.id);
    } else {
      const selectedGroupIds = selectedGroups.map((group) => group.id);
      const groupDataMap = {};

      groupsData.getAllGroups.forEach((group) => {
        groupDataMap[group.id] = group;
      });

      if (formData.visibilityType === "includeAllChildren") {
        const getAllSubGroupUsers = async (groupIds) => {
          let users = [];
          for (const groupId of groupIds) {
            const { data: subGroupsData } = await client.query({
              query: GET_SUB_GROUPS,
              variables: {
                tenantId: localStorage.getItem("tenantId"),
                groupId,
              },
            });
            const subGroupIds = subGroupsData.querySubGroups.map(
              (subGroup) => subGroup.id
            );
            users = users.concat(
              subGroupsData.querySubGroups.reduce(
                (acc, subGroup) =>
                  acc.concat(subGroup.leaderId, ...subGroup.members),
                []
              )
            );
            users = users.concat(await getAllSubGroupUsers(subGroupIds));
          }
          return [...new Set(users)];
        };

        visibility = visibility.concat(
          await getAllSubGroupUsers(selectedGroupIds)
        );
      }

      if (formData.visibilityType === "includeAllParents") {
        selectedGroupIds.forEach((groupId) => {
          visibility = visibility.concat(groupDataMap[groupId].visibility);
        });
      }

      if (formData.visibilityType === "onlyLeaders") {
        visibility = visibility.concat(
          selectedGroups.map((group) => group.leaderId)
        );
      }

      if (!formData.visibilityType) {
        selectedGroupIds.forEach((groupId) => {
          visibility = visibility.concat(
            groupDataMap[groupId].leaderId,
            ...groupDataMap[groupId].members
          );
        });
      }

      visibility = [...new Set(visibility)];
    }

    return visibility;
  };

  if (userLoading || groupLoading) {
    return <p>Loading...</p>;
  }

  const handleAddDenialReason = () => {
    setFormData((prevData) => ({
      ...prevData,
      denialReasons: [...prevData.denialReasons, ""],
    }));
  };

  const handleRemoveDenialReason = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      denialReasons: prevData.denialReasons.filter((_, i) => i !== index),
    }));
  };

  const handleDenialReasonChange = (index, value) => {
    setFormData((prevData) => ({
      ...prevData,
      denialReasons: prevData.denialReasons.map((reason, i) =>
        i === index ? value : reason
      ),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const visibility = await processVisibility();
      const input = { ...formData, visibility };
      if (iscreate) {
        await createTemplate({ variables: { input } });
        showToast("Template created successfully!", "success");
      } else {
        await updateTemplate({
          variables: {
            categoryId: template.categoryId,
            id: template.id,
            input,
          },
        });
        showToast("Template updated successfully!", "success");
      }
      navigate("/tickets");
      window.location.reload();
    } catch (err) {
      console.error("Error saving template:", err);
      showToast("Error saving template: " + err.message, "error");
    }
  };

  return (
    <main className="flex flex-col">
      <ToastAlert />
      <div className="flex justify-between items-center space-x-2">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row space-x-2 mb-10">
          <NavLink
            className="flex items-center gap-x-4 hover:text-red-800"
            to="/tickets"
          >
            <ForwardIcon fontSize="large" className="rotate-180" />
          </NavLink>
          <div className="text-[#b00041] flex px-4 space-x-2">
            <h1 className="text-4xl font-black">
              {iscreate ? "Create Template" : "Update Template"}
            </h1>
          </div>
        </div>
        <div className="flex justify-around">
          <form onSubmit={handleSubmit}>
            <div className="card w-[768px] max-h-[10000px] p-5 rounded-[25px] shadow">
              <div className="text-black mb-3">
                <label className="mb-1">Template Title</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                  placeholder="Enter the template title"
                  className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 mb-4"
                />
              </div>
              <div className="text-black mb-3">
                <label className="mb-1">Description</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Enter the template description"
                  className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 mb-4"
                />
              </div>
              <div className="text-black mb-3">
                <label className="mb-1">Estimated Resolution Days</label>
                <input
                  type="number"
                  name="estimatedResolutionDays"
                  value={formData.estimatedResolutionDays}
                  onChange={handleChange}
                  placeholder="Enter the estimated resolution days"
                  className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 mb-4"
                />
              </div>
              <div>
                {validationError && (
                  <FormHelperText error>{validationError}</FormHelperText>
                )}
                <div className="flex flex-row justify-between">
                  <div className="text-black mb-3">
                    <label className="mb-1">Available From</label>
                    <input
                      type="time"
                      name="availableFrom"
                      value={formData.availableFrom}
                      onChange={handleChange}
                      className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 mb-4"
                    />
                  </div>
                  <div className="text-black mb-3">
                    <label className="mb-1">Available To</label>
                    <input
                      type="time"
                      name="availableTo"
                      value={formData.availableTo}
                      onChange={handleChange}
                      className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 mb-4"
                    />
                  </div>
                </div>
              </div>

              <div className="text-black mb-3">
                <label className="mb-1">Availability Days</label>
                <Autocomplete
                  multiple
                  options={[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ]}
                  getOptionLabel={(option) => option}
                  value={formData.availabilityDays}
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      availabilityDays: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select availability days"
                      className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 mb-4"
                    />
                  )}
                />
              </div>
              <div className="text-black mb-3">
                <label className="mb-1">Assignors</label>
                <Autocomplete
                  multiple
                  options={usersData.getAllUsers}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      assigneeIds: newValue.map((user) => user.id),
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Assignors"
                      className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 mb-4"
                    />
                  )}
                />
              </div>
              <div className="text-black mb-3">
                <label className="mb-1">Dynamic Fields</label>
                <Autocomplete
                  multiple
                  options={["Field1", "Field2", "Field3"]}
                  getOptionLabel={(option) => option}
                  value={formData.dynamicFields}
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      dynamicFields: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 mb-4"
                    />
                  )}
                />
              </div>
              <div className="text-black mb-3">
                <label className="mb-1">Connected Fields</label>
                <Autocomplete
                  multiple
                  options={["Field1", "Field2", "Field3"]}
                  getOptionLabel={(option) => option}
                  value={formData.connectedFields}
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      connectedFields: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 mb-4"
                    />
                  )}
                />
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-black mb-3">
                  <label className="mb-1">Visibility</label>
                  <Autocomplete
                    multiple
                    options={groupsData.getAllGroups}
                    className="w-[300px]"
                    getOptionLabel={(option) => option.name}
                    onChange={handleGroupChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select groups"
                      />
                    )}
                  />
                </div>
                <div className="text-black mb-3 space-y-2 flex flex-col">
                  <label className="mb-1">Visibility Options</label>
                  <FormControl className="w-[300px]">
                    <Select
                      value={formData.visibilityType}
                      onChange={handleVisibilityTypeChange}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="includeAllUsers">
                        Include all users
                      </MenuItem>
                      <MenuItem value="includeAllChildren">
                        Include all children
                      </MenuItem>
                      <MenuItem value="includeAllParents">
                        Include all parents
                      </MenuItem>
                      <MenuItem value="onlyLeaders">Only leaders</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <TemplateForm
                allquestions={formData.questions}
                onUpdateQuestions={(newQuestions) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    questions: newQuestions,
                  }))
                }
              />
              <div className="text-black mb-3 mt-3">
                <label className="mb-1 mr-3">Denial Reasons</label>
                {formData.denialReasons.map((reason, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      value={reason}
                      onChange={(e) =>
                        handleDenialReasonChange(index, e.target.value)
                      }
                      variant="outlined"
                      placeholder="Enter denial reason"
                      className="bg-gray-50 border dark:bg-darkcard border-gray-300 text-gray-900 rounded-lg block w-[80%] p-1 mb-1"
                    />
                    <button
                      onClick={() => handleRemoveDenialReason(index)}
                      className="text-ruby-red pb-2 ml-2"
                    >
                      <DeleteForeverOutlined />
                    </button>
                  </div>
                ))}

                <button
                  type="button"
                  className="bg-rose-700 text-sm text-white max-w-40   mb-3 p-1 rounded"
                  onClick={handleAddDenialReason}
                >
                  Add Reason
                </button>
              </div>
              <div className="mt-5 flex flex-row justify-end space-x-3">
                <button
                  type="submit"
                  className="w-full h-[38px] px-[30px] py-[9px] bg-rose-900 rounded-md text-white font-semibold"
                  disabled={userLoading || groupLoading}
                >
                  {iscreate ? "Create" : "Update"} Template
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default CreateTemplate;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APOLLO_CLIENT_API,
    authDomain: process.env.REACT_APP_APOLLO_CLIENT_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_APOLLO_CLIENT_DATABASEURL,
    projectId: process.env.REACT_APP_APOLLO_CLIENT_PROJECTID,
    storageBucket: process.env.REACT_APP_APOLLO_CLIENT_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_APOLLO_CLIENT_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APOLLO_CLIENT_APPID,
    measurementId: process.env.REACT_APP_APOLLO_CLIENT_MEASUREID
  };
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
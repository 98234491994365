import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import SearchIcon from "@mui/icons-material/Search";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import logo from "../../assets/ruby_logo.svg";
import PersonIcon from "@mui/icons-material/Person";
const GET_ALL_CHATS = gql`
  query {
    getAllChats {
      id
      name
      chatsfrom {
        from
        time
        messages {
          message
          type
        }
      }
    }
  }
`;

const SEND_MESSAGE_MUTATION = gql`
  mutation SendMessage(
    $chatId: ID!
    $from: String!
    $message: String!
    $type: String!
  ) {
    sendMessage(chatId: $chatId, from: $from, message: $message, type: $type)
  }
`;

function ChatListData() {
  const { loading, error, data, refetch } = useQuery(GET_ALL_CHATS);
  const [selectedChat, setSelectedChat] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [chatid, setchatid] = useState("");
  const [sendMessageMutation] = useMutation(SEND_MESSAGE_MUTATION);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const chats = data.getAllChats;

  const handleChatSelection = (chatids, chatFrom) => {
    setSelectedChat(chatFrom);
    setchatid(chatids);
  };

  const formatTimeAgo = (timestamp) => {
    const currentTime = new Date();
    const messageTime = new Date(timestamp);
    const timeDiff = Math.floor((currentTime - messageTime) / 60000);

    if (timeDiff < 1) {
      return "Just now";
    } else if (timeDiff < 60) {
      return `${timeDiff} minute${timeDiff === 1 ? "" : "s"} ago`;
    } else if (timeDiff < 1440) {
      return `${Math.floor(timeDiff / 60)} hour${
        Math.floor(timeDiff / 60) === 1 ? "" : "s"
      } ago`;
    } else {
      return messageTime.toISOString().slice(0, 10);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    try {
      await sendMessageMutation({
        variables: {
          chatId: selectedChat ? chatid : "",
          from: selectedChat ? selectedChat.from : "",
          message: newMessage,
          type: "sender",
        },
      });
      await refetch();
    } catch (error) {
      console.error("Error sending the message:", error);
    }
    setNewMessage("");
  };

  return (
    <div className="flex">
      <div className="border-r border-gray-300">
        <div className="border-b-2 pb-1 w-full space-x-3">
          <SearchIcon className="text-[#b00041]" fontSize="medium" />
          <input
            type="search"
            name="search inqueries"
            placeholder="Search Inquiries"
            className="bg-zinc-100 dark:bg-darkcard border-2 h-9 px-4 rounded-full text-sm focus:outline-none"
          />
          <button>
            <EditNoteIcon className="text-[#b00041]" fontSize="medium" />
          </button>
        </div>
        {chats.map((chat) => (
          <div className=" flex flex-col justify-between h-[644px]">
            <div
              key={chat.id}
              className="overflow-y-auto scrollbar-hide max-h-[560px] mt-2"
            >
              {chat.chatsfrom.map((chatFrom) => (
                <div key={chatFrom.from}>
                  <button
                    onClick={() => handleChatSelection(chat.id, chatFrom)}
                    className={` ${
                      selectedChat === chatFrom
                        ? "bg-rose-700  dark:bg-darkdiv  bg-opacity-25 text-white"
                        : " dark:bg-darkcard"
                    } rounded-md focus:outline-none`}
                  >
                    <div className="flex flex-row w-[300px] h-20  text-black border-r-2 dark:border-darkdiv hover:bg-rose-700 hover:bg-opacity-25 border-b-2 rounded-[10px] justify-center items-center overflow-y-auto">
                      <div className="w-1/6">
                        <img src={logo} alt="" />
                      </div>
                      <div className="w-[210px]  font-normal dark:text-white font-montserrat">
                        {chatFrom.from}
                        <br />
                        desc Text · {formatTimeAgo(chatFrom.time)}
                      </div>
                    </div>
                  </button>
                </div>
              ))}
            </div>
            <div className="flex justify-end  text-[#b00041] space-x-2  text-m mt-2 pb-3">
              <button>{chat.name} </button>
              <PersonIcon />
            </div>
          </div>
        ))}
      </div>
      <div className="w-[0px]">
        {selectedChat && (
          <div className="w-[760px]">
            <div className="flex flex-row border-b-2 space-x-3">
              <div>
                <img className="h-8 pl-2 mt-1" alt="" src={logo} />
              </div>
              <div className="w-[264px] h-4 mt-2 dark:text-textprimary text-base font-semibold font-['Montserrat']">
                {selectedChat.from}
              </div>
              <div className="flex items-center text-textprimary text-bold pl-[380px]">
                <h1 className="text-xs mt-1">Delete</h1>
                <DeleteOutlineIcon />
              </div>
            </div>
            <div className="w-full px-2 mt-3 overflow-y-auto scrollbar-hide h-[557px]">
              {selectedChat.messages.map((message, index) => (
                <div
                  key={message.id}
                  className={`flex ${
                    message.type === "sender" ? "justify-end" : "justify-start"
                  } space-x-2 mb-4`}
                >
                  {message.type !== "sender" && (
                    <img
                      src={logo}
                      className="object-cover h-8 w-8 rounded-full"
                      alt=""
                    />
                  )}
                  <div
                    className={`max-w-[340px] max-h-[75px] ${
                      message.type === "sender"
                        ? "bg-gray-200 dark:bg-darkcard dark:border-[1px]"
                        : "bg-rose-700 dark:bg-darkdiv bg-opacity-25"
                    } rounded-[10px] p-2 dark:text-white text-base font-normal font-['Montserrat'] leading-tight`}
                  >
                    {message.message}
                  </div>
                  {message.type === "sender" && (
                    <img
                      src={logo}
                      className="object-cover h-8 w-8 rounded-full"
                      alt=""
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="mt-4 ml-3 space-x-1 text-[#b00041]">
              <AddReactionIcon />
              <AttachFileIcon />
              <input
                type="search"
                name="search"
                placeholder="Search"
                className="bg-zinc-100 dark:bg-darkcard border-2  h-9 w-[40rem] pl-4 rounded-full text-sm focus:outline-none"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <SendIcon onClick={handleSendMessage} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatListData;

import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { NavLink, useNavigate } from 'react-router-dom';
import { GET_USER_WORKSPACES } from '../GraphQL/NewMethods/Queries';
import { Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WorkspaceModal from './WorkspaceModal';

const WorkspaceSwitcher = () => {
    const userId = localStorage.getItem('userid'); 
    const { loading, error, data } = useQuery(GET_USER_WORKSPACES, {
      variables: { userId } 
    });
    const [workspaces, setWorkspaces] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isWorkspaceModalOpen, setIsWorkspaceModalOpen] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
      if (data) {
        setWorkspaces(data.getUserWorkspaces);
      }
    }, [data]);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleOpenWorkspaceModal = () => {
      setIsWorkspaceModalOpen(true);
      handleClose();
    };
  
    const handleCloseWorkspaceModal = () => {
      setIsWorkspaceModalOpen(false);
    };
  
    const handleNavigateToManageWorkspaces = () => {
        if (workspaces.length > 0) {
          navigate(`/workspace/${workspaces[0].id}`);
        } else {
          navigate('/workspace');
        }
        handleClose();
      };
  

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="workspace-switcher">
            <NavLink to="#" className="styled-button" 
                onClick={handleClick}>
            <ArrowDropDownIcon className="styled-icon" />
                Hephium
            </NavLink>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
            <MenuItem onClick={handleNavigateToManageWorkspaces}>
                Manage Workspaces
            </MenuItem>
            <MenuItem onClick={handleOpenWorkspaceModal}>
                + New Workspace
            </MenuItem>
            {workspaces.map(workspace => (
                <MenuItem key={workspace.id} onClick={handleClose}>
                    <NavLink to={`/workspace/${workspace.id}`} className="styled-navlink">
                        {workspace.name}
                    </NavLink>
                </MenuItem>
                ))}
            </Menu>
            <WorkspaceModal open={isWorkspaceModalOpen} handleClose={handleCloseWorkspaceModal}/>
     </div>
    );
};

export default WorkspaceSwitcher;

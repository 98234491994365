import React, { useState,useEffect } from 'react';

const FishboneDiagramCreator = () => {
  const [jsonData, setJsonData] = useState({ titles: [] });
  const [branches, setBranches] = useState([{ title: '', subtitles: '' }]);
  const [jsonOutput, setJsonOutput] = useState('');
  const [jsonInputValue, setJsonInputValue] = useState('');

  useEffect(() => {
    // Update diagram or perform any side effect when jsonData changes
    // createFishboneDiagram(jsonData);
    setJsonOutput(JSON.stringify(jsonData, null, 2));
  }, [jsonData]);
  const handleJsonInputChange = (event) => {
    setJsonInputValue(event.target.value);
    try {
      const parsedJson = JSON.parse(event.target.value);
      setJsonData(parsedJson);
    } catch (error) {
      // Handle invalid JSON input
    }
  };
  const addBranch = () => {
    setBranches(prevBranches => [...prevBranches, { title: '', subtitles: '' }]);
  };

  const removeBranch = (indexToRemove) => {
    setBranches(prevBranches => prevBranches.filter((_, index) => index !== indexToRemove));
  };

  const convertToJson = () => {
    const newJsonData = {
      titles: branches.map(branch => ({
        title: branch.title,
        subtitles: branch.subtitles.split(',').map(s => s.trim()),
      })),
    };
    setJsonData(newJsonData);
    setJsonOutput(JSON.stringify(newJsonData, null, 2));
  };
  const createSvgElement = (type, attributes) => {
    const elem = document.createElementNS("http://www.w3.org/2000/svg", type);
    Object.entries(attributes).forEach(([key, value]) => elem.setAttribute(key, value));
    return elem;
  };

  const createLine = (x1, y1, x2, y2) => createSvgElement('line', { x1, y1, x2, y2, stroke: 'black' });

  const createTextElement = (text, x, y, fontWeight) => {
    const fontSize = fontWeight === 'bold' ? 'larger' : 'normal';
    const textElem = createSvgElement('text', { x, y, 'font-weight': fontWeight, 'font-size': fontSize });
    textElem.textContent = text;
    return textElem;
  };

  const calculateBranchEnd = (startX, startY, angle, length) => ({
    x: startX + Math.cos(angle) * length,
    y: startY + Math.sin(angle) * length
  });

  const createBranchesAndText = (data, diagram, spineY, spineLength) => {
    const angleOffset = Math.PI / 3; // 60 degrees in radians
    const pairSpacing = 300; // Space between branch pairs

    data.titles.forEach((title, index) => {
        const pairIndex = Math.floor(index / 2);
        const branchStartX = pairIndex * pairSpacing;
        const angle = index % 2 === 0 ? angleOffset : -angleOffset;
        const branchLength = 100 + (title.subtitles.length * 30); 
        const { x: branchEndX, y: branchEndY } = calculateBranchEnd(branchStartX, spineY, angle, branchLength);

        const branch = createLine(branchStartX, spineY, branchEndX, branchEndY);
        diagram.appendChild(branch);

        const titleText = createTextElement(title.title, branchEndX, branchEndY, 'bold');
        diagram.appendChild(titleText);

        // Evenly distribute subtitles along the branch and shift them to the right
        title.subtitles.forEach((subtitle, subIndex) => {
            const subtitleSpacing = branchLength / (title.subtitles.length + 1);
            const subtitlePosition = subtitleSpacing * (subIndex + 1);

            const { x: subtitleX, y: subtitleY } = calculateBranchEnd(
                branchStartX, spineY, angle, subtitlePosition
            );
            const adjustedSubtitleX = subtitleX + 8; // Shift right by 8 pixels
            const subtitleText = createTextElement(subtitle, adjustedSubtitleX, subtitleY, 'normal');
            diagram.appendChild(subtitleText);
        });
    });
};
const createFishboneDiagram = (data) => {
  const diagramArea = document.getElementById('diagramArea');
  const diagram = createSvgElement('svg', {});
  diagramArea.innerHTML = '';
  diagramArea.appendChild(diagram);

  const spineLength = calculateSpineLength(data);
  const svgWidth = spineLength;
  const svgHeight = (100 + getMaxSubtitles(data) * 30) * 2;

  diagram.setAttribute('width', svgWidth);
  diagram.setAttribute('height', svgHeight);

  const spineY = svgHeight / 2;
  const spine = createLine(0, spineY, spineLength, spineY);
  diagram.appendChild(spine);

  createBranchesAndText(data, diagram, spineY, spineLength);
};

  const getMaxSubtitles = (data) => Math.max(...data.titles.map(title => title.subtitles.length));
  const calculateSpineLength = (data) => Math.ceil(data.titles.length / 2) * 300;

  const handleLoadJson = () => {
    const jsonDataInput = document.getElementById('jsonInput');
    if (jsonDataInput.value.trim() === '') {
      alert('Please enter JSON data. Sample JSON:\n\n' +
        '{\n' +
        '  "titles": [\n' +
        '    { "title": "Branch 1", "subtitles": ["Sub 1.1", "Sub 1.2"] },\n' +
        '    { "title": "Branch 2", "subtitles": ["Sub 2.1", "Sub 2.2"] },\n' +
        '    { "title": "Branch 3", "subtitles": ["Sub 3.1", "Sub 3.2"] }\n' +
        '  ]\n' +
        '}');
      return;
    }

    try {
      const jsonData = JSON.parse(jsonDataInput.value);
      createFishboneDiagram(jsonData);
    } catch (e) {
      alert('Invalid JSON: ' + e.message);
    }
  }; 
  function exportSvgAsImage() {
    const svgElement = document.getElementById('diagramArea').firstChild;
    const serializer = new XMLSerializer();
    const source = serializer.serializeToString(svgElement);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    const scale = 2; // Increase this factor for higher resolution

    img.onload = () => {
        canvas.width = img.width * scale; // Increase canvas width
        canvas.height = img.height * scale; // Increase canvas height
        ctx.scale(scale, scale); // Scale the context to match the increased size

        ctx.fillStyle = 'white'; // Set the background to white
        ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the canvas with white background

        ctx.drawImage(img, 0, 0); // Draw the image onto the canvas
        const imgSrc = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgSrc;
        link.download = 'fishbone-diagram.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);

    // Save the JSON data as a .txt file
    const jsonData = document.getElementById('jsonInput').value;
    const jsonBlob = new Blob([jsonData], { type: 'text/plain' });
    const jsonLink = document.createElement('a');
    jsonLink.href = URL.createObjectURL(jsonBlob);
    jsonLink.download = 'diagram-data.txt';
    jsonLink.click();
}


  



  return (
    <div className="min-h-screen flex flex-col">
      <header className="bg-black text-white py-4 text-center">
        <h1>Fishbone Diagram Creator</h1>
      </header>
      <div
        id="diagramArea"
        className="flex-grow p-4 bg-white border rounded-md shadow-md mx-4 my-4 flex justify-center items-center"
      >
        {/* Render your SVG diagram here */}
        {/* <svg id="fishboneDiagram"></svg> */}
      </div>
      <div id="jsonArea" className="bg-gray-300 p-4 rounded-md mx-4 my-4">
        <div>
          <h2 className="text-2xl font-bold mb-4">List to JSON Converter</h2>
          <div id="branches">
            {branches.map((branch, index) => (
              <div key={index} className="flex flex-col mb-4">
                <label className="mb-1">Title:</label>
                <input
                  type="text"
                  className="border p-2"
                  value={branch.title}
                  onChange={(e) => {
                    const newBranches = [...branches];
                    newBranches[index].title = e.target.value;
                    setBranches(newBranches);
                  }}
                />
                <label className="mb-1 mt-2">Subtitles (comma-separated):</label>
                <input
                  type="text"
                  className="border p-2"
                  value={branch.subtitles}
                  onChange={(e) => {
                    const newBranches = [...branches];
                    newBranches[index].subtitles = e.target.value;
                    setBranches(newBranches);
                  }}
                />
                <span
                  className="text-red-500 cursor-pointer mt-2"
                  onClick={() => removeBranch(index)}
                >
                  Remove
                </span>
              </div>
            ))}
          </div>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2 mt-2"
            onClick={addBranch}
          >
            Add Another Branch
          </button>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-md mt-2"
            onClick={convertToJson}
          >
            Convert to JSON
          </button>
          <h3 className="text-xl font-bold mt-4">JSON Output:</h3>
          <pre id="jsonOutput" className="bg-gray-200 p-2 border">
            {jsonOutput}
          </pre>
        </div>
        <h2 className="text-2xl font-bold mt-4">Diagram Data</h2>
        <textarea
          id="jsonInput"
          className="border p-2 w-full h-32"
          value={jsonInputValue}
        onChange={handleJsonInputChange}
        
        ></textarea>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
          onClick={handleLoadJson}
        >
          Load JSON
        </button>
        <button
          className="bg-yellow-500 text-white px-4 py-2 rounded-md"
          onClick={exportSvgAsImage}
        >
          Export as Image
        </button>
      </div>
    </div>
  );
};

export default FishboneDiagramCreator;

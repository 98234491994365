import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_USERS} from '../GraphQL/NewUsers/Queries'; 
import { CREATE_WORKSPACE } from "../GraphQL/NewMethods/Mutation";// Import your GraphQL queries and mutations
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const WorkspaceModal = ({ open, handleClose }) => {
  const [step, setStep] = useState(1);
  const [workspaceName, setWorkspaceName] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { loading, error, data } = useQuery(GET_ALL_USERS);
  const [createWorkspace] = useMutation(CREATE_WORKSPACE);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleCreateWorkspace = async () => {
    try {
      await createWorkspace({
        variables: {
          input: {
            name: workspaceName,
            leader: {
              id: localStorage.getItem('userid'),
              name: localStorage.getItem('name'),
              email: localStorage.getItem('email'),
            },
            members: selectedMembers.map(member => ({
              id: member.id,
              name: member.name,
              email: member.email,
            })),
          },
        },
      });
      handleClose();
    } catch (error) {
      console.error('Error creating workspace:', error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === 1 && (
          <>
            <Typography variant="h6" component="h2">
              Enter Workspace Name
            </Typography>
            <TextField
              fullWidth
              label="Workspace Name"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              margin="normal"
            />
            <Button onClick={handleNext} variant="contained" color="primary">
              Next
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <Typography variant="h6" component="h2">
              Select Members
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel id="members-label">Members</InputLabel>
              <Select
                labelId="members-label"
                multiple
                value={selectedMembers}
                onChange={(e) => setSelectedMembers(e.target.value)}
                renderValue={(selected) => selected.map(member => member.name).join(', ')}
              >
                {loading ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : (
                  data.getAllUsers.map((user) => (
                    <MenuItem key={user.id} value={user}>
                      {user.name} ({user.email})
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
            <Box display="flex" justifyContent="space-between">
              <Button onClick={handleBack} variant="contained" color="secondary">
                Back
              </Button>
              <Button onClick={handleCreateWorkspace} variant="contained" color="primary">
                Create Workspace
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default WorkspaceModal;

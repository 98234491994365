import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_WORKSPACE_BY_ID } from "../GraphQL/NewMethods/Queries";
import {
  UPDATE_WORKSPACE,
  DELETE_WORKSPACE,
} from "../GraphQL/NewMethods/Mutation";
import Autocomplete from "@mui/material/Autocomplete";
import { GET_ALL_USERS } from "../GraphQL/NewUsers/Queries";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";
import {

    TextField,
  
  } from '@mui/material';
const WorkspaceSettings = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [newLeader, setNewLeader] = useState(null);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { loading, error, data } = useQuery(GET_WORKSPACE_BY_ID, {
    variables: { id },
  });
  const {
    loading: usersLoading,
    error: usersError,
    data: usersData,
  } = useQuery(GET_ALL_USERS);
  const [deleteWorkspace] = useMutation(DELETE_WORKSPACE);
  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);

  if (loading || usersLoading) return <p>Loading...</p>;
  if (error || usersError)
    return <p>Error: {error ? error.message : usersError.message}</p>;

  const workspace = data.getWorkspaceById;
  const users = usersData.getAllUsers;

  const handleDelete = async () => {
    await deleteWorkspace({ variables: { deleteWorkspaceId: id } });
    navigate("/");
  };

  const handleTransferOwnership = async () => {
    await updateWorkspace({
      variables: {
        updateWorkspaceId: id,
        input: {
          leader: {
            id: newLeader.id,
            name: newLeader.name,
            email: newLeader.email,
          },
        },
      },
    });
    window.location.reload();
  };

  const handleInviteUsers = async () => {
    const newMembers = selectedUsers.map((userId) => {
      const user = users.find((user) => user.id === userId);
      return { id: user.id, name: user.name, email: user.email };
    });

    await updateWorkspace({
      variables: {
        id,
        input: {
          members: [...(workspace.members || []), ...newMembers],
        },
      },
    });
    setInviteModalOpen(false);
    setSelectedUsers([]);
    window.location.reload();
  };

  const handleRemoveMember = async (memberId) => {
    const updatedMembers = workspace.members.filter(
      (member) => member.id !== memberId
    );

    await updateWorkspace({
      variables: {
        updateWorkspaceId: id,
        input: {
          members: updatedMembers,
        },
      },
    });
    window.location.reload();
  };

  // Filter out leader and already existing members from the users list
  const filteredUsers = users.filter(
    (user) =>
      user.id !== workspace.leader.id &&
      !workspace.members.some((member) => member.id === user.id)
  );

  return (
    <div className="w-screen h-screen flex flex-col items-center">
      <div className="flex justify-between items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="card w-[70%] flex flex-col space-y-2">
        <h2>Workspace Settings</h2>
        <p>
          <strong>ID:</strong> {workspace.id}
        </p>
        <p>
          <strong>Name:</strong> {workspace.name}
        </p>
        <p>
          <strong>Leader:</strong> {workspace.leader.name}
        </p>

        <h3 className="mt-3 mb-2">Transfer Ownership</h3>
        <div className="w-full mb-4">
          <label htmlFor="new-leader" className="block mb-2 font-bold">
            New Leader
          </label>
          <select
            id="new-leader"
            className="w-full px-3 py-2 border rounded"
            value={newLeader ? newLeader.id : ""}
            onChange={(e) => {
              const selectedUser = users.find(
                (user) => user.id === e.target.value
              );
              setNewLeader(selectedUser);
            }}
          >
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name} ({user.email})
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={handleTransferOwnership}
          className="w-[300px] h-[38px] px-[30px] py-[9px] hover:bg-opacity-70 bg-ruby-red hover:text-black hover:text-white rounded-md text-white text-sm font-semibold font-montserrat mt-2"
        >
          Transfer Ownership
        </button>

        <h3 className="mt-3 mb-2">Invite People</h3>
        <button
          className="w-[300px] h-[38px] px-[30px] py-[9px] hover:bg-opacity-70 bg-ruby-red hover:text-black hover:text-white rounded-md text-white text-sm font-semibold font-montserrat mt-2"
          onClick={() => setInviteModalOpen(true)}
        >
          Invite People
        </button>
        {inviteModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded shadow-lg w-96">
              <h4 className="text-xl font-bold mb-4">
                Invite Users to Workspace
              </h4>
              <Autocomplete
                multiple
                options={filteredUsers}
                getOptionLabel={(option) => `${option.name} (${option.email})`}
                value={selectedUsers.map((userId) =>
                  users.find((user) => user.id === userId)
                )}
                onChange={(event, newValue) =>
                  setSelectedUsers(newValue.map((user) => user.id))
                }
                renderInput={(params) => (
                  <TextField {...params} label="Users" />
                )}
              />
              <div className="flex flex-row space-x-3">
                <button
                  className="bg-ruby-red  text-white px-4 py-2 rounded mt-4"
                  onClick={handleInviteUsers}
                >
                  Add
                </button>
                <button
                  className="bg-white border-2 border-ruby-red text-ruby-red px-4 py-2 rounded mt-4"
                  onClick={() => setInviteModalOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        <h3 className="mt-3 mb-2">Members</h3>
        <ul className="flex flex-col space-y-2">
          {workspace.members.map((member) => (
            <li key={member.id} className="flex justify-between items-center">
              <span>
                {member.name} ({member.email})
              </span>
              <button
                className="bg-red-700 text-white px-2 py-1 rounded"
                onClick={() => handleRemoveMember(member.id)}
              >
                Remove
              </button>
            </li>
          ))}
        </ul>

        <h3 className="mt-3">Danger Zone</h3>
        <button
          onClick={handleDelete}
          className="w-[300px] h-[38px] px-[30px] py-[9px] hover:bg-opacity-70 bg-red-700 hover:text-black hover:text-white rounded-md text-white text-sm font-semibold font-montserrat mt-2"
        >
          Delete Workspace
        </button>
      </div>
    </div>
  );
};

export default WorkspaceSettings;

import { gql } from '@apollo/client';

export const CREATE_AUDIT = gql`
  mutation CreateAudit($workspaceId: ID!, $input: AuditInput!) {
    createAudit(workspaceId: $workspaceId, input: $input) {
      auditId
      auditTitle
      auditDesc
      questions {
        questionId
        questionName
        questionType
        isRequired
        values
        failvalue
      }
      createdBy {
        id
        name
        email
      }
      admins {
        id
        name
        email
      }
    }
  }
`;

export const CREATE_TICKET_TEMPLATE = gql`
  mutation CreateTicketTemplate($workspaceId: ID!, $input: TicketTemplateInput!) {
    createTicketTemplate(workspaceId: $workspaceId, input: $input) {
      id
      title
      description
      questions {
        questionId
        questionName
        questionType
        isRequired
        values
        failvalue
      }
      createdBy {
        id
        name
        email
      }
      admins {
        id
        name
        email
      }
    }
  }
`;

export const CREATE_TICKET = gql`
  mutation CreateTicket($workspaceId: ID!, $input: TicketInput!) {
    createTicket(workspaceId: $workspaceId, input: $input) {
      id
      templateName
      status
      answers {
        questionId
        value
      }
      createdBy {
        id
        name
        email
      }
      admins {
        id
        name
        email
      }
      assignees
      createdAt
      updatedAt
      additionalComment
      denialReasons {
        value
        status
      }
    }
  }
`;

export const UPDATE_AUDIT = gql`
  mutation UpdateAuditById($workspaceId: ID!, $auditId: ID!, $input: UpdateAuditInput!) {
    updateAuditById(workspaceId: $workspaceId, auditId: $auditId, input: $input) {
      auditId
      auditTitle
      auditDesc
      questions {
        questionId
        questionName
        questionType
        isRequired
        values
        failvalue
      }
      createdBy {
        id
        name
        email
      }
      admins {
        id
        name
        email
      }
    }
  }
`;

export const DELETE_AUDIT_BY_ID = gql`
  mutation DeleteAuditById($workspaceId: ID!, $auditId: ID!) {
    deleteAuditById(workspaceId: $workspaceId, auditId: $auditId)
  }
`;

export const UPDATE_TICKET_TEMPLATE = gql`
  mutation UpdateTicketTemplate($workspaceId: ID!, $id: ID!, $input: UpdateTicketTemplateInput!) {
    updateTicketTemplate(workspaceId: $workspaceId, id: $id, input: $input) {
      id
      title
      description
      questions {
        questionId
        questionName
        questionType
        isRequired
        values
        failvalue
      }
      createdBy {
        id
        name
        email
      }
      admins {
        id
        name
        email
      }
    }
  }
`;

export const DELETE_TICKET_TEMPLATE = gql`
  mutation DeleteTicketTemplate($workspaceId: ID!, $id: ID!) {
    deleteTicketTemplate(workspaceId: $workspaceId, id: $id)
  }
`;

export const APPROVE_TICKET = gql`
  mutation ApproveTicket($workspaceId: ID!, $ticketId: ID!, $additionalComment: String!) {
    approveTicket(workspaceId: $workspaceId, ticketId: $ticketId, additionalComment: $additionalComment) {
      id
      templateName
      status
      additionalComment
    }
  }
`;

export const DENY_TICKET = gql`
  mutation DenyTicket($workspaceId: ID!, $ticketId: ID!, $reasons: [DenialInput!]!, $additionalComment: String!) {
    denyTicket(workspaceId: $workspaceId, ticketId: $ticketId, reasons: $reasons, additionalComment: $additionalComment) {
      id
      templateName
      status
      additionalComment
      denialReasons {
        value
        status
      }
    }
  }
`;

export const DELETE_TICKET = gql`
  mutation DeleteTicket($workspaceId: ID!, $ticketId: ID!) {
    deleteTicket(workspaceId: $workspaceId, ticketId: $ticketId)
  }
`;

export const CREATE_WORKSPACE = gql`
  mutation CreateWorkspace($input: WorkspaceInput!) {
    createWorkspace(input: $input) {
      id
      name
      leader {
        id
        name
        email
      }
      members {
        id
        name
        email
      }
    }
  }
`;

export const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace($id: ID!, $input: WorkspaceInput!) {
    updateWorkspace(id: $id, input: $input) {
      id
      name
      leader {
        id
        name
        email
      }
      members {
        id
        name
        email
      }
    }
  }
`;

export const DELETE_WORKSPACE = gql`
  mutation DeleteWorkspace($id: ID!) {
    deleteWorkspace(id: $id)
  }
`;

import React, { useState, useRef } from "react";
import rubyLogLogo from "../../profile_pic.png";

const DocumentUpload = () => {
  const [documents, setDocuments] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileInputChange = (e) => {
    const newDocuments = [...documents];
    newDocuments.push(e.target.files[0]);
    setDocuments(newDocuments);
  };

  const handleRemoveDocument = (index) => {
    const newDocuments = [...documents];
    newDocuments.splice(index, 1);
    setDocuments(newDocuments);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
    
      <button
        className="flex justify-center pt-2 w-[181px] h-[40px] bg-rose-700 rounded-lg border hover:bg-opacity-90 border-gray-300 text-m text-white mt-3"
        onClick={handleButtonClick}
      >
        Attach Document
      </button>
      <input
        type="file"
        onChange={handleFileInputChange}
        className="hidden"
        id="fileInput"
        ref={fileInputRef}
      />
      <div className="mt-4 flex ">
        {documents.map((document, index) => (
          <div key={index} className="mb-4 ml-2 p-2 border-2 text-center  text-xs">
            <div className="text-center">
              <img
                src={rubyLogLogo}
                alt="Document Icon"
                className="w-20 h-20 mx-auto mb-2"
              />
              <div >{document.name}</div>
            </div>
            <div >
              <button
                className="text-red-600 hover:text-red-800"
                onClick={() => handleRemoveDocument(index)}
              >
                Remove
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentUpload;
import React from "react";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";
import { NavLink } from "react-router-dom";
import ForwardIcon from "@mui/icons-material/Forward";
import NewTicketScreen from "../components/Tickets/NewTicketScreen";

function Ticket() {
  return (
    <main className="relative flex flex-col w-[80%] ">
      <div className="flex justify-between items-center ">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="flex flex-col mt-2 ">
        <div className="flex  mt-3 ">
          <NewTicketScreen />
        </div>
      </div>
    </main>
  );
}

export default Ticket;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function TodaysResults(data) {
  console.log(data)
  const userId = localStorage.getItem("userid");

  // Utility function to determine if the user can view or interact with the audit
  const canViewAudit = (audit) => {
    return (
      audit.assignee.some(assignee => assignee.id === userId) ||
      audit.groups.some(group => group.visibility.includes(userId) ) ||
      audit.assignedBy.visibility.includes(userId) 
    );
  };

  const statusStyle = (status) => {
    switch (status) {
      case "Pass": return <h2 className="my-audits-item text-green-700 bg-green-200">Pass</h2>;
      case "Fail": return <h3 className="text-red-700 bg-red-200 my-audits-item">Fail</h3>;
      case "Unresolved": return <h3 className="text-gray-700 bg-gray-200 my-audits-item">Unresolved</h3>;
      case "Unassigned": return <h3 className="text-yellow-700 bg-yellow-200 my-audits-item">Unassigned</h3>;
      default: return <span>{status}</span>;
    }
  };

  // Filter audits based on user access and audit status
  const filteredAudits = data.value.getAllAudits.filter(canViewAudit);

  const passAudits = filteredAudits.filter(audit => audit.resolution === "Pass");
  const failAudits = filteredAudits.filter(audit => audit.resolution === "Fail");
  const unresolvedAudits = filteredAudits.filter(
    audit => audit.resolution === "Unresolved" 
    // && new Date(audit.endDate) > currentDate
  );
  // const overdueAudits = filteredAudits.filter(
  //   audit => audit.resolution === "Unresolved" && new Date(audit.endDate) < currentDate
  // );

  const [activeTab, setActiveTab] = useState("Pass");

  return (
    <div className="card w-[65rem] h-[40rem] justify-between">
      <div className=" overflow-scroll scrollbar-hide">

        <div className="w-full h-[90%] ">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 bg-gray-100 dark:bg-[#71717a] dark:text-white uppercase">
              <tr>
                <th scope="col" className="px-6 py-3">Audit name</th>
                <th scope="col" className="px-6 py-3">Assigned Groups</th>
                <th scope="col" className="px-6 py-3">Assignor</th>
                <th scope="col" className="px-6 py-3">Assignee</th>
                <th scope="col" className="px-6 py-3">Status</th>
                <th scope="col" className="px-6 py-3">Repeat</th>
              </tr>
            </thead>
            <tbody>
              {(activeTab === "Pass" ? passAudits : activeTab === "Fail" ? failAudits 
              // : activeTab === "OverDue" ? overdueAudits 
              : unresolvedAudits).map((audit, index) => (
                <tr key={index} className="border-b">
                     <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                          {audit.assignee.some(
                            (assignee) =>
                              assignee.id === localStorage.getItem("userid") &&
                              assignee.status === "Unresolved"  && audit.resolution === "Unresolved"
                          ) ? (
                            <NavLink
                              state={{ audit: audit }}
                              to={`/audits/response/${audit.auditId}`}
                            >
                              {audit.auditTitle}
                            </NavLink>
                          ) : (
                            <h2>{audit.auditTitle}</h2>
                          )}
                        </th>
                  <td className="px-6 py-4">{audit.groups.map((group) =>(
                    <div>
                 
                        <p>{group.name}</p>
                       
                    
                  
                    </div>
                  ))}</td>
                  <td className="px-6 py-4">{audit.assignedBy.name}</td>
                  <td className="px-6 py-4">
                    {audit.assignee.map((assignee, idx) => (
                      <div key={idx} className="flex flex-row justify-between pt-1">
                        <p>{assignee.name}</p>
                        <p>{statusStyle(assignee.status)}</p>
                      </div>
                    ))}
                  </td>
                  <td className="py-4">{audit.assignee.length===0 ?statusStyle("Unassigned") : statusStyle(audit.resolution)}</td>
                  <td className="px-6 py-4">{audit.repeatType === "Daily"
                   ? "Daily" : audit.repeatType === "Weekly" ?
                    <div className="flex flex-col space-y-1">
                      <h1>Weekly</h1>
                      <h2>{audit.repeatDays.length>0 ? audit.repeatDays.join(",") : ""}</h2>
                      </div> 
                      :
                      audit.repeatType === "Monthly" ? 
                      <div className="flex flex-col space-y-1">
                      <h1>Monthly</h1>
                      <h2>{audit.repeatDates.length>0 ? audit.repeatDates.join(",") : ""}</h2>
                      </div> : "" }</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex gap-4 text-md text-gray-600 self-center pt-2">
        {["Pass", "Fail", "Unresolved"
        // , "OverDue"
      ].map((tab, index) => (
          <div
            key={index}
            className={`flex p-1 gap-x-2 cursor-pointer ${activeTab === tab ? "text-rose-700 font-semibold border-b-2 border-rose-700" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            <p>{tab}</p>
            <p className={`bg-gray-200 px-2 rounded-xl w-7 text-center ${activeTab === tab ? "font-semibold bg-rose-700 bg-opacity-30" : ""}`}>
              {tab === "Pass" ? passAudits.length :
               tab === "Fail" ? failAudits.length :
              //  tab === "OverDue" ? overdueAudits.length :
               unresolvedAudits.length}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TodaysResults;

import React from "react";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";
import Minichat from "../components/home/minichat";
import ChatListData from "../components/home/ChatListData";

function Home() {
  return (
    <main className="flex flex-col">
      <div className="flex justify-between  items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="flex flex-col mt-2  ">
        <div className="text-[#b00041] flex items-center justify-between px-4 mb-3">
          <div className="flex items-center gap-x-4">
            <h1 className="text-4xl font-black">All Inquiries</h1>
          </div>
        </div>
        <div className="flex flex-row ">
          <div class="card  w-[1100px] h-[695px]   rounded-[25px] m-2 pt-2  ">
            <div class="flex ">
              <div className="w-[434px] h-[695px]">
                <ChatListData />
              </div>
            </div>
          </div>
        </div>
        <Minichat />
      </div>
    </main>
  );
}

export default Home;

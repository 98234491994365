import { gql } from "@apollo/client";

// Mutation to create a new ticket
const CREATE_TICKET = gql`
  mutation CreateTicket($input: TicketInput!) {
    createTicket(input: $input) {
      id
      tenantId
      templateId
      templateName
      assignees
      categoryId
      answers {
        questionId
        value
      }
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
      status
      denialReasons {
        value
        status
      }
      additionalComment
    }
  }
`;

// Mutation to approve a ticket by ID
const APPROVE_TICKET = gql`
  mutation ApproveTicket($tenantId: ID!, $ticketId: ID!,$additionalComment : String!) {
    approveTicket(tenantId: $tenantId, ticketId: $ticketId,additionalComment : $additionalComment) {
      id
      tenantId
      templateId
      templateName
      categoryId
      answers {
        questionId
        value
      }
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
      status
      assignees
      denialReasons {
        value
        status
      }
      additionalComment
    }
  }
`;

// Mutation to deny a ticket by ID with reasons
const DENY_TICKET = gql`
  mutation DenyTicket($tenantId: ID!, $ticketId: ID!, $reasons: [DenialInput!]!,$additionalComment : String!) {
    denyTicket(tenantId: $tenantId, ticketId: $ticketId, reasons: $reasons,additionalComment : $additionalComment) {
      id
      tenantId
      templateId
      templateName
      categoryId
      answers {
        questionId
        value
      }
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
      status
      assignees
      denialReasons {
        value
        status
      }
      additionalComment
    }
  }
`;

// Mutation to delete a ticket by ID
const DELETE_TICKET = gql`
  mutation DeleteTicket($tenantId: ID!, $ticketId: ID!) {
    deleteTicket(tenantId: $tenantId, ticketId: $ticketId)
  }
`;

// Mutation to create a new category
const CREATE_CATEGORY = gql`
  mutation CreateCategory($input: CategoryInput!) {
    createCategory(input: $input) {
      id
      tenantId
      name
      parentId
      childrenIds
      icon
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;

// Mutation to update a category by ID
const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $input: UpdateCategoryInput!) {
    updateCategory(id: $id, input: $input) {
      id
      tenantId
      name
      parentId
      icon
      childrenIds
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;

// Mutation to delete a category by ID
const DELETE_CATEGORY = gql`
  mutation DeleteCategory($tenantId: ID!, $id: ID!) {
    deleteCategory(tenantId: $tenantId, id: $id)
  }
`;

// Mutation to create a new template
const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($input: TemplateInput!) {
    createTemplate(input: $input) {
      id
      tenantId
      title
      description
      estimatedResolutionDays
      availableFrom
      availableTo
      availabilityDays
      visibilityType
      visibility
      denialReasons
      assigneeIds
      dynamicFields
      connectedFields
      questions {
        questionName
        questionType
        isRequired
        values
      }
      categoryId
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;

// Mutation to update a template by ID
const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate(
    $categoryId: ID!
    $id: ID!
    $input: UpdateTemplateInput!
  ) {
    updateTemplate(categoryId: $categoryId, id: $id, input: $input) {
      id
      tenantId
      title
      description
      estimatedResolutionDays
      availableFrom
      availableTo
      denialReasons
      availabilityDays
      visibilityType
      visibility
      assigneeIds
      dynamicFields
      connectedFields
      questions {
        questionName
        questionType
        isRequired
        values
      }
      categoryId
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;

// Mutation to delete a template by ID
const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($tenantId: ID!, $categoryId: ID!, $id: ID!) {
    deleteTemplate(tenantId: $tenantId, categoryId: $categoryId, id: $id)
  }
`;

const UPDATE_TICKET = gql`
  mutation UpdateTicket(
    $tenantId: ID!
    $ticketId: ID!
    $updatedTicketData: UpdateTicketInput!
  ) {
    updateTicketById(
      tenantId: $tenantId
      ticketId: $ticketId
      updatedTicketData: $updatedTicketData
    ) {
      success
    }
  }
`;

export {
  CREATE_TICKET,
  APPROVE_TICKET,
  DENY_TICKET,
  DELETE_TICKET,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_TICKET,
  DELETE_CATEGORY,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
};

import { gql } from "@apollo/client";

// Query to get a ticket by ID
const GET_TICKET_BY_ID = gql`
  query GetTicketById($tenantId: ID!, $id: ID!) {
    getTicketById(tenantId: $tenantId, id: $id) {
      id
      tenantId
      templateId
      templateName
      categoryId
      answers {
        questionId
        value
      }
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
      status
      assignees
      denialReasons {
        value
        status
      }
      additionalComment
    }
  }
`;

// Query to get all tickets for a tenant
const GET_ALL_TICKETS = gql`
  query GetAllTickets($tenantId: ID!) {
    getAllTickets(tenantId: $tenantId) {
      id
      tenantId
      templateId
      templateName
      assignees
      categoryId
      answers {
        questionId
        value
      }
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
      status
      denialReasons {
        value
        status
      }
      additionalComment
    }
  }
`;

// Query to get tickets by category
const GET_TICKETS_BY_CATEGORY = gql`
  query GetTicketsByCategory($tenantId: ID!, $categoryId: ID!) {
    getTicketsByCategory(tenantId: $tenantId, categoryId: $categoryId) {
      id
      tenantId
      templateId
      templateName
      assignees
      categoryId
      answers {
        questionId
        value
      }
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
      status
      denialReasons {
        value
        status
      }
      additionalComment
    }
  }
`;

// Query to get templates by category
const GET_TEMPLATES_BY_CATEGORY = gql`
  query GetTemplatesByCategory($tenantId: ID!, $categoryId: ID!) {
    getTemplatesByCategory(tenantId: $tenantId, categoryId: $categoryId) {
      id
      tenantId
      title
      description
      denialReasons
      estimatedResolutionDays
      availableFrom
      availableTo
      availabilityDays
      visibilityType
      visibility
      assigneeIds
      dynamicFields
      connectedFields
      questions {
        questionName
        questionType
        isRequired
        values
      }
      categoryId
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;

// Query to get all categories for a tenant
const GET_ALL_CATEGORIES = gql`
  query GetAllCategories($tenantId: ID!) {
    getAllCategories(tenantId: $tenantId) {
      id
      tenantId
      name
      parentId
      icon
      childrenIds
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;

// Query to get tickets by user
const GET_TICKET_BY_USER = gql`
  query GetTicketByUser($tenantId: ID!, $userId: ID!) {
    getTicketByUser(tenantId: $tenantId, userId: $userId) {
      id
      tenantId
      templateId
      templateName
      assignees
      categoryId
      answers {
        questionId
        value
      }
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
      status
      denialReasons {
        value
        status
      }
      additionalComment
    }
  }
`;

export {
  GET_TICKET_BY_ID,
  GET_ALL_TICKETS,
  GET_TICKETS_BY_CATEGORY,
  GET_TEMPLATES_BY_CATEGORY,
  GET_ALL_CATEGORIES,
  GET_TICKET_BY_USER
};

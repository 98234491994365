import React from "react";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";
import { NavLink } from "react-router-dom";
import ForwardIcon from "@mui/icons-material/Forward";
import CreateTemplateForm from "../components/audits/CreateTemplateForm";

function CreateAuditTemplate() {
  return (
    <main className="relative flex flex-col mx-auto">
      <div className="flex justify-between items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="flex flex-col mt-2">
        <div className="text-ruby-red flex items-center justify-between px-4">
          <div className="flex gap-x-4">
            <NavLink
              className="flex items-center gap-x-4 hover:text-red-800"
              to="/audits/templates"
            >
              <ForwardIcon fontSize="large" className="rotate-180" />
            </NavLink>
            <h1 className="text-4xl font-black">Create Audit Template</h1>
          </div>
        </div>
        <div className="flex justify-center">
          <CreateTemplateForm />
        </div>
      </div>
    </main>
  );
}

export default CreateAuditTemplate;

import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import { NavLink } from "react-router-dom";
const PopupCard = () => (
  <div className="fixed inset-0 ml-60 flex items-center justify-center ">
    <div className="bg-white shadow-lg rounded-lg w-[464px] h-[230px] p-6">
      <div className="w-12 h-12 relative bg-emerald-100 rounded-3xl ml-[180px]">
        <div className="w-6 h-6 left-[12px] top-[12px] absolute bg-emerald-600 rounded-full">
          <DoneIcon />
        </div>
      </div>
      <div className="flex flex-col justify-start items-center gap-2 mt-4">
        <div className="text-gray-900 text-lg font-medium font-montserrat leading-normal">
          New Audit Template Created
        </div>
        <div className="text-gray-500 text-sm font-normal font-montserrat leading-tight">
          Manage templates any time!
        </div>
        <div className="flex items-stretch space-x-3 mt-2">
          <div>
            <NavLink
              to="/audits/templates"
              onClick={() => {
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              }}
            >
              <div className="bg-white text-black border-2  px-4 py-2 rounded-lg hover:bg-rose-800 transition-colors duration-300">
                Manage Templates
              </div>
            </NavLink>
          </div>
          <div>
            <NavLink
              to="/audits"
              onClick={() => {
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              }}
            >
              <div className="bg-rose-700 text-white border-2 border-rose-700  px-4 py-2 rounded-lg hover:bg-rose-800 transition-colors duration-300">
                View Audits
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PopupCard;

import { gql } from "@apollo/client";

const GET_ALL_AUDITS = gql`
query GetAllAudits($tenantId: ID!) {
  getAllAudits(tenantId: $tenantId) {
    auditId
    repeatCount
    assignee {
      id
      name
      status
    }
    groups {
      id
      name
      visibility
    }
    auditDesc
    auditTitle
    endDate
    assignedBy {
      id
      name
      visibility
    }
    repeatType
    repeatDays
    repeatDates
    resolution
    startDate
    comment
    questions {
      questionId
      questionName
      questionType
      isRequired
      values
      response {
        name
        values
      }
      failvalue
    }
  }
}
`;

const GET_AUDIT_BY_ID = gql`
query GetAuditById($tenantId: ID!, $auditId: ID!) {
  getAuditById(tenantId: $tenantId, auditId: $auditId) {
    auditId
    repeatCount
    assignee {
      id
      name
      status
    }
    groups {
      id
      name
      visibility
    }
    auditDesc
    auditTitle
    endDate
    assignedBy {
      id
      name
      visibility
    }
    repeatType
    repeatDays
    repeatDates
    resolution
    startDate
    comment
    questions {
      questionId
      questionName
      questionType
      isRequired
      values
      response {
        name
        values
      }
      failvalue
    }
  }
}
`;

export { GET_ALL_AUDITS, GET_AUDIT_BY_ID };

import React from "react";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";
import { NavLink } from "react-router-dom";
import TodaysResults from "../components/audits/TodaysResults";

import { useQuery } from "@apollo/client";

import { GET_ALL_AUDITS } from "../GraphQL/Audits/Queries";

function Audits() {
  const { loading, error, data } = useQuery(GET_ALL_AUDITS, {
    variables: {
      tenantId: localStorage.getItem('tenantId'),
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  console.log(data);
  return (
    <main className="relative flex flex-col w-[80%]">
      <div className="flex justify-between items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="flex flex-col mt-2">
        <div className="text-[#b00041] flex items-center justify-between px-4">
          <div className="flex items-center gap-x-4">
            <h1 className="text-4xl font-black">Audits</h1>
          </div>
          <div className=" mb-3">
          {localStorage.getItem("isAdmin") === "true" &&
            <NavLink
              to="/audits/templates"
              className="box navlink-button navlink-button-hover"
            >
              Manage Templates
            </NavLink>
}
          </div>
        </div>
        <div className="flex ">
          <TodaysResults value={data} />
          {/* <MyAudits value={data} /> */}
        </div>
        <div className="flex justify-end">
          {/* <AllAudits value={data} /> */}
          {/* <AuditsFilter /> */}
        </div>
      </div>
    </main>
  );
}

export default Audits;

import React, { useState, useEffect } from "react";
import Loggedin from "./Loggedin";
import Login from "./pages/LogInForm";

import { Routes, Route } from "react-router-dom";




import Home from "./pages/Home";
import Admin from "./pages/Admin";
import AdminTenant from "./pages/AdminTenant";
import Audits from "./pages/Audits";
import AuditTemplates from "./pages/AuditTemplates";
import AssignAudit from "./pages/AssignAudit";

import CreateAuditTemplate from "./pages/CreateAuditTemplate";
import FileUpload from "./pages/FileUpload";
import Notifications from "./pages/Notifications";

import ForgotPassword from "./pages/ForgotPassword";

import Dashboard from "./pages/Dashboard";

import Ticket from "./pages/Ticket";
import IndividualTicket from "./components/Tickets/IndividualTicket";

import ActiveTicket from "./components/Tickets/ActiveTicket";

import Landing from "./pages/Landing";

import AllTickets from "./components/Tickets/AllTickets";
import Publictools from "./components/Publictools";
import AuditResponse from "./components/audits/AuditResponse";
import CreateCategory from "./components/Tickets/CreateCategory";
import CreateTemplate from "./components/Tickets/CreateTemplate";
import TemplateList from "./components/Tickets/TemplateList";
import TicketDetails from "./components/Tickets/TicketDetails";

import Sidebar from "./components/Sidebar/Sidebar";
import './index.css';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';


import WorkspaceSettings from "./pages/WorkspaceSettings";
import WorkspacePage from "./pages/WorkspacePage";
import CreateTemplateForm from "./pages/CreateTemplateForm";
import FeedbackForm from "./components/FeedbackForm"; // added this line

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleCollapse = (isCollapsed) => {
    setCollapsed(isCollapsed);
  };
  return (
    <div className="dark:bg-neutral-900 w-screen">
      {currentUser && <Sidebar onCollapse={handleCollapse} />}
     
      <div className={`main-content ${currentUser ? (collapsed ? 'collapsed' : 'expanded') : 'full-width'}`}>
        <Routes>
          <Route
            path="/freetools/fishbone_diagram_creator"
            element={<Publictools />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Landing />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/" element={<Loggedin />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="chat" element={<Home />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/admintenant" element={<AdminTenant />} />

            <Route path="audits" element={<Audits />} />
            <Route path="/audits/:auditId" element={<AssignAudit />} />
            <Route
              path="/audits/response/:auditId"
              element={<AuditResponse />}
            />

            <Route path="audits/templates" element={<AuditTemplates />} />
            <Route path="tickets" element={<Ticket />} />
            <Route path="tickets/:id" element={<TemplateList />} />
            <Route path="ticketdetails/:id" element={<TicketDetails />} />
            <Route path="tickets/response/:id" element={<IndividualTicket />} />
            <Route
              path="tickets/:id/createtemplate"
              element={<CreateTemplate />}
            /> 
            
            <Route path="tickets/createcategory" element={<CreateCategory />} />

            <Route path="tickets/activeticket" element={<ActiveTicket />} />
            <Route path="tickets/alltickets" element={<AllTickets />} />
            <Route
              path="audits/templates/create"
              element={<CreateAuditTemplate />}
            />
            <Route
              path="/workspace/:id/settings"
              element={<WorkspaceSettings />}
            />
            {/* how to know the path below? */}
            <Route
              path="components/FeedbackForm"
              element={<FeedbackForm  />}
            />
            
             <Route
              path="/workspace/:id/create-template"
              element={<CreateTemplateForm />}
            />
            <Route path="/workspace/:id" element={<WorkspacePage />} />

            <Route path="fileupload" element={<FileUpload />} />
            <Route path="notifications" element={<Notifications />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;

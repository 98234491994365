import React, { useRef, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_TICKET_BY_USER } from "../../GraphQL/Tickets/Queries";
import { DELETE_TICKET } from "../../GraphQL/Tickets/Mutations";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import * as Icons from "@mui/icons-material";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import ToastAlert, { showToast } from "../ToastAlert";
import { NavLink } from "react-router-dom";

const MyTickets = () => {
  const tenantId = localStorage.getItem("tenantId");
  const userId = localStorage.getItem("userid");
  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchField, setSearchField] = useState("templateName");
  const [searchTerm, setSearchTerm] = useState("");

  const {
    loading: ticketsLoading,
    error: ticketsError,
    data: ticketsData,
  } = useQuery(GET_TICKET_BY_USER, {
    variables: { tenantId, userId },
  });

  const [deleteTicket] = useMutation(DELETE_TICKET, {
    onCompleted: () => {
      showToast("Ticket deleted successfully!", "success");
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    },
    onError: (error) => {
      showToast(`Error deleting ticket: ${error.message}`, "error");
    },
  });

  const handleDeleteTicket = async (ticketId) => {
    await deleteTicket({ variables: { tenantId, ticketId } });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (ticketsLoading) {
    return <div>Loading...</div>;
  }

  if (ticketsError) {
    return <div>Error loading tickets</div>;
  }

  const statusStyle = (status) => {
    if (status === "Approved") {
      return <h2 className="my-audits-item text-green-700 bg-green-200">{"Approved"}</h2>;
    } else if (status === "Denied") {
      return <h3 className="text-red-700 bg-red-200 my-audits-item">{"Denied"}</h3>;
    } else if (status === "Pending") {
      return <h3 className="text-black bg-stone-200 my-audits-item">{"Pending"}</h3>;
    }
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedTickets = [...ticketsData.getTicketByUser].sort((a, b) => {
    let comparison = 0;
    if (a[sortField] > b[sortField]) {
      comparison = 1;
    } else if (a[sortField] < b[sortField]) {
      comparison = -1;
    }
    return sortDirection === "asc" ? comparison : -comparison;
  });

  const filteredTickets = sortedTickets.filter((ticket) => {
    if (!searchTerm) return true;
    const value = ticket[searchField];
    if (typeof value === "string") {
      return value.toLowerCase().includes(searchTerm.toLowerCase());
    } else if (typeof value === "object" && value.name) {
      return value.name.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  });

  return (
    <div className="card w-[70rem] max-h-[10000rem] mt-5 gap-2">
      <ToastAlert />
      <div className="text-xl mb-3 text-rose-700 text-bold">My Tickets</div>
      <div className="flex items-center">
        <div className="justify-between bg-zinc-100 mb-3 dark:bg-darkcard rounded-full border-2">
          <SearchIcon className="text-[#b00041] ml-3" fontSize="large" />
          <input
            type="search"
            name="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="search"
            className="w-[700px] bg-zinc-100 rounded-full dark:bg-darkcard h-[50px] px-4 text-m focus:outline-none"
          />
        </div>
        <div className="ml-5 pb-3">

        
        <FormControl className="w-[200px]">
          <InputLabel>Search By</InputLabel>
          <Select
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            label="Search By"
          >
            <MenuItem value="templateName">Template Name</MenuItem>
            <MenuItem value="status">Status</MenuItem>
            <MenuItem value="createdBy.name">Created By</MenuItem>
            <MenuItem value="id">Ticket ID</MenuItem>
          </Select>
        </FormControl>
        </div>
      </div>
      <div className="flex flex-row gap-4 mb-4">
        <h2 className="text-2xl font-semibold text-ruby-red mt-1">Sort By : </h2>
        <button onClick={() => handleSort("templateName")} className="text-ruby-red px-3 py-2 rounded-xl border border-ruby-red hover:text-white hover:bg-ruby-red">
          Template Name
        </button>
        <button onClick={() => handleSort("status")} className="text-ruby-red px-3 py-2 rounded-xl border border-ruby-red hover:text-white hover:bg-ruby-red">
          Status
        </button>
        <button onClick={() => handleSort("createdBy.name")} className="text-ruby-red px-3 py-2 rounded-xl border border-ruby-red hover:text-white hover:bg-ruby-red">
          Created By
        </button>
        <button onClick={() => handleSort("id")} className="text-ruby-red px-3 py-2 rounded-xl border border-ruby-red hover:text-white hover:bg-ruby-red">
          Ticket ID
        </button>
      </div>
      {filteredTickets.map((ticket) => (
        <div key={ticket.id} className="flex flex-col border border-gray-300 rounded-lg p-4 mb-2">
          <div className="flex justify-between">
            <div className="w-full flex flex-row justify-between">
            <NavLink to={`/ticketdetails/${ticket.id}`} state={{ ticketData: ticket }}>
              {ticket.id}
              </NavLink>
              <h2 className="flex flex-row space-x-2">
                {statusStyle(ticket.status)}
              </h2>
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div>
              <p>
                <strong>Template: </strong> {ticket.templateName}
              </p>
            </div>
            <button
              className="text-red-500 hover:text-red-700"
              onClick={() => handleDeleteTicket(ticket.id)}
            >
              <Icons.DeleteForever />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyTickets;

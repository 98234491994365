import React from "react";
import ProfilePic from "../profile_pic.jpg";

const auditData = [
  {
    title: "Team1",
    projectmanager: "Vrushab",
    actiontitle: "Action title 1",
    project: "1",
    status: "Active",
    team: " Team 1",
    statusColor: "green",
  },
  {
    title: "Team1",
    projectmanager: "Vrushab",
    actiontitle: "Action title 1",
    project: "1",
    status: "Active",
    team: " Team 1",
    statusColor: "green",
  },
  {
    title: "Team1",
    projectmanager: "Vrushab",
    actiontitle: "Action title 1",
    project: "1",
    status: "Active",
    team: " Team 1",
    statusColor: "green",
  },
  {
    title: "Team1",
    projectmanager: "Vrushab",
    actiontitle: "Action title 1",
    project: "1",
    status: "Active",
    team: " Team 1",
    statusColor: "green",
  },
];

function ActionItems() {
  return (
    <div className="card w-[47rem] h-[35rem] justify-between">
      <div>
        <h1>Today's Results</h1>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs dark:bg-[#71717a] dark:text-white text-gray-700  bg-gray-100 border uppercase">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Teams
                </th>
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Team
                </th>
              </tr>
            </thead>
            <tbody>
              {auditData.map((audit, index) => (
                <tr key={index} className="border-b">
                  <th
                    scope="row"
                    className="px-6 py-4 w-[15rem] flex flex-row space-x-20 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    <div className=" space-y-2">
                      <h2> {audit.title} </h2>
                      <h2 className="text-gray-600">{audit.projectmanager} </h2>
                    </div>
                    <div>
                      <img
                        alt=""
                        class="cursor-pointer w-8 h-8 absolute rounded-full ml-[20px]   hover:shadow-outline"
                        src={ProfilePic}
                      />
                      <img
                        alt=""
                        class="cursor-pointer w-8 h-8 absolute rounded-full ml-[40px]  hover:shadow-outline"
                        src={ProfilePic}
                      />
                      <img
                        alt=""
                        class="cursor-pointer w-8 h-8 absolute rounded-full ml-[60px]   hover:shadow-outline"
                        src={ProfilePic}
                      />
                      <img
                        alt=""
                        class="cursor-pointer w-8 h-8 absolute rounded-full ml-[80px]  hover:shadow-outline"
                        src={ProfilePic}
                      />
                    </div>
                  </th>
                  <td className="px-6 py-4">
                    <div className="space-y-2">
                      <h2>{audit.actiontitle}</h2> <h2>{audit.project}</h2>
                    </div>
                  </td>
                  <td className=" py-4">
                    <div
                      className={`text-${audit.statusColor}-700 bg-${audit.statusColor}-200 w-[100px] rounded-full text-center py-1`}
                    >
                      {audit.status}
                    </div>
                  </td>

                  <td className="px-6 py-4">{audit.team}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex gap-4 text-xs text-gray-600 self-center">
        {[
          { label: "Structured", count: 4 },
          { label: "Assigned", count: 4 },
          { label: "Personal", count: 4 },
        ].map((item, index) => (
          <div key={index} className="flex gap-x-1">
            <p>{item.label}</p>
            <p className="bg-gray-200 px-2 rounded-full">{item.count}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ActionItems;

import React, { useState } from "react";
// import Navbar from "../Navbar";
// import SearchBar from "../SearchBar";
import MembersDisplay from "../MembersDisplay";
import DocumentUpload from "./DocumentUpload";
import ProfilePic from "../../profile_pic.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ForwardIcon from "@mui/icons-material/Forward";
import { UPDATE_AUDIT } from "../../GraphQL/Audits/Mutations";
import { useMutation } from "@apollo/client";
import ToastAlert, { showToast } from "../../components/ToastAlert";

function Checkbox({ id, name, value, checked, onChange }) {
  return (
    <label className="flex items-center space-x-2">
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        className="bg-black accent-rose-900"
        checked={checked}
        onChange={onChange}
      />
      <span className="text-black text-m">{value}</span>
    </label>
  );
}

function MultipleChoice({ id, name, options, selectedOption, onChange }) {
  return (
    <div>
      {options.map((option, index) => (
        <label key={index} className="flex items-center space-x-2">
          <input
            type="radio"
            id={`${id}-${index}`}
            name={name}
            value={option}
            className="bg-black accent-rose-900"
            checked={selectedOption === option}
            onChange={() => onChange(option)}
          />
          <span className="text-black text-m">{option}</span>
        </label>
      ))}
    </div>
  );
}

function AuditResponse() {
  const audit = useLocation().state.audit;
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [updateAudit] = useMutation(UPDATE_AUDIT);
  const user = localStorage.getItem("userid");
  const navigate = useNavigate();
  console.log(selectedAnswers);

  const handleCheckboxChange = (questionId, value) => {
    setSelectedAnswers((prevState) => ({
      ...prevState,
      [questionId]: {
        ...(prevState[questionId] || {}),
        [value]: !prevState[questionId]?.[value],
      },
    }));
  };

  const handleRadioButtonChange = (questionId, value) => {
    setSelectedAnswers((prevState) => ({
      ...prevState,
      [questionId]: value,
    }));
  };

  const submitAudit = () => {
    let failstatus = false;
    const updatedQuestions = audit.questions.map((question) => {
      let previousResponse = question.response || [];
      let newResponse;

      if (typeof selectedAnswers[question.questionId] === "object") {
        newResponse = {
          name: localStorage.getItem("name"),
          values: Object.keys(selectedAnswers[question.questionId]).join(","),
        };
      } else {
        if (question.failvalue === selectedAnswers[question.questionId]) {
          failstatus = true;
        }
        newResponse = {
          name: localStorage.getItem("name"),
          values: selectedAnswers[question.questionId],
        };
      }

      return {
        ...question,
        response: previousResponse.concat(newResponse || []),
      };
    });

    const updatedAssignee = audit.assignee.map((assignee) => ({
      ...assignee,
      status: assignee.id === user ? (failstatus ? "Fail" : "Pass") : assignee.status,
    }));

    const allAssigneesPassed = updatedAssignee.every(
      (assignee) => assignee.status === "Pass"
    );

    const resolution = failstatus ? "Fail" : allAssigneesPassed ? "Pass" : audit.resolution;
    console.log(failstatus, resolution);

    updateAudit({
      variables: {
        updatedAuditData: {
          tenantId: localStorage.getItem("tenantId"),
          auditId: audit.auditId,
          assignee: updatedAssignee,
          resolution: resolution,
          questions: updatedQuestions,
        },
      },
    })
      .then((response) => {
        const toastContent = (
          <div>
            <p className="text-ruby-red text-bold">
              Audit Answered Successfully!{" "}
            </p>
            <p className="text-green-500">{audit.auditTitle}</p>
          </div>
        );
        showToast(toastContent, "success");
        setTimeout(() => {
          navigate("/audits");
          window.location.reload();
        }, 1500);
        navigate("/audits");
      })
      .catch((error) => {
        console.error("Error updating audit:", error);
      });
  };

  const renderQuestionInput = (question, selectedAnswer) => {
    if (question.questionType === "checkbox") {
      return question.values.map((value, index) => (
        <Checkbox
          key={index}
          id={question.questionId}
          name={question.questionName}
          value={value}
          onChange={() => handleCheckboxChange(question.questionId, value)}
          checked={selectedAnswer?.[value]}
        />
      ));
    } else if (question.questionType === "multipleChoice") {
      return (
        <MultipleChoice
          id={question.questionId}
          name={question.questionName}
          options={question.values}
          onChange={(value) =>
            handleRadioButtonChange(question.questionId, value)
          }
          selectedOption={selectedAnswer}
        />
      );
    }
  };

  return (
    <main className="flex flex-col mx-auto font-montserrat">
      <ToastAlert />
      <div className="flex justify-between items-center space-x-2">
        {/* <SearchBar />
        <Navbar /> */}
      </div>

      <div className="flex flex-col ">
        <div className="text-[#b00041] flex  flex-row space-x-2">
          <NavLink to="/audits">
            <ForwardIcon fontSize="large" className="rotate-180" />
          </NavLink>
          <h1 className="text-4xl font-black">Audit {audit.auditTitle}</h1>
        </div>

        <div className="flex justify-around ">
          <div className="card max-w-[54rem] h-max-[1000px] justify-between space-y-3">
            <div className="flex flex-col text-l space-y-4">
              <div className="flex flex-row text-l  space-x-2 text-neutral-500 font-light">
                <h2 className="text-sm">*</h2>
                <h2>Required</h2>
              </div>

              <div className="flex flex-row text-l text-gray-900 space-x-2 font-semibold">
                Audit Description
              </div>
              <h2 className="text-gray-900 text-md w-[50rem] font-nunito">
                {audit.auditDesc}
              </h2>
            </div>
            <div className="flex flex-row text-l text-gray-900 space-x-2 font-semibold mt-3 text-ruby-red">
              Questions
            </div>
            {audit.questions.map((question, index) => (
              <div key={question.questionId} className="ml-3">
                <div className="flex flex-row text-l mt-2 space-x-2 mb-2">
                  <h2 className="font-semibold">{index + 1}. {question.questionName}</h2>
                  <h2 className="text-sm text-ruby-red">*</h2>
                </div>
                <div className="ml-3"> 
                {renderQuestionInput(
                  question,
                  selectedAnswers[question.questionId]
                )}
                  </div>
              </div>
            ))}
            <div className="flex flex-row text-l mt-4 space-x-2">
              Supporting Documents
            </div>
            <div className="flex flex-row space-x-2">
              <DocumentUpload />
            </div>
            <div className="mt-5 flex md:flex md:flex-grow flex-row justify-end space-x-3">
              <button className="text-rose-900 text-sm w-[112px] h-[38px] px-[30px] py-[9px] rounded-md border-2 border-rose-900 font-semibold font-montserrat">
                Cancel
              </button>
              <button
                type="submit"
                onClick={submitAudit}
                className="w-[112px] h-[38px] px-[30px] py-[9px] bg-rose-900 rounded-md text-white text-sm font-semibold font-montserrat"
              >
                Submit
              </button>
            </div>
          </div>
          <div>
            <div className="card h-80 w-[24rem]">
              <h1>Assigned By</h1>
              <div className="overflow-y-auto scrollbar-hide">
                <div className="flex border rounded-lg shadow-sm px-3 py-3 my-2">
                  <img
                    alt="profile pic"
                    src={ProfilePic}
                    className="w-[40px] h-[40px] rounded-full mr-3"
                  />
                  <div>
                    <h2 className="font-semibold mb-1">
                      {audit.assignedBy.name}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="card h-80 w-[24rem]">
              <h1>Assignee</h1>
              <div className="overflow-y-auto scrollbar-hide">
                {audit.assignee.map((member) => (
                  <div
                    className="flex border rounded-lg shadow-sm px-3 py-3 my-2"
                    key={member.id}
                  >
                    <img
                      alt="profile pic"
                      src={ProfilePic}
                      className="w-[40px] h-[40px] rounded-full mr-3"
                    />
                    <div>
                      <h2 className="font-semibold mb-1">{member.name}</h2>
                      <h2 className="text-[#6B7280]">{member.status || ""}</h2>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AuditResponse;

import React, { useEffect, useState } from "react";
import UserendAudit from "./UserendAudit";
import AuditCreatedPopup from "./AuditCreatedPopup";

import { useMutation } from "@apollo/client";
import { CREATE_AUDIT, UPDATE_AUDIT } from "../../GraphQL/Audits/Mutations";
import ToastAlert, { showToast } from "../../components/ToastAlert";
import { useLocation, useNavigate } from "react-router-dom";

function CreateTemplateForm() {
  const defaultQuestion = {
    questionType: "checkbox",
    questionName: "",
    values: [""],
    response: [],
    isRequired: false,
    failvalue: ""
  };

  const navigate = useNavigate();
  const location = useLocation();
  const dataedit = location.state.currentaudit;
  const [questions, setQuestions] = useState([defaultQuestion]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [auditTitle, setAuditTitle] = useState("");
  const [auditDesc, setAuditDesc] = useState("");

  useEffect(() => {
    if (dataedit) {
      setAuditTitle(dataedit.auditTitle);
      setAuditDesc(dataedit.auditDesc);
      setQuestions(dataedit.questions);
    }
  }, [dataedit]);

  const [createAudit] = useMutation(CREATE_AUDIT);
  const [updateAudit] = useMutation(UPDATE_AUDIT);

  const handleCreateTemplateClick = async () => {
    try {
      if (!dataedit) {
        await createAudit({
          variables: {
            input: {
              tenantId: localStorage.getItem('tenantId'),
              assignee: [],
              groups: [],
              assignedBy: { id: "", name: "", visibility: [localStorage.getItem('userid')] },
              auditDesc,
              auditTitle,
              endDate: new Date().toISOString(),
              repeatType: "",
              repeatDays: [],
              repeatCount : 0,
              repeatDates: [],
              resolution: "Unresolved",
              startDate: new Date().toISOString(),
              comment: "",
              questions,
            },
          },
        });
      } else {
        await updateAudit({
          variables: {
            updatedAuditData: {
              tenantId: localStorage.getItem('tenantId'),
              auditId: dataedit.auditId,
              auditDesc,
              auditTitle,
              questions,
            },
          },
        });
      }
      showToast(`Audit ${dataedit ? "updated" : "created"} successfully!`, "success");
      setIsPopupOpen(true);
    } catch (error) {
      console.error("Error creating or updating audit:", error);
      showToast("Failed to create or update audit.", "error");
    }
  };

  return (
    <form >
      <ToastAlert />
      <div className="flex mt-10 ">
        <div className="card w-[890px] max-h-[20000px] p-5 rounded-[25px] shadow">
          <div className="text-gray-700 text-sm font-medium font-nunito">Audit Name</div>
          <input
            type="text"
            required
            value={auditTitle}
            className="bg-gray-50 dark:bg-darkcard border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            onChange={(e) => setAuditTitle(e.target.value)}
          />
          <div className="text-gray-700 text-sm pt-4 font-medium font-nunito">Audit Description</div>
          <textarea
            required
            className="bg-gray-50 dark:bg-darkcard border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            rows="4"
            value={auditDesc}
            onChange={(e) => setAuditDesc(e.target.value)}
          />
          <div className="text-gray-700 text-sm pt-4 font-medium font-nunito">Questions</div>
          <UserendAudit
            allquestions={questions}
            onUpdateQuestions={setQuestions}
          />
          <div className="mt-5 flex md:flex-grow flex-row justify-end space-x-3">
            <button
              type="button"
              onClick={handleCreateTemplateClick}
              className="w-[160px] h-[38px] bg-rose-900 rounded-md text-white text-sm font-semibold font-nunito"
            >
              {dataedit ? "Update Template" : "Create Template"}
            </button>
            {isPopupOpen && <AuditCreatedPopup />}
          </div>
        </div>
      </div>
    </form>
  );
}

export default CreateTemplateForm;

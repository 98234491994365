import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  GET_USER_GROUPS,
  GET_SUB_GROUPS,
  GET_ALL_GROUPS,
} from "../../GraphQL/Groups/Queries";
import { GET_ALL_USERS } from "../../GraphQL/Users/Queries";
import { UPDATE_AUDIT } from "../../GraphQL/Audits/Mutations";
import MultiSelect from "./Multiselect";
import ToastAlert, { showToast } from "../../components/ToastAlert";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

function AssignAuditForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const tenantId = localStorage.getItem("tenantId");
  const userId = localStorage.getItem("userid");
  const audit = location.state.audit;

  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(audit.assignedBy);
  const [allGroups, setAllGroups] = useState([]);
  const [selectedAllGroups, setSelectedAllGroups] = useState([]);
  const [includeUsers, setIncludeUsers] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(audit.assignee || []);
  const [formData, setFormData] = useState({
    repeatType: audit.repeatType || "None",
    repeatDays: audit.repeatDays || [],
    repeatDates: audit.repeatDates || [],
    repeatCount: 1, // Default to 1 for count-based repetition
  });

  const [updateAudit] = useMutation(UPDATE_AUDIT);
  const { loading: groupsLoading, data: leaderGroups } = useQuery(GET_USER_GROUPS, {
    variables: { tenantId, userId },
  });
  const { loading: allGroupsLoading, data: allGroupsData } = useQuery(GET_ALL_GROUPS, {
    variables: { tenantId },
  });
  const { loading: usersLoading, data: usersData } = useQuery(GET_ALL_USERS, {
    variables: { tenantId },
  });
  const [loadSubGroups, { loading: subGroupsLoading, data: subGroupsData }] = useLazyQuery(GET_SUB_GROUPS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (leaderGroups) {
      leaderGroups.queryUserGroups.forEach((group) => {
        if (group.leaderId === userId) {
          loadSubGroups({
            variables: { tenantId, groupId: group.id },
          });
        }
      });
    }
  }, [leaderGroups, tenantId, loadSubGroups, userId]);

  useEffect(() => {
    if (subGroupsData) {
      const options = leaderGroups.queryUserGroups.map((group) => ({
        id: group.id,
        name: group.name,
        visibility: group.visibility,
      }));

      if (subGroupsData.querySubGroups) {
        subGroupsData.querySubGroups.forEach((subGroup) => {
          options.push({
            id: subGroup.id,
            name: subGroup.name,
            visibility: subGroup.visibility,
          });
        });
      }

      setGroupOptions(options);
    }
  }, [subGroupsData, leaderGroups]);

  useEffect(() => {
    if (allGroupsData) {
      setAllGroups(
        allGroupsData.getAllGroups.map((group) => ({
          id: group.id,
          name: group.name,
          members: group.members,
          visibility: group.visibility,
        }))
      );
      const filteredGroups = allGroupsData.getAllGroups.filter((group) =>
        audit.groups.some((auditGroup) => auditGroup.id === group.id)
      );
      setSelectedAllGroups(filteredGroups);
    }
  }, [allGroupsData, audit.groups]);

  useEffect(() => {
    if (usersData) {
      setAllUsers(
        usersData.getAllUsers.map((user) => ({
          id: user.id,
          name: user.name,
          status: "Unresolved",
        }))
      );
    }
  }, [usersData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Combine members from all selected groups
      const groupsForAudit =
        selectedAllGroups.length > 0
          ? selectedAllGroups.map((group) => ({
              id: group.id,
              name: group.name,
              visibility: group.visibility,
            }))
          : { id: "", name: "", visibility: [localStorage.getItem("userid")] };
      const assigneeIds = selectedAllGroups.flatMap((group) => group.members);
      const assignees = assigneeIds.map((id) => {
        const user = allUsers.find((user) => user.id === id);
        console.log(user, id, assigneeIds, allUsers);
        return {
          id: id,
          name: user ? user.name : "Unknown",
          status: "Unresolved",
        };
      });
      console.log(assignees);
      const assigneelist = includeUsers
        ? [...assignees, ...selectedUsers]
        : assignees;

      const uniqueAssignees = Array.from(
        new Map(
          assigneelist
            .map((assignee) => {
              return assignee.name !== "Unknown"
                ? [assignee.id, assignee]
                : null;
            })
            .filter((item) => item)
        ).values()
      );

      const updateData = {
        tenantId: tenantId,
        auditId: audit.auditId,
        questions: audit.questions,
        assignedBy: selectedGroup,
        assignee: uniqueAssignees,
        groups: groupsForAudit,
        resolution: "Unresolved",
        repeatType: formData.repeatType,
        repeatDays: formData.repeatType === "Weekly" ? formData.repeatDays : [],
        repeatCount : formData.repeatCount,
        repeatDates:
          formData.repeatType === "Monthly" ? formData.repeatDates : [],
      };
      console.log(updateData);

      const { data } = await updateAudit({
        variables: { updatedAuditData: updateData },
      });

      if (data.updateAuditById.success) {
        showToast("Audit updated successfully!", "success");
        navigate("/audits");
        window.location.reload();
      } else {
        showToast("Failed to update the audit.", "error");
      }
    } catch (error) {
      console.error("Error updating audit:", error);
      showToast("Error updating audit. Please try again.", "error");
    }
  };


  const handleRepeatTypeChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      repeatType: value,
      repeatDays: value === "Weekly" ? formData.repeatDays : [],
      repeatDates: value === "Monthly" ? formData.repeatDates : [],
    });
  };

  const handleDayToggle = (day) => {
    const currentDays = formData.repeatDays;
    if (currentDays.includes(day)) {
      setFormData({
        ...formData,
        repeatDays: currentDays.filter((d) => d !== day),
      });
    } else {
      setFormData({
        ...formData,
        repeatDays: [...currentDays, day],
      });
    }
  };

  const handleDateChange = (dates) => {
    setFormData({
      ...formData,
      repeatDates: dates,
    });
  };

  if (
    groupsLoading ||
    subGroupsLoading ||
    allGroupsLoading ||
    (includeUsers && usersLoading)
  )
    return <p>Loading...</p>;
  if (!leaderGroups || !allGroupsData || (includeUsers && !usersData))
    return <p>No data found.</p>;

  return (
    <div className="flex flex-col space-y-3 w-[900px]">
      <ToastAlert />
      <div className="card">
        <div className="mb-4">
          <h2>Audit Title: {audit.auditTitle}</h2>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <div className="flex flex-row justify-between">
            <div className="space-y-2">
              <label htmlFor="group-select" className="font-semibold">
                Assigned By
              </label>
              <Autocomplete
                disablePortal
                id="group-select"
                options={groupOptions}
                sx={{ width: 250 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Group for Assignment" />
                )}
                value={selectedGroup}
                onChange={(_, newValue) => {
                  setSelectedGroup(newValue);
                }}
                getOptionLabel={(option) => option.name}
              />
            </div>
            <div className="space-y-3">
              <label htmlFor="all-groups-select" className="ml-3 font-semibold">
                Assignee
              </label>
              <MultiSelect
                selectedValues={selectedAllGroups}
                setSelectedValues={setSelectedAllGroups}
                names={allGroups}
                setNames={setAllGroups}
              />
            </div>
          </div>
          <div className="mt-3 space-y-3 mb-3">
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeUsers}
                  onChange={(e) => setIncludeUsers(e.target.checked)}
                />
              }
              label="Include Individual Users"
            />
            {includeUsers && (
              <MultiSelect
                selectedValues={selectedUsers}
                setSelectedValues={setSelectedUsers}
                names={allUsers}
                setNames={setAllUsers}
              />
            )}
            <div>
              <label className="font-semibold">Repeat Type:</label>
              <select
                value={formData.repeatType}
                onChange={handleRepeatTypeChange}
              >
                <option value="None">None</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
              </select>
            </div>
            {(formData.repeatType) && (
              <div>
                <label className="font-semibold">Repeat Count:</label>
                <input
                  type="number"
                  value={formData.repeatCount}
                  onChange={(e) => setFormData({...formData, repeatCount: parseInt(e.target.value, 10)})}
                  className="ml-2 border-2 border-gray-300 rounded px-2 py-1"
                  min="1"
                />
              </div>
            )}
            {formData.repeatType === "Weekly" && (
              <div>
                <label className="font-semibold">Repeat on:</label>
                <div className="flex space-x-2 mt-2">
                  {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
                    <button
                      key={day}
                      className={`h-10 w-10 rounded-full flex items-center justify-center text-xs font-semibold ${
                        formData.repeatDays.includes(day)
                          ? "bg-ruby-red text-white"
                          : "bg-white text-ruby-red border border-ruby-red"
                      }`}
                      onClick={() => handleDayToggle(day)}
                      type="button"
                    >
                      {day.slice(0, 1)}
                    </button>
                  ))}
                </div>
              </div>
            )}
            {formData.repeatType === "Monthly" && (
              <div>
                <label className="font-semibold mr-3">Select Days:</label>
                <input
                  type="text"
                  className="w-[296px] h-8 mt-2 ml-5 mr-20 text-gray-500 text-sm font-normal font-['Inter'] leading-tight border-2 border-gray-500 border-opacity-30 p-2 rounded-full"
                  value={formData.repeatDates.join(", ")}
                  onChange={(e) =>
                    handleDateChange(e.target.value.split(",").map(Number))
                  }
                  placeholder="Enter days separated by commas (e.g., 1, 15, 28)"
                />
              </div>
            )}
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-rose-700 text-white mt-2 px-2 py-1 rounded"
              >
                Assign Audit
              </button>
            </div>
            </div>
          </form>
        </div>
      </div>
    );
}

export default AssignAuditForm;

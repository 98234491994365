import React from "react";
// import SearchBar from "../components/SearchBar";
// import Navbar from "../components/Navbar";
import ActionItems from "../components/home/ActionItems";
import Groups from "../components/home/Groups";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import MenuBookIcon from "@mui/icons-material/MenuBookOutlined";
import MyAudits from "../components/audits/MyAudits";
import { NavLink } from "react-router-dom";
function Dashboard() {
  console.log(localStorage.getItem("user"))
  return (
    <main className="flex flex-col  w-[80%] ">
      <div className="flex justify-between items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="flex flex-col mt-2 w-full">
        <div className="text-[#b00041] flex items-center justify-between px-4 mb-3">
          <div className="flex items-center gap-x-4">
            <h1 className="text-4xl font-black">Dashboard</h1>
          </div>
        </div>
        <div className="flex flex-row w-full">
          <div className="flex flex-col">
            <ActionItems />
            <div className="flex flex-row">
              <div>
                {/* <Groups /> */}
              </div>
              <div className="space-y-10 p-4 w-[20rem] h-[20rem]">
                <NavLink
                  className="flex flex-col  bg-gradient-to-r from-rose-800 via-rose-700 to-rose-600 rounded-2xl "
                  to="/tickets"
                >
                  <h2 className="text-white text-xl font-black p-3">
                    Open Ticket
                  </h2>
                  <div className="flex justify-end ">
                    <ConfirmationNumberIcon
                      style={{
                        width: "120px",
                        height: "100px",
                        color: "white",
                      }}
                    />
                  </div>
                </NavLink>
                {/* <NavLink
                  className="flex flex-col  bg-gradient-to-r from-rose-800 via-rose-700 to-rose-600 rounded-2xl "
                  to="/knowledgebase"
                >
                  <h2 className="text-white text-xl font-black p-3">
                    Knowledge Base
                  </h2>
                  <div className="flex justify-end ">
                    <MenuBookIcon
                      style={{
                        width: "120px",
                        height: "100px",
                        color: "white",
                      }}
                    />
                  </div>
                </NavLink> */}
              </div>
            </div>
          </div>
          <MyAudits />
        </div>
      </div>
    </main>
  );
}

export default Dashboard;

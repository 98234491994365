import { createSlice } from "@reduxjs/toolkit";

const formSlice = createSlice({
  name: "form",
  initialState: {
    formData: {},
  },
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    clearFormData: (state) => {
      state.formData = {};
    },
  },
});

export const { updateFormData, clearFormData } = formSlice.actions;

//to get a form data from store using useSelector(selectFormData)
export const selectFormData = (state) => state.form.formData;

export default formSlice.reducer;
import { gql } from "@apollo/client";

const CREATE_AUDIT = gql`
mutation CreateAudit($input: AuditInput!) {
  createAudit(input: $input) {
    auditId
    repeatCount
    assignee {
      id
      name
      status
    }
    groups {
      id
      name
      visibility
    }
    auditDesc
    auditTitle
    endDate
    assignedBy {
      id
      name
      visibility
    }
    repeatType
    repeatDays
    repeatDates
    resolution
    startDate
    comment
    questions {
      questionId
      questionName
      questionType
      isRequired
      values
      response {
        name
        values
      }
      failvalue
    }
  }
}
`;


const UPDATE_AUDIT = gql`
mutation UpdateAudit($updatedAuditData: UpdateAuditInput!) {
  updateAuditById(updatedAuditData: $updatedAuditData) {
    success
  }
}
`;
const DELETE_AUDIT = gql`
  mutation DeleteAuditById($tenantId: ID!, $auditId: ID!) {
    deleteAuditById(tenantId: $tenantId, auditId: $auditId) {
      success
    }
  }
`;

export { CREATE_AUDIT, UPDATE_AUDIT, DELETE_AUDIT };

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_WORKSPACE_BY_ID } from '../GraphQL/NewMethods/Queries';
import { UPDATE_WORKSPACE } from '../GraphQL/NewMethods/Mutation';
import {

  TextField,

} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { GET_ALL_USERS } from '../GraphQL/NewUsers/Queries';
// import SearchBar from '../components/SearchBar';
// import Navbar from '../components/Navbar';

const WorkspacePage = () => {
  const { id } = useParams();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { loading, error, data } = useQuery(GET_WORKSPACE_BY_ID, { variables: { id } });
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_ALL_USERS);
  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);

  if (loading || usersLoading) return <p>Loading...</p>;
  if (error || usersError) return <p>Error: {error ? error.message : usersError.message}</p>;

  const workspace = data.getWorkspaceById;
  const users = usersData.getAllUsers;

  const handleInviteUsers = async () => {
    const newMembers = selectedUsers.map(userId => {
      const user = users.find(user => user.id === userId);
      return { id: user.id, name: user.name, email: user.email };
    });

    await updateWorkspace({
      variables: {
         id,
        input: {
          members: [...(workspace.members || []), ...newMembers],
        },
      },
    });
    setInviteModalOpen(false);
    setSelectedUsers([]);
  };



  // Filter out leader and already existing members from the users list
  const filteredUsers = users.filter(user => 
    user.id !== workspace.leader.id && 
    !workspace.members.some(member => member.id === user.id)
  );

  return (
    <div className="w-screen h-screen  flex flex-col  items-center">
      <div className="flex justify-between items-center">
        {/* <SearchBar />
        <Navbar /> */}
      </div>
      <div className="w-[90%] h-full card">
        <div className='flex flex-row justify-between p-3'>
          <h2 className="text-2xl font-bold mb-4">{workspace.name}</h2>
          <button
            className="w-[200px] h-[38px] px-[30px] py-[9px] hover:bg-opacity-70  bg-ruby-red hiver:text-black hover:text-white rounded-md text-white text-sm font-semibold font-montserrat mt-2"
            onClick={() => setInviteModalOpen(true)}
          >
            Invite People
          </button>
        </div>

   

        {inviteModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded shadow-lg w-96">
              <h4 className="text-xl font-bold mb-4">Invite Users to Workspace</h4>
              <Autocomplete
                multiple
                options={filteredUsers}
                getOptionLabel={(option) => `${option.name} (${option.email})`}
                value={selectedUsers.map(userId => filteredUsers.find(user => user.id === userId))}
                onChange={(event, newValue) => setSelectedUsers(newValue.map(user => user.id))}
                renderInput={(params) => <TextField {...params} label="Users" />}
              />
              <div className='flex flex-row space-x-3'>
                <button
                  className="bg-ruby-red  text-white px-4 py-2 rounded mt-4"
                  onClick={handleInviteUsers}
                >
                  Add
                </button>
                <button
                  className="bg-white border-2 border-ruby-red text-ruby-red px-4 py-2 rounded mt-4"
                  onClick={() => setInviteModalOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkspacePage;

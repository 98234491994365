// Example: queries.js
import { gql } from "@apollo/client";

export const GET_ALL_GROUPS = gql`
  query GetAllGroups($tenantId: ID!) {
    getAllGroups(tenantId: $tenantId) {
      id
      name
      desc
      leaderId
      members
      tempLeader
      visibility
      parentGroupId
      childGroupIds
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query GetGroupById($tenantId: ID!, $id: ID!) {
    getGroupById(tenantId: $tenantId, id: $id) {
      id
      name
      desc
      leaderId
      members
      visibility
      tempLeader
      parentGroupId
      childGroupIds
    }
  }
`;
export const GET_USER_GROUPS = gql`
  query GetUserGroups($tenantId: ID!, $userId: ID!) {
    queryUserGroups(tenantId: $tenantId, userId: $userId) {
      id
      name
      desc
      leaderId
      members
      tempLeader
      visibility
      parentGroupId
      childGroupIds
    }
  }
`;

export const GET_SUB_GROUPS = gql`
query QuerySubGroups($tenantId: ID, $groupId: ID) {
  querySubGroups(tenantId: $tenantId, groupId: $groupId) {
    id
    name
    desc
    leaderId
    members
    visibility
    tempLeader
    parentGroupId
    childGroupIds
  }
}
`;
